import { useState } from 'react';

import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { t } from 'tools/i18n';

import initialApiService from 'services/initialApiService';
import { useRemovePowerOfAttorneyFilesMutation } from 'services/power-of-attorney/powerOfAttorneyApiService';

import { Button, ModalDefault } from 'gazprom-ui-lib';

import { SERVICE_TAGS } from 'constants/serviceTags';

import s from './RemoveButton.module.scss';

interface Props {
  employeeId: string;
}

const RemoveButton = (props: Props) => {
  const { employeeId } = props;

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const [remove, { isLoading }] = useRemovePowerOfAttorneyFilesMutation();

  const handleOpen = () => setIsOpen((prev) => !prev);

  const handleRemove = () =>
    remove({ employeeId }).then(() => {
      handleOpen();
      dispatch(initialApiService.util.invalidateTags([SERVICE_TAGS.GET_PERSON_BY_ID]));
    });

  return (
    <>
      <Button leftIcon="bin" type="text" className={cn(s.button, s.danger)} onClick={handleOpen} />
      <ModalDefault open={isOpen} onCancel={handleOpen} width={320} closable>
        <ModalDefault.Icon name="error" type="danger" />
        <ModalDefault.Title>{t('common_accept_remove')}</ModalDefault.Title>
        <ModalDefault.Description>{t('pover_of_attorney_remove')}</ModalDefault.Description>
        <ModalDefault.Actions>
          <Button fullWidth leftIcon="ok" loading={isLoading} onClick={handleRemove}>
            {t('common_confirm')}
          </Button>
        </ModalDefault.Actions>
      </ModalDefault>
    </>
  );
};

export default RemoveButton;
