import { t } from 'tools/i18n';

import { STATUSES } from 'components/table/table-status/tableStatus.utils';

import { KEDO_REVIEW_TASK_ASSIGNEE_STATUS_ENUM } from 'types/review-task.types';
import { SORT_ENUM } from 'types/sort.types';

export type RequestBodyType = {
  kedoReviewTaskId?: string;
  page: number;
  size: number;
  property?: string | null;
  order?: SORT_ENUM;
  search?: string;
};

export const DEFAULT_REQUEST_BODY: RequestBodyType = {
  page: 1,
  size: 10,
  property: null,
  order: SORT_ENUM.DESC,
  search: '',
};

export const getKedoReviewTaskAssigneeStatus = (
  status: KEDO_REVIEW_TASK_ASSIGNEE_STATUS_ENUM,
): { label: string; status: STATUSES } => {
  switch (status) {
    case KEDO_REVIEW_TASK_ASSIGNEE_STATUS_ENUM.WAITING:
      return {
        label: t('kedo_review_tasks_assign_waiting'),
        status: STATUSES.NEUTRAL,
      };
    case KEDO_REVIEW_TASK_ASSIGNEE_STATUS_ENUM.APPROVED:
      return {
        label: t('kedo_review_tasks_assign_approved'),
        status: STATUSES.APPROVED,
      };
    case KEDO_REVIEW_TASK_ASSIGNEE_STATUS_ENUM.REJECTED:
      return {
        label: t('kedo_review_tasks_assign_rejected'),
        status: STATUSES.DECLINED,
      };
    case KEDO_REVIEW_TASK_ASSIGNEE_STATUS_ENUM.CANCELED:
      return {
        label: t('common_canceled'),
        status: STATUSES.DECLINED,
      };
    default:
      return {
        label: t('kedo_review_tasks_assign_waiting'),
        status: STATUSES.PENDING,
      };
  }
};

export const createSignDownloadUrl = (taskAssignmentId: string): string =>
  `${process.env.https://admin-gateway-test.dev.tockl.ru/api/v1/}recruit/download/lna?taskAssignmentId=${taskAssignmentId}`;
