import { t } from 'tools/i18n';

import { useReinviteUserMutation } from 'services/communities/communitiesApiService';

import { Button, ButtonProps } from 'gazprom-ui-lib';

type ResentInviteButtonProps = {
  has24hPassed: boolean;
  location: 'table' | 'modal';
  inviteId: string;
  communityId: string;
  closeModal?: () => void;
};

type ResendInviteButtonStylesValue = {
  type: ButtonProps['type'];
  size: ButtonProps['size'];
};

const ResendInviteButtonStyles: Record<
  ResentInviteButtonProps['location'],
  ResendInviteButtonStylesValue
> = {
  modal: {
    size: 'middle',
    type: 'primary',
  },
  table: {
    size: 'small',
    type: 'link',
  },
};

const ResendInviteButton = ({
  has24hPassed,
  location,
  inviteId,
  communityId,
  closeModal,
}: ResentInviteButtonProps) => {
  const [reinviteUser, { isLoading }] = useReinviteUserMutation();

  const handleReinvite = async () => {
    try {
      await reinviteUser({ inviteId, communityId }).unwrap();

      if (location === 'modal' && closeModal) {
        closeModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const props = ResendInviteButtonStyles[location];

  const loadingModal = location === 'modal' && isLoading;
  const loadingTable = location === 'table' && isLoading;

  return (
    <Button
      {...props}
      loading={loadingModal}
      onClick={(e) => {
        e.stopPropagation();
        handleReinvite();
      }}
      disabled={!has24hPassed || loadingTable}
      leftIcon="refresh">
      {t(has24hPassed ? 'community_retry_invitation_action' : 'community_resend_invite_24h_limit')}
    </Button>
  );
};

export default ResendInviteButton;
