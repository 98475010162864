import { useEffect } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import {
  useCreateCommunityCategoryMutation,
  useUpdateCommunityCategoryMutation,
} from 'services/communities/communitiesApiService';

import { Button, Flex, Form, Input, Modal, Typography } from 'gazprom-ui-lib';

import { CommunityCategoryType } from 'types/community.types';

type ModalAddEditProps = {
  open: boolean;
  close: () => void;
  selectedCategory: CommunityCategoryType | null;
};

const ModalAddEdit = ({ open, close, selectedCategory }: ModalAddEditProps) => {
  const isEdit = !!selectedCategory;
  const [form] = Form.useForm();

  const [createCommunityCategory, { isLoading: isCreating }] = useCreateCommunityCategoryMutation();
  const [updateCommunityCategory, { isLoading: isUpdating }] = useUpdateCommunityCategoryMutation();

  const handleCloseModal = () => {
    close();
    form.resetFields();
  };

  useEffect(() => {
    if (isEdit && selectedCategory) {
      form.setFieldsValue({
        name: selectedCategory.name,
      });
    } else {
      form.resetFields();
    }
  }, [isEdit, selectedCategory, form]);

  const handleSubmit = async (values: { name: string }) => {
    const payload = { payload: values };
    try {
      if (isEdit) {
        await updateCommunityCategory({ ...payload, id: selectedCategory.id }).unwrap();
      } else {
        await createCommunityCategory({ ...payload }).unwrap();
      }
      handleCloseModal();
    } catch (error) {
      console.error(error);
    }
  };

  const disableSubmit = isCreating || isUpdating;

  return (
    <Modal open={open} onCancel={handleCloseModal} closable>
      <Flex vertical gap="16">
        <Typography.Title level={4}>
          {t(isEdit ? 'communities_edit_category' : 'common_add_category')}
        </Typography.Title>

        <Flex vertical gap="8">
          <Typography.Text size="16" weight="500">
            {t('common_name')}
          </Typography.Text>

          <Form form={form} onFinish={handleSubmit}>
            <Form.Item name="name" rules={[formRules.required]}>
              <Input placeholder={t('common_enter_text')} />
            </Form.Item>

            <Flex gap="8" align="center">
              <Button
                disabled={disableSubmit}
                htmlType="submit"
                loading={disableSubmit}
                leftIcon={isEdit ? 'ok' : 'plus'}
                fullWidth>
                {t(isEdit ? 'common_save' : 'common_add')}
              </Button>
              <Button htmlType="button" type="secondary" onClick={handleCloseModal} fullWidth>
                {t('common_close')}
              </Button>
            </Flex>
          </Form>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ModalAddEdit;
