import { EmployeeGroupType } from '../../types/employeeGroups.types';

export const KEDO_REVIEW_TASK_FORM_NAME = 'KEDO_REVIEW_TASK_FORM_NAME';

export enum KEDO_REVIEW_TASK_FORM_ENUM {
  NAME = 'NAME',
  DOC_NAME = 'DOC_NAME',
  FILE = 'FILE',
  EMPLOYEE_GROUPS = 'EMPLOYEE_GROUPS',
  SIGN_REQUIRED = 'SIGN_REQUIRED',
  INSTANCE_1C = 'INSTANCE_1C',
  DOC_NUMBER = 'DOC_NUMBER',
  DOC_DATE = 'DOC_DATE',
}

export type KedoReviewTaskFileType = {
  link: string;
  name: string;
  size: number;
};

export interface KedoReviewTaskFormResponse {
  [KEDO_REVIEW_TASK_FORM_ENUM.NAME]: string;
  [KEDO_REVIEW_TASK_FORM_ENUM.DOC_NAME]: string;
  [KEDO_REVIEW_TASK_FORM_ENUM.FILE]: KedoReviewTaskFileType;
  [KEDO_REVIEW_TASK_FORM_ENUM.EMPLOYEE_GROUPS]: string[];
  [KEDO_REVIEW_TASK_FORM_ENUM.SIGN_REQUIRED]: boolean;
  [KEDO_REVIEW_TASK_FORM_ENUM.DOC_NUMBER]: string;
  [KEDO_REVIEW_TASK_FORM_ENUM.DOC_DATE]: string;
}

export const DEFAULT_PAGE_DATA = { page: 1, size: 500 };

export const prepareEmployeeGroupsOption = (
  employeeGroups: EmployeeGroupType[],
): { value: string; label: string }[] =>
  employeeGroups.map(({ id, title }) => ({ value: id, label: title }));
