import { FC } from 'react';

import { Table } from 'gazprom-ui-lib';

import { RecruitDocumentType } from 'types/recruit.types';

import useColumns from './useColumns.hook';

interface LnaTableProps {
  data: RecruitDocumentType[];
  hideDownload?: boolean;
}

const DocumentsTable: FC<LnaTableProps> = (props) => {
  const { data, hideDownload } = props;
  const columns = useColumns({ hideDownload });

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey="id"
      pagination={{
        current: 1,
        total: 1,
      }}
    />
  );
};

export default DocumentsTable;
