import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  BlockSubscriberPropsType,
  CreateCommunityCategoryPropsType,
  CreateCommunityDiscussionCommentPropsType,
  CreateCommunityDiscussionCommentResponseType,
  CreateCommunityDiscussionPropsType,
  CreateCommunityDiscussionResponseType,
  CreateCommunityNewsPropsType,
  CreateCommunityPropsType,
  CreateUpdateCommunityCategoryPropsType,
  CreateUpdateCommunityCategoryResponseType,
  CreateUpdateCommunityResponseType,
  DeleteCommunityCategoryPropsType,
  GetCommentByCommunityNewsIdPropsType,
  GetCommentByCommunityNewsIdResponseType,
  GetCommunitiesPropsType,
  GetCommunitiesResponseType,
  GetCommunityCategoriesPropsType,
  GetCommunityCategoriesResponseType,
  GetCommunityCommentsByDiscussionIdPropsType,
  GetCommunityCommentsByDiscussionIdResponseType,
  GetCommunityDiscussionByIdPropsType,
  GetCommunityDiscussionByIdResponseType,
  GetCommunityDiscussionCommentPropsType,
  GetCommunityDiscussionCommentResponseType,
  GetCommunityDiscussionsListPropsType,
  GetCommunityDiscussionsListResponseType,
  GetCommunityInvitesPropsType,
  GetCommunityInvitesResponseType,
  GetCommunityNewsByIdPropsType,
  GetCommunityNewsByIdResponseType,
  GetCommunityNewsListPropsType,
  GetCommunityNewsListResponseType,
  GetCommunityPropsType,
  GetCommunityResponseType,
  GetCommunitySubscribersPropsType,
  GetCommunitySubscribersResponseType,
  InviteUserPropsType,
  MassSubscribeInviteProps,
  ReinviteUserPropsType,
  ResponseToCommentByCommunityNewsIdPropsType,
  ResponseToCommentByCommunityNewsIdResponseType,
  UnblockSubscriberPropsType,
  UpdateCommentByCommunityNewsIdPropsType,
  UpdateCommentByCommunityNewsIdResponseType,
  UpdateCommunityDiscussionCommentPropsType,
  UpdateCommunityDiscussionCommentResponseType,
  UpdateCommunityDiscussionPropsType,
  UpdateCommunityDiscussionResponseType,
  UpdateCommunityNewsPropsType,
  UpdateCommunityPropsType,
} from './communityApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

const communitiesApiService = initialApiService.injectEndpoints({
  // ! COMMUNITY CATEGORIES
  endpoints: (builder) => ({
    getCommunityCategories: builder.query<
      GetCommunityCategoriesResponseType,
      GetCommunityCategoriesPropsType
    >({
      query: (params) => ({
        url: `category_communities${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_CATEGORIES],
    }),
    createCommunityCategory: builder.mutation<
      CreateUpdateCommunityCategoryResponseType,
      CreateCommunityCategoryPropsType
    >({
      query: ({ payload }) => ({
        url: `category_communities`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_CATEGORIES],
    }),
    updateCommunityCategory: builder.mutation<
      CreateUpdateCommunityCategoryResponseType,
      CreateUpdateCommunityCategoryPropsType
    >({
      query: ({ id, payload }) => ({
        url: `category_communities/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_CATEGORIES],
    }),
    deleteCommunityCategory: builder.mutation<void, DeleteCommunityCategoryPropsType>({
      query: ({ id }) => ({
        url: `category_communities/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_CATEGORIES],
    }),

    // ! COMMUNITY MANAGEMENT
    getCommunities: builder.query<GetCommunitiesResponseType, GetCommunitiesPropsType>({
      query: (params) => ({
        url: `community${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITIES],
    }),
    getCommunitiesModerator: builder.query<GetCommunitiesResponseType, GetCommunitiesPropsType>({
      query: (params) => ({
        url: `me/community/moderator${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITIES_MODERATOR],
    }),
    getCommunity: builder.query<GetCommunityResponseType, GetCommunityPropsType>({
      query: ({ id }) => ({
        url: `community/${id}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY],
    }),
    createCommunity: builder.mutation<CreateUpdateCommunityResponseType, CreateCommunityPropsType>({
      query: ({ payload }) => ({
        url: `community`,
        body: payload,
        method: 'POST',
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY,
        SERVICE_TAGS.GET_COMMUNITIES,
        SERVICE_TAGS.GET_COMMUNITY_CATEGORIES,
        SERVICE_TAGS.GET_COMMUNITIES_MODERATOR,
      ],
    }),
    updateCommunity: builder.mutation<CreateUpdateCommunityResponseType, UpdateCommunityPropsType>({
      query: ({ payload, id }) => ({
        url: `community/${id}`,
        body: payload,
        method: 'PUT',
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY,
        SERVICE_TAGS.GET_COMMUNITIES,
        SERVICE_TAGS.GET_COMMUNITY_CATEGORIES,
        SERVICE_TAGS.GET_COMMUNITIES_MODERATOR,
      ],
    }),

    // ! SUBSCRIBERS
    getCommunitySubscribers: builder.query<
      GetCommunitySubscribersResponseType,
      GetCommunitySubscribersPropsType
    >({
      query: ({ id, params }) => ({
        url: `subscribers/community/${id}${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_SUBSCRIBERS],
    }),
    getCommunityDiscussions: builder.query<
      GetCommunityDiscussionsListResponseType,
      GetCommunityDiscussionsListPropsType
    >({
      query: ({ communityId, ...params }) => ({
        url: `community/${communityId}/discussion${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_DISCUSSIONS_LIST],
    }),
    createCommunityDiscussion: builder.mutation<
      CreateCommunityDiscussionResponseType,
      CreateCommunityDiscussionPropsType
    >({
      query: ({ communityId, ...params }) => ({
        url: `community/${communityId}/discussion`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY_DISCUSSIONS_LIST,
        SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_BY_ID,
      ],
    }),
    updateCommunityDiscussion: builder.mutation<
      UpdateCommunityDiscussionResponseType,
      UpdateCommunityDiscussionPropsType
    >({
      query: ({ communityId, discussionId, ...params }) => ({
        url: `community/${communityId}/discussion/${discussionId}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY_DISCUSSIONS_LIST,
        SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_BY_ID,
      ],
    }),
    getCommunityDiscussionById: builder.query<
      GetCommunityDiscussionByIdResponseType,
      GetCommunityDiscussionByIdPropsType
    >({
      query: ({ communityId, discussionId }) => ({
        url: `community/${communityId}/discussion/${discussionId}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_BY_ID],
    }),
    getCommunityCommentsByDiscussionId: builder.query<
      GetCommunityCommentsByDiscussionIdResponseType,
      GetCommunityCommentsByDiscussionIdPropsType
    >({
      query: ({ communityId, discussionId, ...params }) => ({
        url: `community/${communityId}/discussion/${discussionId}/comments${prepareQueryParams(
          params,
        )}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_COMMENTS],
    }),
    createCommunityDiscussionComment: builder.mutation<
      CreateCommunityDiscussionCommentResponseType,
      CreateCommunityDiscussionCommentPropsType
    >({
      query: ({ communityId, discussionId, ...params }) => ({
        url: `community/${communityId}/discussion/${discussionId}/comments`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_COMMENTS,
        SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_COMMENT_BY_ID,
      ],
    }),
    getCommunityDiscussionComment: builder.query<
      GetCommunityDiscussionCommentResponseType,
      GetCommunityDiscussionCommentPropsType
    >({
      query: ({ communityId, discussionId, commentId }) => ({
        url: `community/${communityId}/discussion/${discussionId}/comments/${commentId}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_COMMENT_BY_ID],
    }),
    updateCommunityDiscussionComment: builder.mutation<
      UpdateCommunityDiscussionCommentResponseType,
      UpdateCommunityDiscussionCommentPropsType
    >({
      query: ({ communityId, discussionId, commentId, ...params }) => ({
        url: `community/${communityId}/discussion/${discussionId}/comments/${commentId}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_COMMENTS,
        SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_COMMENT_BY_ID,
      ],
    }),
    getCommunityNewsList: builder.query<
      GetCommunityNewsListResponseType,
      GetCommunityNewsListPropsType
    >({
      query: ({ communityId, ...params }) => ({
        url: `community/${communityId}/article${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_NEWS_LIST],
    }),
    createCommunityNews: builder.mutation<void, CreateCommunityNewsPropsType>({
      query: ({ communityId, body }) => {
        return {
          url: `community/${communityId}/article`,
          body: body,
          method: 'POST',
        };
      },
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_NEWS_LIST],
    }),
    updateCommunityNewsById: builder.mutation<void, UpdateCommunityNewsPropsType>({
      query: ({ communityId, newsId, body }) => {
        return {
          url: `community/${communityId}/article/${newsId}`,
          body: body,
          method: 'PUT',
        };
      },
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_NEWS_LIST],
    }),
    getCommunityNewsById: builder.query<
      GetCommunityNewsByIdResponseType,
      GetCommunityNewsByIdPropsType
    >({
      query: ({ communityId, newsId }) => ({
        url: `community/${communityId}/article/${newsId}`,
      }),
    }),
    getCommentByCommunityNewsId: builder.query<
      GetCommentByCommunityNewsIdResponseType,
      GetCommentByCommunityNewsIdPropsType
    >({
      query: ({ communityId, newsId, ...params }) => ({
        url: `community/${communityId}/article/${newsId}/comments${prepareQueryParams(params)}`,
      }),
    }),
    updateCommentByCommunityNewsId: builder.mutation<
      UpdateCommentByCommunityNewsIdResponseType,
      UpdateCommentByCommunityNewsIdPropsType
    >({
      query: ({ communityId, newsId, commentId, body }) => ({
        url: `community/${communityId}/article/${newsId}/comments/${commentId}`,
        method: 'PUT',
        body,
      }),
    }),
    responseToCommentByCommunityNewsId: builder.mutation<
      ResponseToCommentByCommunityNewsIdResponseType,
      ResponseToCommentByCommunityNewsIdPropsType
    >({
      query: ({ communityId, newsId, body }) => ({
        url: `community/${communityId}/article/${newsId}/comments`,
        method: 'POST',
        body,
      }),
    }),
    blockSubscriber: builder.mutation<void, BlockSubscriberPropsType>({
      query: ({ payload, communityId, subscriberId }) => ({
        url: `community/${communityId}/subscriber/${subscriberId}/block`,
        body: payload,
        method: 'PUT',
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY,
        SERVICE_TAGS.GET_COMMUNITIES,
        SERVICE_TAGS.GET_COMMUNITY_CATEGORIES,
        SERVICE_TAGS.GET_COMMUNITIES_MODERATOR,
        SERVICE_TAGS.GET_COMMUNITY_SUBSCRIBERS,
      ],
    }),
    unblockSubscriber: builder.mutation<void, UnblockSubscriberPropsType>({
      query: ({ communityId, subscriberId }) => ({
        url: `community/${communityId}/subscriber/${subscriberId}/unblock`,
        method: 'PUT',
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY,
        SERVICE_TAGS.GET_COMMUNITIES,
        SERVICE_TAGS.GET_COMMUNITY_CATEGORIES,
        SERVICE_TAGS.GET_COMMUNITIES_MODERATOR,
        SERVICE_TAGS.GET_COMMUNITY_SUBSCRIBERS,
      ],
    }),
    // TODO.Ilya check when BE ready
    massSubscribe: builder.mutation<void, MassSubscribeInviteProps>({
      query: ({ payload, communityId }) => ({
        url: `community/${communityId}/subscribe`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_SUBSCRIBERS],
    }),

    // ! INVITES
    getCommunityInvites: builder.query<
      GetCommunityInvitesResponseType,
      GetCommunityInvitesPropsType
    >({
      query: ({ id, params }) => ({
        url: `invites/community/${id}${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_INVITES],
    }),
    reinviteUser: builder.mutation<void, ReinviteUserPropsType>({
      query: ({ communityId, inviteId }) => ({
        url: `community/${communityId}/invites/${inviteId}`,
        method: 'PUT',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_INVITES],
    }),
    inviteUser: builder.mutation<void, InviteUserPropsType>({
      query: ({ params, communityId }) => ({
        url: `community/${communityId}/invites${prepareQueryParams(params)}`,
        method: 'POST',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_INVITES],
    }),
    massInvite: builder.mutation<void, MassSubscribeInviteProps>({
      query: ({ payload, communityId }) => ({
        url: `community/${communityId}/invite`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_INVITES],
    }),
  }),
});

export const {
  useGetCommunityCategoriesQuery,
  useCreateCommunityCategoryMutation,
  useUpdateCommunityCategoryMutation,
  useDeleteCommunityCategoryMutation,
  useGetCommunitiesQuery,
  useGetCommunityQuery,
  useCreateCommunityMutation,
  useUpdateCommunityMutation,
  useGetCommunitiesModeratorQuery,
  useGetCommunitySubscribersQuery,
  useCreateCommunityNewsMutation,
  useUpdateCommunityNewsByIdMutation,
  useLazyGetCommunityNewsListQuery,
  useGetCommunityNewsByIdQuery,
  useLazyGetCommentByCommunityNewsIdQuery,
  useUpdateCommentByCommunityNewsIdMutation,
  useResponseToCommentByCommunityNewsIdMutation,
  useGetCommunityInvitesQuery,
  useBlockSubscriberMutation,
  useUnblockSubscriberMutation,
  useReinviteUserMutation,
  useInviteUserMutation,
  useLazyGetCommunityDiscussionsQuery,
  useLazyGetCommunityCommentsByDiscussionIdQuery,
  useUpdateCommunityDiscussionMutation,
  useGetCommunityDiscussionByIdQuery,
  useCreateCommunityDiscussionMutation,
  useCreateCommunityDiscussionCommentMutation,
  useUpdateCommunityDiscussionCommentMutation,
  useLazyGetCommunityDiscussionCommentQuery,
} = communitiesApiService;
