import cInitialApiService from 'services/1c/cApiService';
import { createWorkTourUrl } from 'services/1c/work-tour/utils';
import { createKedoUrl } from 'services/kedo/utils';
import { createRecruitUrl } from 'services/recruit/utils';

import {
  EmployerCancelDocumentPropsType,
  EmployerCancelDocumentResponseType,
} from './employerCancelDocumentApiService.types';
import { C_SERVICE_TAGS } from 'constants/serviceTags';

const employerCancelDocumentApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    employerCancelWorkTour: builder.mutation<
      EmployerCancelDocumentResponseType,
      EmployerCancelDocumentPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createWorkTourUrl(`/${id}/employer-cancel`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.GET_WORK_TOUR_BY_ID],
    }),
    employerCancelKedoDocument: builder.mutation<
      EmployerCancelDocumentResponseType,
      EmployerCancelDocumentPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createKedoUrl(`/task/${id}/employer-cancel`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.STATEMENT_TASK_BY_ID],
    }),
    employerCancelRecruit: builder.mutation<
      EmployerCancelDocumentResponseType,
      EmployerCancelDocumentPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createRecruitUrl(`/${id}/employer-cancel`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.GET_RECRUIT_BY_ID],
    }),
  }),
});

export const {
  useEmployerCancelWorkTourMutation,
  useEmployerCancelRecruitMutation,
  useEmployerCancelKedoDocumentMutation,
} = employerCancelDocumentApiService;
