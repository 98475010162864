import { t } from 'tools/i18n';

export const COMMUNITIES_FILTERS_TYPE_OPTIONS = [
  {
    value: true,
    label: t('common_published_plural'),
  },
  {
    value: false,
    label: t('common_not_published'),
  },
];
