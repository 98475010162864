import { t } from 'tools/i18n';

import { IconProps, TagProps } from 'gazprom-ui-lib';

import { CommunityInviteStatus } from 'types/communityInvite.types';

export type SendStatusMapValue = {
  type: TagProps['type'];
  iconName: IconProps['name'];
  text: string;
};

export const SEND_STATUS_MAP: Record<CommunityInviteStatus, SendStatusMapValue> = {
  ACCEPTED: {
    iconName: 'ok',
    text: t('community_invite_accepted'),
    type: 'success',
  },
  REJECTED: {
    iconName: 'deleteFilled',
    text: t('community_invite_rejected'),
    type: 'danger',
  },
  SEND: {
    iconName: 'time',
    text: t('community_invite_sent'),
    type: 'warning',
  },
};
