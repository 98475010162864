import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import { getTimezone } from 'utils/date.utils';

import { useDownloadGiftOrderReportMutation } from 'services/report/reportApiService';
import { DownloadGiftOrderPropsType } from 'services/report/reportApiService.types';

import { Button, Flex } from 'gazprom-ui-lib';

import FiltersWrapper from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

export const ORDERS_BY_POST_ID = 18;
export const ORDERS_BY_PICKUP_ID = 16;

const Filters = () => {
  const [downloadGiftReport, { isLoading }] = useDownloadGiftOrderReportMutation();

  const handleDownload = () => {
    const requestBody: DownloadGiftOrderPropsType = {
      filename: `Отчет по отзывам на заказы ${dayjs().format()}`,
      timeZone: getTimezone(),
      orderRatingFilterParam: {},
    };

    downloadGiftReport(requestBody);
  };

  return (
    <FiltersV2.TableFilters>
      <Flex align="items">
        <FiltersV2.Search />
        <Button
          size="small"
          type="link"
          leftIcon="download"
          onClick={handleDownload}
          loading={isLoading}>
          {t('present_report_download')}
        </Button>
      </Flex>
      <FiltersWrapper
        showClearAll
        overlay={
          <FilterCustomWrapper>
            <FilterHeader valueKey="status">{t('presents_delivery_type')}</FilterHeader>
            <FilterCheckboxGroup
              usePadding={false}
              valueKey="deliveryType"
              options={[
                { label: t('presents_by_post'), value: ORDERS_BY_POST_ID },
                { label: t('presents_by_pickup'), value: ORDERS_BY_PICKUP_ID },
              ]}
            />
          </FilterCustomWrapper>
        }
      />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
