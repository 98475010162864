import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import downloadHandler from 'utils/downloadHandler';

import { qoaParamsSelector } from 'selectors/surveysSlice.selector';
import {
  useLazyGetAllFreeStatsByIdQuery,
  useLazyGetSurveyStatsByIdQuery,
} from 'services/surveys/surveysApiService';
import {
  GetFreeStatsByIdPropsType,
  GetSurveyStatsByIdPropsType,
  GetSurveyStatsByIdResponseType,
} from 'services/surveys/surveysApiService.types';
import { useAppSelector } from 'store';

import { handleRequest } from 'gazprom-ui-lib';

import {
  MappedFreeQuestionType,
  MappedQuestionStatType,
  QuestionStatsType,
  RequestBodyType,
} from 'types/surveys.types';

import {
  DEFAULT_REQUEST_BODY,
  getQueryQoaString,
  getReportUrl,
  updateQuestionsWithStats,
} from './statsTab.utils';

const DEFAULT_FREE_PAGE = 1;
const DEFAULT_FREE_SIZE = 5;

const useStatsTab = () => {
  const { id, revisionId } = useParams<{ id: string; revisionId: string }>();

  const [isDownloadingInProgress, setIsDownloadingInProgress] = useState<boolean>(false);
  const [requestBody, setRequestBody] = useState<RequestBodyType>(DEFAULT_REQUEST_BODY);
  const [questionStatsResult, setQuestionStatsResult] = useState<
    MappedQuestionStatType[] | QuestionStatsType[]
  >([]);

  const qoa = useAppSelector(qoaParamsSelector);

  const [getFreeStats] = useLazyGetAllFreeStatsByIdQuery();
  const [getSurveysById] = useLazyGetSurveyStatsByIdQuery();
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

  // kek({});

  const handleDownLoadReport = () => {
    if (!id) {
      return;
    }

    setIsDownloadingInProgress(true);
    downloadHandler({
      url: getReportUrl(id),
      fileName: t('common_report'),
      method: 'POST',
      headersFileNameKey: `filename*=UTF-8''`,
    }).finally(() => setIsDownloadingInProgress(false));
  };

  const handleSurveyStats = useCallback(
    (response: GetSurveyStatsByIdResponseType | undefined) => {
      if (!response) {
        return;
      }

      const { questions } = response;
      const freeQuestions = response.questions.filter((question) => question.type === 'free');

      if (!freeQuestions?.length) {
        setIsDataLoading(false);
        setQuestionStatsResult(questions);
        return;
      }

      const freeQuestionsWithParams = freeQuestions.map((question) => {
        const preparedBody: GetFreeStatsByIdPropsType = {
          id: id!,
          questionId: String(question.questionId),
          revisionId: revisionId!,
          page: DEFAULT_FREE_PAGE,
          size: DEFAULT_FREE_SIZE,
          from: requestBody.from,
          to: requestBody.to,
          freeQuestionId: question.freeQuestionId,
        };

        if (requestBody.company) {
          preparedBody.employerId = requestBody.company;
        }
        if (requestBody.staff) {
          preparedBody.positionId = requestBody.staff;
        }
        if (requestBody.division) {
          preparedBody.divisionId = requestBody.division;
        }

        return preparedBody;
      });

      getFreeStats(freeQuestionsWithParams)
        .then((freeResponses) => {
          if (freeResponses.data) {
            const initialStats = updateQuestionsWithStats(
              questions,
              freeResponses.data as MappedFreeQuestionType[],
            );
            setQuestionStatsResult(initialStats);
          }
        })
        .finally(() => setIsDataLoading(false));
    },
    [getFreeStats, id, requestBody, revisionId],
  );

  useEffect(() => {
    if (id && revisionId) {
      const preparedBody: GetSurveyStatsByIdPropsType = {
        id,
        revisionId,
      };

      if (requestBody.company) {
        preparedBody.employerId = requestBody.company;
      }
      if (requestBody.staff) {
        preparedBody.positionId = requestBody.staff;
      }
      if (requestBody.division) {
        preparedBody.divisionId = requestBody.division;
      }
      if (requestBody.from) {
        preparedBody.from = requestBody.from;
      }
      if (requestBody.to) {
        preparedBody.to = requestBody.to;
      }

      if (qoa.length) {
        preparedBody.qoaQuery = getQueryQoaString(qoa);
      }

      setIsDataLoading(true);
      getSurveysById(preparedBody).then(
        handleRequest({
          onSuccess: handleSurveyStats,
        }),
      );
    }
  }, [getSurveysById, handleSurveyStats, id, qoa, requestBody, revisionId]);

  return {
    qoa,
    isDataLoading,
    questionStatsResult,
    requestBody,
    isDownloadingInProgress,
    setRequestBody,
    handleDownLoadReport,
  };
};

export default useStatsTab;
