import { t } from 'tools/i18n';

import { useDeleteCommunityCategoryMutation } from 'services/communities/communitiesApiService';

import { Button, ModalDefault } from 'gazprom-ui-lib';

import { CommunityCategoryType } from 'types/community.types';

type ModalDeleteProps = {
  open: boolean;
  close: () => void;
  selectedCategory: CommunityCategoryType;
};

const ModalDelete = ({ open, close, selectedCategory }: ModalDeleteProps) => {
  const canDelete = selectedCategory.countCommunity < 1;

  const [deleteCategory, { isLoading }] = useDeleteCommunityCategoryMutation();

  const handleDeleteOrClose = async () => {
    if (canDelete) {
      try {
        await deleteCategory({ id: selectedCategory.id });
        close();
      } catch (error) {
        console.error(error);
      }
    } else {
      close();
    }
  };

  return (
    <ModalDefault open={open} onCancel={close} width={320} closable>
      <ModalDefault.Icon name={canDelete ? 'bin' : 'error'} type="danger" />
      <ModalDefault.Title>
        {t(canDelete ? 'common_accept_remove' : 'communities_categories_can_not_delete_title')}
      </ModalDefault.Title>
      <ModalDefault.Description>
        {t(
          canDelete
            ? 'communities_categories_delete_modal_body'
            : 'communities_categories_can_not_delete_description',
        )}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button
          loading={isLoading}
          danger={canDelete ? true : false}
          ghost
          fullWidth
          leftIcon="bin"
          onClick={handleDeleteOrClose}>
          {t(canDelete ? 'common_delete' : 'common_close')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default ModalDelete;
