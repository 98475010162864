import { useState } from 'react';

import { useVisibility } from 'utils/useVisibility.hook';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2/filtersV2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { CommunityCategoryType } from 'types/community.types';

import { DEFAULT_FORM_STATE, FormResponseType } from './communityCategories.utils';
import Filters from './containers/filters';
import Header from './containers/header';
import ModalAddEdit from './containers/modal-add-edit';
import ModalDelete from './containers/modal-delete';
import Table from './containers/table';
import { useRequest } from './useRequest.hook';

const CommunityCategories = () => {
  const [form] = Form.useForm<FormResponseType>();

  const requestBody: FormResponseType = Form.useWatch([], form);

  const [selectedCategory, setSelectedCategory] = useState<CommunityCategoryType | null>(null);
  const [addEditIsOpened, { open: openAddEdit, close: closeAddEdit }] = useVisibility();
  const [deleteIsOpened, { open: openDelete, close: closeDelete }] = useVisibility();

  const onCloseAddEdit = () => {
    closeAddEdit();
    setSelectedCategory(null);
  };

  const onOpenAddEdit = (record: CommunityCategoryType) => {
    setSelectedCategory(record);
    openAddEdit();
  };

  const onCloseDelete = () => {
    closeDelete();
    setSelectedCategory(null);
  };

  const onOpenDelete = (record: CommunityCategoryType) => {
    setSelectedCategory(record);
    openDelete();
  };

  const requestData = useRequest({ requestBody });

  return (
    <>
      <WithOverflow>
        <Header openAddEdit={openAddEdit} />
        <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
          <Filters />
          <Table onOpenDelete={onOpenDelete} onOpenAddEdit={onOpenAddEdit} {...requestData} />
        </FiltersV2>
      </WithOverflow>
      <ModalAddEdit
        open={addEditIsOpened}
        close={onCloseAddEdit}
        selectedCategory={selectedCategory}
      />
      {selectedCategory && (
        <ModalDelete
          open={deleteIsOpened}
          close={onCloseDelete}
          selectedCategory={selectedCategory}
        />
      )}
    </>
  );
};

export default CommunityCategories;
