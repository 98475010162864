import FiltersV2 from 'containers/filters-v2';

const Filters = () => {
  return (
    <FiltersV2.TableFilters>
      <FiltersV2.Search />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
