import { useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';

import { Button, Flex } from 'gazprom-ui-lib';

import s from './Actions.module.scss';

interface Props {
  isLoading: boolean;
}

const Actions = (props: Props) => {
  const { isLoading } = props;

  const navigate = useNavigate();

  const handleClose = () => navigate(-1);

  return (
    <Flex gap="8" className={s.container}>
      <Button type="primary" leftIcon="ok" htmlType="submit" loading={isLoading}>
        {t('common_save')}
      </Button>
      <Button type="secondary" htmlType="submit" onClick={handleClose}>
        {t('common_close')}
      </Button>
    </Flex>
  );
};

export default Actions;
