export const COMMUNITIES_ROUTE = '/communities';

export const COMMUNITY_CATEGORIES_ROUTE = `${COMMUNITIES_ROUTE}/categories`;

export const COMMUNITY_MANAGEMENT_ROUTE = `${COMMUNITIES_ROUTE}/management`;

export const COMMUNITY_MANAGEMENT_CREATE_ROUTE = `${COMMUNITIES_ROUTE}/management/create`;

export const COMMUNITY_MANAGEMENT_EDIT_ROUTE = `${COMMUNITIES_ROUTE}/management/edit/:id`;

export const COMMUNITY_MANAGEMENT_VIEW_ROUTE = `${COMMUNITIES_ROUTE}/management/view/:id`;

export const COMMUNITY_MANAGEMENT_SUBSCRIBERS_ROUTE = `${COMMUNITY_MANAGEMENT_VIEW_ROUTE}/subscribers`;

export const COMMUNITY_MANAGEMENT_SUBSCRIBERS_ADD_ROUTE = `${COMMUNITY_MANAGEMENT_VIEW_ROUTE}/subscribers/add`;

export const COMMUNITY_MANAGEMENT_SUBSCRIBERS_INVITE_ROUTE = `${COMMUNITY_MANAGEMENT_VIEW_ROUTE}/subscribers/invite`;

export const COMMUNITY_MANAGEMENT_NEWS_CREATE_ROUTE = `${COMMUNITY_MANAGEMENT_VIEW_ROUTE}/news/create`;

export const COMMUNITY_MANAGEMENT_NEWS_BY_ID_ROUTE = `${COMMUNITY_MANAGEMENT_VIEW_ROUTE}/news/:newsId`;
export const COMMUNITY_MANAGEMENT_DISCUSSIONS_BY_ID_ROUTE = `${COMMUNITY_MANAGEMENT_VIEW_ROUTE}/discussions/:discussionId`;

export const getCommunityManagementNewsCreateRoute = (id: string) =>
  `${COMMUNITIES_ROUTE}/management/view/${id}/news/create`;
export const getCommunityManagementViewByIdRoute = (id: string) =>
  `${COMMUNITIES_ROUTE}/management/view/${id}`;
export const getCommunityManagementNewsByIdRoute = (id: string, newsId: string) =>
  `${COMMUNITIES_ROUTE}/management/view/${id}/news/${newsId}`;
export const getCommunityManagementDiscussionsByIdRoute = (id: string, discussionId: string) =>
  `${COMMUNITIES_ROUTE}/management/view/${id}/discussions/${discussionId}`;
