import { t } from 'i18next';
import { useDispatch } from 'react-redux';

import initialApiService from 'services/initialApiService';
import { useUploadPowerOfAttorneyFilesMutation } from 'services/power-of-attorney/powerOfAttorneyApiService';

import { Button, Flex, Form, FormInstance, Modal, Typography } from 'gazprom-ui-lib';

import FileUpload from 'components/file/file-upload';
import ImgPreview from 'components/img-preview';

import { SERVICE_TAGS } from 'constants/serviceTags';

interface Props {
  open: boolean;
  employeeId: string;
  onCancel: () => void;
  form: FormInstance;
}

export enum UPLOAD_FILES_FORM_ENUM {
  XML = 'xml',
  SIG = 'sig',
}

const ModalUpload = (props: Props) => {
  const { open, employeeId, form, onCancel } = props;
  const dispatch = useDispatch();

  const [upload, { isLoading }] = useUploadPowerOfAttorneyFilesMutation();

  const xmlFile = Form.useWatch(UPLOAD_FILES_FORM_ENUM.XML, form) as File;
  const sigFile = Form.useWatch(UPLOAD_FILES_FORM_ENUM.SIG, form) as File;
  const isSubmitDisable = xmlFile && sigFile;

  const uploadFiles = async (values: Record<string, File>) => {
    const formData = new FormData();

    formData.append('fileXml', values[UPLOAD_FILES_FORM_ENUM.XML]);
    formData.append('fileSig', values[UPLOAD_FILES_FORM_ENUM.SIG]);

    upload({ employeeId, body: formData }).then(() => {
      dispatch(initialApiService.util.invalidateTags([SERVICE_TAGS.GET_PERSON_BY_ID]));
      onCancel();
      form.resetFields();
    });
  };

  const resetFile = (name: UPLOAD_FILES_FORM_ENUM) => () => form.setFieldValue(name, undefined);

  return (
    <Modal open={open} onCancel={onCancel} closable width={420}>
      <Typography.Title level={4}>{t('common_machine_readable_attorney')}</Typography.Title>
      <Form form={form} onFinish={uploadFiles}>
        <Form.Item
          label={
            <Typography.Text size="14" weight="500" type="primary">
              {t('common_attorney_file')}
            </Typography.Text>
          }
          valuePropName="fileList"
          name={UPLOAD_FILES_FORM_ENUM.XML}>
          {xmlFile ? (
            <ImgPreview
              type="image"
              url=""
              resetImage={resetFile(UPLOAD_FILES_FORM_ENUM.XML)}
              title={xmlFile.name}
              description={`${(xmlFile.size ?? 0).toString()} Кбайт`}
            />
          ) : (
            <FileUpload
              isLoading={false}
              isError={false}
              resetHandler={resetFile(UPLOAD_FILES_FORM_ENUM.XML)}
              icon="upload"
              title={t('common_upload_a_file')}
              description={t('validation_upload_file_format', {
                name: UPLOAD_FILES_FORM_ENUM.XML,
              })}
              availableFileTypes={['.xml']}
              beforeUpload={(file) => {
                form.setFieldValue(UPLOAD_FILES_FORM_ENUM.XML, file);

                return false;
              }}
            />
          )}
        </Form.Item>

        <Form.Item
          label={
            <Typography.Text size="14" weight="500" type="primary">
              {t('common_sign_file')}
            </Typography.Text>
          }
          valuePropName="fileList"
          name={UPLOAD_FILES_FORM_ENUM.SIG}>
          {sigFile ? (
            <ImgPreview
              type="image"
              url=""
              resetImage={resetFile(UPLOAD_FILES_FORM_ENUM.SIG)}
              title={sigFile.name}
              description={`${(sigFile.size ?? 0).toString()} Кбайт`}
            />
          ) : (
            <FileUpload
              isLoading={false}
              isError={false}
              resetHandler={resetFile(UPLOAD_FILES_FORM_ENUM.SIG)}
              icon="upload"
              title={t('common_upload_a_file')}
              description={t('validation_upload_file_format', {
                name: UPLOAD_FILES_FORM_ENUM.SIG,
              })}
              availableFileTypes={['.sig']}
              beforeUpload={(file) => {
                form.setFieldValue(UPLOAD_FILES_FORM_ENUM.SIG, file);

                return false;
              }}
            />
          )}
        </Form.Item>

        <Flex gap="8">
          <Button htmlType="button" onClick={onCancel} ghost fullWidth>
            {t('common_close')}
          </Button>
          <Button
            htmlType="submit"
            leftIcon="ok"
            fullWidth
            disabled={!isSubmitDisable}
            loading={isLoading}>
            {t('common_save')}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default ModalUpload;
