import { FC, useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import {
  useCreateCommunityCategoryMutation,
  useGetCommunityCategoriesQuery,
} from 'services/communities/communitiesApiService';
import { CreateUpdateCommunityCategoryResponseType } from 'services/communities/communityApiService.types';

import {
  Button,
  Form,
  Icon,
  Input,
  Modal,
  Select,
  Typography,
  handleRequest,
} from 'gazprom-ui-lib';

import { COMMUNITY_NEWS_FORM_ENUM } from '../../form.utils';
import s from './Categories.module.scss';

const Categories: FC = () => {
  const [form] = Form.useForm();
  const parentForm = Form.useFormInstance();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [createCommunityCategory, { isLoading: isCreating }] = useCreateCommunityCategoryMutation();

  const { data: { items: communityCategories = [] } = {}, isLoading } =
    useGetCommunityCategoriesQuery({
      page: 1,
      size: 500,
    });

  const preparedCategories: { value: string; label: string }[] = communityCategories.map(
    ({ id, name }) => ({ value: id, label: name }),
  );

  const handleCheckValidation = () => form.validateFields().then(handleCategoryCreate);

  const handleModalOpenClose = () => setIsModalOpen((prevState) => !prevState);

  const handleCategoryCreate = () => {
    const alreadySelectedCategories =
      parentForm.getFieldValue(COMMUNITY_NEWS_FORM_ENUM.CATEGORIES) ?? [];
    const newCategoryName = form.getFieldValue(NEW_CATEGORY_NAME);

    const onSuccess = (data?: CreateUpdateCommunityCategoryResponseType) => {
      if (data) {
        form.setFieldValue(
          COMMUNITY_NEWS_FORM_ENUM.CATEGORIES,
          alreadySelectedCategories.concat(data.id),
        );
        handleModalOpenClose();
        form.resetFields([NEW_CATEGORY_NAME]);
      }
    };

    const onError = () => {
      form.setFields([
        {
          name: NEW_CATEGORY_NAME,
          errors: [t('errors_category_name_exist')],
        },
      ]);
    };

    createCommunityCategory({ payload: { name: newCategoryName, visible: true } }).then(
      handleRequest({
        onSuccess,
        onError,
      }),
    );
  };

  return (
    <>
      <div className={s.wrapper}>
        <Form.Item label={t('news_category')} name={COMMUNITY_NEWS_FORM_ENUM.CATEGORIES}>
          <Select
            mode="multiple"
            placeholder={t('news_select_category')}
            loading={isLoading}
            options={preparedCategories}
            filterOption={(search, option) => {
              return JSON.stringify(option)?.toLowerCase().includes(search?.toLowerCase());
            }}
          />
        </Form.Item>
        <button type="button" className={s.modalOpenButton} onClick={handleModalOpenClose}>
          <Icon name="plus" />
          <Typography.Text size="14" weight="500">
            {t('common_add_category')}
          </Typography.Text>
        </button>
      </div>
      <Modal open={isModalOpen} onCancel={handleModalOpenClose} closable>
        <Form form={form}>
          <Typography.Title level={4}>{t('common_add_category')}</Typography.Title>
          <Form.Item
            normalize={(e: string) => e.trimStart()}
            name={NEW_CATEGORY_NAME}
            className={s.categoryInput}
            rules={[formRules.required]}>
            <Input placeholder={t('common_enter_category_name')} />
          </Form.Item>
          <div className={s.actions}>
            <Button htmlType="button" type="secondary" onClick={handleModalOpenClose}>
              {t('common_close')}
            </Button>
            <Button htmlType="button" onClick={handleCheckValidation} loading={isCreating}>
              {t('common_save')}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

const NEW_CATEGORY_NAME = 'NEW_CATEGORY_NAME';

export default Categories;
