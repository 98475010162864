import { ChangeEvent, useEffect, useState } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetEmployersListQuery } from 'services/employer/employerApiService';
import { GetEmployersPropsType } from 'services/employer/employerApiService.types';

import { INITIAL_REQUEST_BODY, RequestBody, selectFromResult } from './filter.utils';

const useFilterData = () => {
  const [requestBody, setRequestBody] = useState<RequestBody>(INITIAL_REQUEST_BODY);

  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);

  const [getEmployers, employersResult] = useLazyGetEmployersListQuery({ selectFromResult });

  const employersSearchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setRequestBody((prev) => ({ ...prev, employerName: e.target.value }));
  };

  useEffect(() => {
    const defaultBody: GetEmployersPropsType = {
      page: debouncedRequestBody.page!,
      size: 500,
    };

    if (debouncedRequestBody.employerName) {
      defaultBody.name = debouncedRequestBody.employerName;
    }

    getEmployers(defaultBody);
  }, [debouncedRequestBody, getEmployers]);

  return {
    employersResult,
    employersSearchHandler,
  };
};

export default useFilterData;
