import { useEffect } from 'react';

import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { prepareDateAndTime } from 'utils/date.utils';

import { useUpdateCommunityNewsByIdMutation } from 'services/communities/communitiesApiService';
import { CreateCommunityNewsPropsType } from 'services/communities/communityApiService.types';

import { FormInstance } from 'gazprom-ui-lib';

import { CommunityNewsType } from 'types/community.types';

import { COMMUNITY_NEWS_FORM_ENUM, CommunityNewsFormResponse } from '../form/form.utils';

interface Props {
  news: CommunityNewsType | undefined;
  form: FormInstance;
}

const useFormData = (props: Props) => {
  const { news, form } = props;

  const { id, newsId } = useParams<{ id: string; newsId: string }>();

  useEffect(() => {
    if (news) {
      form.setFieldsValue({
        [COMMUNITY_NEWS_FORM_ENUM.TITLE]: news.title,
        [COMMUNITY_NEWS_FORM_ENUM.TEXT]: news.text,
        [COMMUNITY_NEWS_FORM_ENUM.COVER]: news.cover.link,
        [COMMUNITY_NEWS_FORM_ENUM.CATEGORIES]: news.categories,
        [COMMUNITY_NEWS_FORM_ENUM.PUBLISH_FROM_DATE]: dayjs(news.publishFrom),
        [COMMUNITY_NEWS_FORM_ENUM.PUBLISH_FROM_TIME]: dayjs(news.publishFrom),
        [COMMUNITY_NEWS_FORM_ENUM.PUBLISH]: news.isPublish,
      });
    }
  }, [form, news]);

  const [update, { isLoading }] = useUpdateCommunityNewsByIdMutation();

  const handleFinish = (values: CommunityNewsFormResponse) => {
    const requestBody: CreateCommunityNewsPropsType['body'] = {
      ...values,
      cover: {
        link: values.cover,
      },
      publishFrom: prepareDateAndTime(
        values[COMMUNITY_NEWS_FORM_ENUM.PUBLISH_FROM_DATE],
        values[COMMUNITY_NEWS_FORM_ENUM.PUBLISH_FROM_TIME],
      ),
      media: [],
      type: 'NEWS',
    };

    if (id && newsId) {
      update({
        body: requestBody,
        communityId: id,
        newsId: newsId,
      });
    }
  };
  return {
    isLoading,
    handleFinish,
  };
};

export default useFormData;
