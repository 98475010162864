import { prepareQueryParams } from 'utils/prepareQueryParams';

import { createGiftUrl } from 'services/gift/utils';
import initialApiService from 'services/initialApiService';

import {
  GetOrderByIdPropsType,
  GetOrderByIdResponseType,
  GetOrdersPropsType,
  GetOrdersResponseType,
} from './giftApiService.types';

const giftApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<GetOrdersResponseType, GetOrdersPropsType>({
      query: (params) => ({
        url: createGiftUrl(`/orders${prepareQueryParams(params)}`),
      }),
    }),
    getOrderById: builder.query<GetOrderByIdResponseType, GetOrderByIdPropsType>({
      query: ({ id }) => ({
        url: createGiftUrl(`/orders/${id}`),
      }),
    }),
  }),
});

export const { useLazyGetOrdersQuery, useGetOrderByIdQuery } = giftApiService;
