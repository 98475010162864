import cn from 'classnames';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Button, Form, Icon, Modal, TextArea, Typography } from 'gazprom-ui-lib';

import s from './DocumentEmployerCancelModal.module.scss';
import useActions from './useActions.hook';

export type EmployerCancelType = 'document' | 'workTour' | 'recruit';

interface Props {
  open: boolean;
  onCancel: () => void;
  id?: string;
  type: EmployerCancelType;
}

const DocumentEmployerCancelModal = (props: Props) => {
  const { open, onCancel, id, type } = props;

  const [form] = Form.useForm();

  const onSuccess = () => {
    onCancel();
    form.resetFields();
  };

  const comment = Form.useWatch('comment', form);

  const { onSubmit, isLoading } = useActions({
    id,
    type,
    employerCancelComment: comment,
    onSuccess,
  });

  return (
    <Modal open={open} onCancel={onCancel} closable>
      <Form form={form} className={s.modalWrapper} onReset={onCancel}>
        <div className={cn(s.iconWrapper, s.danger)}>
          <Icon name="delete" />
        </div>
        <Typography.Title level={3}>{t('documents_employer_cancel_title')}</Typography.Title>
        <Typography.Title level={5} className={s.description} strong={false} type="secondary">
          {t('documents_employer_cancel_reason')}
        </Typography.Title>
        <Form.Item
          name="comment"
          rules={[formRules.required]}
          normalize={(e: string) => e.trimStart()}>
          <TextArea placeholder={t('common_enter_text')} />
        </Form.Item>
        <div className={s.actions}>
          <Button
            danger
            leftIcon="unpublish"
            fullWidth
            loading={isLoading}
            onClick={onSubmit}
            disabled={!comment}>
            {t('common_cancel_employer')}
          </Button>
          <Button type="secondary" fullWidth onClick={onCancel}>
            {t('common_close')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default DocumentEmployerCancelModal;
