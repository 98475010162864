import { Tabs } from 'gazprom-ui-lib';

import s from './Tabs.module.scss';
import { tabsItems } from './tabs.utils';

// TODO.Ilya change when implementing logic
const onChange = (key: string) => {
  console.log(key);
};

const CommunityManagementViewTabs = () => {
  return (
    <Tabs
      className={s.wrapper}
      type="card"
      defaultActiveKey="NEWS"
      items={tabsItems}
      onChange={onChange}
    />
  );
};

export default CommunityManagementViewTabs;
