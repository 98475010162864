import { useParams } from 'react-router-dom';
import { COMMUNITIES_ROUTE } from 'routes/communities/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

type HeaderProps = {
  openConfirmModal: () => void;
  isSubscribersPage: boolean;
  disableSubmit: boolean;
};

const Header = ({ openConfirmModal, isSubscribersPage, disableSubmit }: HeaderProps) => {
  const { id: communityId } = useParams<{ id: string }>();

  return (
    <ContentHeader>
      <ContentHeader.Title
        goBackLink={`${COMMUNITIES_ROUTE}/management/view/${communityId}/subscribers`}>
        {t(
          isSubscribersPage
            ? 'community_add_multiple_page_title'
            : 'community_invite_multiple_page_title',
        )}
      </ContentHeader.Title>
      <ContentHeader.Actions>
        <Button disabled={disableSubmit} onClick={openConfirmModal} leftIcon="plus">
          {t(isSubscribersPage ? 'community_add_multiple_action' : 'community_invite')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
