import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import { getTimezone } from 'utils/date.utils';

import {
  useDownloadRecruitReportMutation,
  useGetRecruitStatusesQuery,
} from 'services/recruit/recruitApiService';
import {
  DownloadRecruitReportPropsType,
  GetRecruitStatusesResponseType,
} from 'services/recruit/recruitApiService.types';

import { Button, Flex, Form } from 'gazprom-ui-lib';

import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

const Filters = () => {
  const form = Form.useFormInstance();

  const { data: taskStatuses = [] } = useGetRecruitStatusesQuery(undefined, { selectFromResult });
  const [downloadRecruits, { isLoading: isDownloadRecruitsLoading }] =
    useDownloadRecruitReportMutation();

  const handleDownload = () => {
    const { filters, range, search } = form.getFieldsValue();
    const downloadRequestBody: DownloadRecruitReportPropsType = {
      filename: `Отчет по трудоустройству`,
      timeZone: getTimezone(),
      recruitFilterParam: {},
    };

    if (range) {
      downloadRequestBody.recruitFilterParam.startDate = dayjs.utc(range[0]).format();
      downloadRequestBody.recruitFilterParam.finishDate = dayjs.utc(range[1]).format();
    }
    if (filters?.status?.length) {
      downloadRequestBody.recruitFilterParam.status = filters.status;
    }
    if (search) {
      downloadRequestBody.recruitFilterParam.text = search;
    }
    downloadRecruits(downloadRequestBody);
  };

  return (
    <FiltersV2.TableFilters>
      <FiltersV2.Search />
      <Flex align="center" gap="8">
        <FiltersV2.RangePicker />
        <Button
          size="small"
          type="link"
          leftIcon="download"
          onClick={handleDownload}
          loading={isDownloadRecruitsLoading}>
          {t('common_download_report')}
        </Button>
      </Flex>

      <FiltersContainer
        showClearAll
        overlay={
          <FilterCustomWrapper>
            <FilterHeader valueKey="status">{t('common_status')}</FilterHeader>
            <FilterCheckboxGroup usePadding={false} valueKey="status" options={taskStatuses} />
          </FilterCustomWrapper>
        }
      />
    </FiltersV2.TableFilters>
  );
};

export default Filters;

const selectFromResult = ({ data, ...other }: { data?: GetRecruitStatusesResponseType }) => ({
  data: data?.map(({ code, title }) => ({ value: code, label: title })) ?? [],
  ...other,
});
