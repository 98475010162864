import { ReactElement, useState } from 'react';

import { useAuth } from 'react-oidc-context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LOGIN_ROUTE } from 'routes/login/list';
import { Trans, t } from 'tools/i18n';
import { userManager } from 'utils/keycloak';

import { Button, TIconsLiteral, Typography } from 'gazprom-ui-lib';

import s from './Error.module.scss';

const useErrorConfig = (): {
  showSupport?: boolean;
  text: string | ReactElement;
  title: string | ReactElement;
  actions?: ReactElement[];
  icon: TIconsLiteral;
} => {
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const code = searchParams.get('code');
  const onGoBack = async () => {
    setIsLoading(true);
    auth.signoutSilent().finally(() => {
      localStorage.clear();
      navigate(LOGIN_ROUTE);
      setIsLoading(false);
    });
  };

  const retryButton = (
    <Button
      onClick={() => userManager.signupRedirect()}
      fullWidth
      size="large"
      loading={isLoading}
      leftIcon="refresh"
      type="primary">
      {t('common_retry_again')}
    </Button>
  );

  const goBackButton = (
    <Button
      onClick={onGoBack}
      fullWidth
      size="large"
      loading={isLoading}
      leftIcon="arrowLeft"
      ghost
      type="primary">
      {t('common_back')}
    </Button>
  );

  const goBackIfLoggedInButton = (
    <Button
      onClick={() => navigate(-2)}
      fullWidth
      size="large"
      loading={isLoading}
      leftIcon="arrowLeft"
      ghost
      type="primary">
      {t('common_back')}
    </Button>
  );

  const retrySigninButton = (
    <Button
      onClick={async () => await auth.signinRedirect()}
      fullWidth
      size="large"
      loading={isLoading}
      leftIcon="refresh"
      type="primary">
      {t('common_retry')}
    </Button>
  );

  switch (code) {
    case CODES.USER_NOT_CONFIRMED: {
      return {
        title: (
          <Trans i18nKey="errors_user_not_confirmed">
            <br />
          </Trans>
        ),
        text: t('errors_user_confirm_ecia'),
        actions: [goBackButton],
        icon: 'verification',
        showSupport: true,
      };
    }
    case CODES.ACCESS_RESTRICTED: {
      return {
        title: t('errors_access_restricted'),
        text: t('errors_access_restricted_description'),
        actions: [goBackButton],
        icon: 'accountClosed',
      };
    }
    case CODES.USER_NOT_FOUND: {
      return {
        title: t('errors_user_not_found'),
        text: t('errors_get_help_from_hr'),
        actions: [goBackButton],
        icon: 'notFound',
      };
    }
    case CODES.INCORRECT_EMAIL: {
      return {
        title: t('errors_email_not_found'),
        text: (
          <Trans i18nKey="errors_incorrect_email">
            <br />
          </Trans>
        ),
        actions: [goBackButton],
        icon: 'unsubscribe',
      };
    }
    case CODES.TECHNICAL_ISSUES_BACK: {
      return {
        title: t('errors_service_unavailable'),
        text: t('errors_service_unavailable_description'),
        actions: [goBackButton],
        icon: 'verification',
        showSupport: true,
      };
    }
    case CODES.TECHNICAL_ISSUES_RETRY: {
      return {
        title: t('errors_service_unavailable'),
        text: t('errors_service_unavailable_description'),
        actions: [retrySigninButton],
        icon: 'verification',
        showSupport: true,
      };
    }
    case CODES.USER_HAS_NO_ACCESS_TO_APP: {
      return {
        title: t('errors_content_do_not_found'),
        text: t('errors_content_do_not_found_description'),
        actions: [auth.isAuthenticated ? goBackButton : goBackIfLoggedInButton],
        icon: 'attention',
        showSupport: true,
      };
    }
    case CODES.ALREADY_REGISTERED: {
      return {
        title: t('errors_already_registered'),
        text: <>{t('errors_already_registered_description')}</>,
        actions: [
          <Button fullWidth onClick={() => auth.signinRedirect()} key="enter__button">
            {t('common_enter')}
          </Button>,
        ],
        icon: 'accountCircle',
        showSupport: true,
      };
    }
    case CODES.INCORRECT_DATA: {
      return {
        title: t('errors_incorrect_data'),
        text: (
          <>
            {t('errors_retry_or_ask_hr')}
            <Typography.Title level={5} className={s.secondText}>
              <Trans i18nKey="errors_already_have_an_account">
                <button onClick={() => auth.signinRedirect()} className={s.loginButton}></button>
              </Trans>
            </Typography.Title>
          </>
        ),
        actions: [retryButton],
        icon: 'attention',
        showSupport: true,
      };
    }
    default: {
      return {
        title: t('errors_unknown_error'),
        text: t('errors_service_unavailable_description'),
        icon: 'attention',
        showSupport: true,
        actions: [goBackButton],
      };
    }
  }
};

const CODES = {
  USER_NOT_CONFIRMED: 'a001',
  ACCESS_RESTRICTED: 'a002',
  USER_NOT_FOUND: 'a003',
  INCORRECT_EMAIL: 'a004',
  INCORRECT_DATA: 'a005',
  ALREADY_REGISTERED: 'a007',
  TECHNICAL_ISSUES_BACK: 'a500',
  TECHNICAL_ISSUES_RETRY: 'b500',
  USER_HAS_NO_ACCESS_TO_APP: 'b403',
};

export default useErrorConfig;
