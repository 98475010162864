import { FileType } from 'types/file.types';

export const sortDocumentsFiles = (a: FileType, b: FileType) => {
  if (a?.type === 'ORDER') return -1;
  if (b?.type === 'ORDER') return 1;
  return 0;
};

export const getOrderFile = (files?: FileType[]) => {
  if (!files) {
    return null;
  }

  return files.find((file) => file?.type === 'ORDER');
};
