import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

type HeaderProps = {
  openAddEdit: () => void;
};

const Header = ({ openAddEdit }: HeaderProps) => {
  return (
    <ContentHeader>
      <ContentHeader.Title>{t('communities_categories')}</ContentHeader.Title>
      <ContentHeader.Actions>
        <Button leftIcon="plus" onClick={openAddEdit}>
          {t('common_add_category')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
