import { useParams } from 'react-router-dom';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

import { DEFAULT_FORM_STATE, FormResponseType } from '../../communityManagementSubscribers.utils';
import Filters from '../filters';
import Table from '../table/table';
import { useGetTabTableData } from '../table/useGetTabTableData.hook';
import { CommunityManagementSubscribersEnumType } from './tabs.utils';

type TabContentsProps = {
  tabType: CommunityManagementSubscribersEnumType;
};

export const TabContents = ({ tabType }: TabContentsProps) => {
  const { id: communityId } = useParams<{ id: string }>() as { id: string };
  const [form] = Form.useForm<FormResponseType>();
  const requestBody: FormResponseType = Form.useWatch([], form);

  const tabTableData = useGetTabTableData({ tabType, requestBody, communityId });

  return (
    <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
      <Filters tabType={tabType} />
      <Table communityId={communityId} tabType={tabType} {...tabTableData} />
    </FiltersV2>
  );
};
