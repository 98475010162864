import { LeaderType } from 'services/1c/cApiService.types';

import { TIconsLiteral } from 'gazprom-ui-lib';

import { DOCUMENT_VIEW_PERSON_ENUM } from 'types/documents.types';

export const checkEmptyLeaders = (el: LeaderType | null | undefined) => el !== null;

export const getParticipantStatusConfig = (
  data: LeaderType,
): { icon: TIconsLiteral; type: DOCUMENT_VIEW_PERSON_ENUM; date?: string } => {
  const { finishDate, status } = data;

  switch (status) {
    case 'APPROVED': {
      return {
        type: DOCUMENT_VIEW_PERSON_ENUM.SUCCESS,
        icon: 'ok',
        date: finishDate ?? '',
      };
    }
    case 'CANCELED':
    case 'DECLINED':
    case 'REJECTED': {
      return {
        type: DOCUMENT_VIEW_PERSON_ENUM.ERROR,
        icon: 'delete',
        date: finishDate ?? '',
      };
    }
    case 'WAITING':
    default: {
      return {
        type: DOCUMENT_VIEW_PERSON_ENUM.WARNING,
        icon: 'time',
      };
    }
  }
};
