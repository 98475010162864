import { FC, memo } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { getNewsWithIdRoute } from 'routes/news/list';
import { t } from 'tools/i18n';

import { usePinArticleMutation } from 'services/article/articleApiService';

import { Collapse, DragAndDropProps, DragAndDropTable, Typography } from 'gazprom-ui-lib';

import newsTableStyles from 'pages/news/news-table/NewsTable.module.scss';

import { ArticleType } from 'types/article.types';

import useColumns from '../useColumns.hook';
import s from './newsTablePinned.module.scss';

interface NewsPinnedTableProps {
  dataSource?: ArticleType[];
  loading: boolean;
}

const NewsTablePinned: FC<NewsPinnedTableProps> = (props) => {
  const { dataSource, loading } = props;

  const navigate = useNavigate();

  const [mutation] = usePinArticleMutation();

  const handleSort: DragAndDropProps['onDragEnd'] = ({ active, over }) => {
    if (dataSource && over) {
      const overIndex = dataSource.findIndex((i) => i.id === over.id);
      const activeIndex = dataSource.findIndex((i) => i.id === active.id);
      let pinAfter = null;

      if (overIndex > 0) {
        if (overIndex < dataSource.length - 1 && overIndex < activeIndex) {
          pinAfter = dataSource[overIndex - 1].id;
        } else {
          pinAfter = dataSource[overIndex].id;
        }
      }

      mutation({
        id: active.id as string,
        pinAfter,
      });
    }
  };

  const columns = useColumns({});

  const onRow = ({ code }: { code: string }) => ({
    onClick: () => navigate(getNewsWithIdRoute(code)),
  });

  if (dataSource?.length) {
    return (
      <Collapse
        noPadding
        noLeftBorder
        defaultActiveKey="1"
        className={s.pinnedTableCollapse}
        items={[
          {
            key: '1',
            label: (
              <Typography.Text size="16" weight="500">
                {t('news_pinned_news')}
              </Typography.Text>
            ),
            children: (
              <DragAndDropTable<ArticleType>
                loading={loading}
                dataSource={dataSource}
                columns={[
                  {
                    key: 'sort',
                  },
                  ...(columns || []),
                ]}
                className={cn(newsTableStyles.table, s.pinnedTable)}
                rowKey="id"
                dragAndDropProps={{
                  onDragEnd: handleSort,
                }}
                onRow={onRow}
              />
            ),
          },
        ]}
      />
    );
  }

  return null;
};

export default memo(NewsTablePinned);
