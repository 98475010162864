import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useLazyGetCommunityDiscussionCommentQuery } from 'services/communities/communitiesApiService';

import { ColumnsType, Flex, Icon, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TablePerson from 'components/table/table-person';

import { CommunityDiscussionComment } from 'types/community.types';

interface UseColumnsProps {
  isRemoved: boolean;
  setCurrentComment?: (comment: CommunityDiscussionComment | null) => void;
}

const useColumns = (props: UseColumnsProps) => {
  const { id, discussionId } = useParams<{ id: string; discussionId: string }>();
  const { isRemoved, setCurrentComment } = props;
  const [getComment] = useLazyGetCommunityDiscussionCommentQuery();
  const renderEmployee = ({ person, employer }: CommunityDiscussionComment['creator']) => {
    const { firstName, lastName } = person;
    const { name } = employer;

    return <TablePerson firstName={firstName} lastName={lastName} department={name} />;
  };

  const renderPosition = ({ position }: CommunityDiscussionComment['creator']) => (
    <Typography.Text size="12" weight="500" type="secondary">
      {position.name}
    </Typography.Text>
  );

  const renderComment = (
    comment: CommunityDiscussionComment['comment'],
    row: CommunityDiscussionComment,
  ) => (
    <Flex vertical>
      {row.parentId && (
        <Flex
          align="center"
          gap="4"
          onClick={(e) => {
            if (setCurrentComment) {
              e.stopPropagation();
              getComment({
                discussionId: discussionId!,
                communityId: id!,
                commentId: row.parentId,
              }).then((r) => {
                if ('data' in r) {
                  const data = r.data as CommunityDiscussionComment;
                  setCurrentComment(data);
                }
              });
            }
          }}>
          <Icon name="return" color="var(--color-primary-6)" />
          <Typography.Text size="12" weight="500" type="primary">
            {t('community_discussion_comment_reply')}
          </Typography.Text>
        </Flex>
      )}
      <Typography.Text size="12" weight="400">
        {comment}
      </Typography.Text>
    </Flex>
  );

  const renderIsRemoved = () => (
    <Flex gap="8">
      <Icon name="bin" color="rgba(0,0,0,0.25)" />
      <Typography.Text size="12" weight="500" type="secondary">
        {t('community_discussion_removed')}
      </Typography.Text>
    </Flex>
  );

  const renderDate = (date: CommunityDiscussionComment['createdAt']) => <TableDate date={date} />;

  let columns: ColumnsType<CommunityDiscussionComment> = [
    {
      title: t('common_employee'),
      dataIndex: 'creator',
      render: renderEmployee,
    },
    {
      title: t('common_staffName'),
      dataIndex: 'creator',
      render: renderPosition,
    },
    {
      title: t('common_comment'),
      dataIndex: 'comment',
      render: renderComment,
    },
    {
      title: t('thank_you_data'),
      dataIndex: 'createdAt',
      render: renderDate,
      align: 'center',
    },
  ];

  if (isRemoved) {
    columns.push({
      title: '',
      dataIndex: 'isRemoved',
      render: renderIsRemoved,
      align: 'center',
    });
  }

  return columns;
};

export default useColumns;
