import { useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import dayjs from 'dayjs';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router-dom';
import { DOCUMENTS_RECRUIT_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { useGetRecruitByIdQuery } from 'services/recruit/recruitApiService';

import { Button, Flex, Icon, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import KedoStatusTag from 'components/kedo-status-tag';
import ContentHeader from 'containers/content-header';
import DocumentEmployerCancelModal from 'containers/document-employer-cancel-modal';
import DocumentView from 'containers/document-view';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import { GLOBAL_ROLES } from 'types/roles.types';

import s from './DocumentsRecruitDetails.module.scss';
import DocumentsTable from './containers/documents-table';
import LnaTable from './containers/lna-table';

const DocumentsRecruitDetails = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { user } = useAuth();

  const [modalType, setModalType] = useState<'' | 'employerCancel'>('');

  const handleGoBack = () => {
    navigate(DOCUMENTS_RECRUIT_ROUTE);
  };

  const handleEmployerCancel = () => {
    setModalType('employerCancel');
  };

  const handleCloseEmployerCancel = () => {
    setModalType('');
  };

  const {
    data: recruit,
    isFetching: recruitIsFetching,
    isError: recruitIsError,
  } = useGetRecruitByIdQuery(id ?? skipToken);

  const userRoles = user?.profile?.roles as string[] | undefined;

  const userHasRightsToCancel = userRoles?.includes(GLOBAL_ROLES.KEDO_MODERATOR);
  const isTaskEmployerCanceled = recruit?.status?.code === 'CANCEL_EMPLOYER';

  const showCancelButton = userHasRightsToCancel && !isTaskEmployerCanceled;

  const { person, instance1c, lnaList, documentList } = recruit ?? {};

  const description = recruit?.finishDate && (
    <>
      {`${t('common_last_change')}: `}
      <Flex gap="4" align="center">
        <Icon name="calendar" />
        <Typography.Text type="secondary">
          {dayjs.utc(recruit.finishDate).local().format('DD MMMM YYYY, HH:mm')}
        </Typography.Text>
      </Flex>
    </>
  );

  return (
    <WithOverflow>
      <DocumentEmployerCancelModal
        open={modalType === 'employerCancel'}
        onCancel={handleCloseEmployerCancel}
        type="recruit"
        id={id}
      />
      <ContentHeader align="flex-start" showBorder>
        <ContentHeader.Title description={description} goBackLink={DOCUMENTS_RECRUIT_ROUTE}>
          {t('documents_recruit_conclusion')}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          {recruit?.status && <KedoStatusTag size="30" currentKedoStatus={recruit?.status} />}
        </ContentHeader.Actions>
      </ContentHeader>
      <WithLoader isLoading={recruitIsFetching}>
        <DocumentView isError={recruitIsError}>
          <DocumentView.Content>
            <Flex vertical gap="24" fullWidth>
              <Flex vertical gap="4">
                <Typography.Title level={5}>{t('common_organization')}</Typography.Title>
                <Typography.Text size="14">{instance1c?.name}</Typography.Text>
              </Flex>
              <Flex vertical gap="4">
                <Typography.Title level={5}>{t('common_employee')}</Typography.Title>
                <Bio
                  firstName={person?.firstName}
                  lastName={person?.lastName}
                  patronymic={person?.patronymic}
                />
              </Flex>
              {lnaList && (
                <Flex vertical gap="16">
                  <Typography.Title level={4}>{t('documents_recruit_lna')}</Typography.Title>
                  <LnaTable
                    data={lnaList}
                    recruitId={recruit?.id}
                    hideDownload={isTaskEmployerCanceled}
                  />
                </Flex>
              )}
              {documentList && (
                <Flex vertical gap="16">
                  <Typography.Title level={4}>
                    {t('documents_recruit_information')}
                  </Typography.Title>
                  <DocumentsTable data={documentList} hideDownload={isTaskEmployerCanceled} />
                </Flex>
              )}
            </Flex>
          </DocumentView.Content>
          <Flex gap="8" className={s.actions}>
            {showCancelButton && (
              <Button leftIcon="unpublish" fullWidth danger onClick={handleEmployerCancel}>
                {t('common_cancel_employer')}
              </Button>
            )}
            <Button fullWidth type="secondary" onClick={handleGoBack}>
              {t('common_close')}
            </Button>
          </Flex>
        </DocumentView>
      </WithLoader>
    </WithOverflow>
  );
};

export default DocumentsRecruitDetails;
