import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useGetOrderByIdQuery } from 'services/gift/giftApiService';

import { Divider, Flex, Icon, Steps, Tag, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import ContentHeader from 'containers/content-header';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './PresentDetails.module.scss';
import { convertPresentDetailsLogs } from './presentDetails.utils';

const PresentDetails = () => {
  const { id } = useParams();
  const { data: presentData, isLoading } = useGetOrderByIdQuery({ id: id! });

  const description = presentData ? (
    <Flex gap="4" align="center">
      <Icon name="info" />
      <Typography.Text type="secondary">GUID: {presentData?.id}</Typography.Text>
    </Flex>
  ) : null;

  const {
    employee,
    familyMember,
    deliveryId,
    receiverAddress,
    logs,
    email,
    phone,
    externalId,
    firstName,
    lastName,
    patronymic,
  } = presentData ?? {};

  const receiverFullName = `${lastName} ${firstName} ${patronymic}`;

  return (
    <WithOverflow>
      <ContentHeader align="flex-start" showBorder>
        <ContentHeader.Title goBackLink={-1} description={description}>
          {`${t('presents_details_title')} ${externalId ?? ''}`}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          <Tag type="outline" icon={<Icon name="info" />} className={s.tag}>
            {presentData?.status}
          </Tag>
        </ContentHeader.Actions>
      </ContentHeader>

      <WithLoader isLoading={isLoading}>
        <Flex gap="32" justify="space-between" className={s.content}>
          <Flex vertical>
            <Flex vertical gap="12" className={s.info}>
              <Typography.Title level={5}>{t('common_customer')}</Typography.Title>
              <Bio
                employerName={employee?.employer?.name}
                firstName={lastName ?? ''}
                lastName={patronymic ?? ''}
                patronymic={firstName ?? ''}
              />
              <Bio
                avatarIcon="gift"
                employerName={t('present_child_full_name')}
                firstName={familyMember?.name}
                lastName={familyMember?.surname}
                patronymic={familyMember?.patronymic}
              />
            </Flex>
            <Divider className={s.divider} />
            <Flex vertical gap="16">
              <Typography.Title>{t('present_delivery_method')}</Typography.Title>
              <Flex vertical gap="24">
                <Flex className={s.row} gap="16">
                  <Flex vertical>
                    <Typography.Title level={5}>{t('presents_delivery_type')}</Typography.Title>
                    <Typography.Text>
                      {deliveryId === 16 ? t('presents_by_pickup') : t('presents_by_post')}
                    </Typography.Text>
                  </Flex>

                  <Flex vertical>
                    <Typography.Title level={5}>{t('common_address')}</Typography.Title>
                    <Typography.Text>{receiverAddress}</Typography.Text>
                  </Flex>
                </Flex>

                <Flex className={s.row} gap="16">
                  <Flex vertical>
                    <Typography.Title level={5}>
                      {t('presents_receiver_full_name')}
                    </Typography.Title>
                    <Typography.Text>{receiverFullName}</Typography.Text>
                  </Flex>

                  <Flex vertical className={s.rowEmail}>
                    <Typography.Title level={5}>{t('common_e_mail')}</Typography.Title>
                    <Typography.Text>{email ?? '-'}</Typography.Text>
                  </Flex>

                  <Flex vertical>
                    <Typography.Title level={5}>{t('common_phone')}</Typography.Title>
                    <Typography.Text>{phone ?? '-'}</Typography.Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex vertical gap="16" className={s.history}>
            <Typography.Title level={5}>{t('present_status_history')}</Typography.Title>
            <Steps items={convertPresentDetailsLogs(logs) ?? []} />
          </Flex>
        </Flex>
      </WithLoader>
    </WithOverflow>
  );
};

export default PresentDetails;
