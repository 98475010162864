import { useParams } from 'react-router-dom';

import { useGetCommunityDiscussionByIdQuery } from 'services/communities/communitiesApiService';

const useGetData = () => {
  const { id, discussionId } = useParams<{ id: string; discussionId: string }>();

  const { data: discussion, isLoading } = useGetCommunityDiscussionByIdQuery(
    { communityId: id!, discussionId: discussionId! },
    { skip: !id || !discussionId },
  );

  const discussionTitle = discussion?.title ?? '';

  return { discussion, discussionTitle, isLoading };
};

export default useGetData;
