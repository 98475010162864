import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Flex, Form, Radio, Typography } from 'gazprom-ui-lib';

import { COMMUNITY_ACCESS_TYPE } from 'types/community.types';

import { COMMUNITY_MANAGEMENT_FORM_ENUM } from '../communityManagementForm.utils';

const CommunityManagementFormType = () => {
  return (
    <Flex vertical gap="8">
      <Flex gap="8" align="center" justify="space-between">
        <Typography.Text weight="500" size="16">
          {t('communities_type')}
        </Typography.Text>
      </Flex>
      <Form.Item rules={[formRules.required]} name={COMMUNITY_MANAGEMENT_FORM_ENUM.TYPE}>
        <Radio.Group>
          <Radio value={COMMUNITY_ACCESS_TYPE.OPEN}>{t('communities_type_open')}</Radio>
          <Radio value={COMMUNITY_ACCESS_TYPE.CLOSE}>{t('communities_type_close')}</Radio>
        </Radio.Group>
      </Form.Item>
    </Flex>
  );
};

export default CommunityManagementFormType;
