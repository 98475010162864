import { useDebounce } from 'utils/useDebounce.hook';

import { GetCommunitiesPropsType } from 'services/communities/communityApiService.types';

import { FormResponseType, useGetCommunitiesQueryHook } from './communityManagement.utils';

type UseRequestProps = {
  requestBody: FormResponseType;
};

export const useRequest = ({ requestBody }: UseRequestProps) => {
  const useGetCommunities = useGetCommunitiesQueryHook();

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  const { search, property, order, filters, ...clearedRequestBody } = debouncedRequestBody ?? {};

  const actualRequestBody: GetCommunitiesPropsType = {
    ...clearedRequestBody,
    ...filters,
    ...(search && { search }),
    ...(property && order && { sort: [{ order, property }] }),
  };

  const {
    data: { items: dataSource, totalCount: total = 0 } = {},
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetCommunities(actualRequestBody, { skip: !actualRequestBody?.page });

  const isInitialLoading = isLoading || isUninitialized;
  const loading = isFetching || isDebouncing;
  const hasFiltersApplied = !!actualRequestBody?.search?.trim();

  return { isInitialLoading, loading, dataSource, total, hasFiltersApplied };
};
