import { Form } from 'gazprom-ui-lib';

import Actions from '../actions';
import NewsForm from '../form';
import Header from './containers/header';
import useHandleSubmit from './useHandleSubmit';

const NewsCreateEdit = () => {
  const [form] = Form.useForm();

  const { isLoading, handleFinish } = useHandleSubmit();

  return (
    <Form form={form} onFinish={handleFinish}>
      <Header isLoading={isLoading} />
      <NewsForm />
      <Actions isLoading={isLoading} />
    </Form>
  );
};

export default NewsCreateEdit;
