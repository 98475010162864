import { useNavigate, useParams } from 'react-router-dom';
import { getCommunityManagementDiscussionsByIdRoute } from 'routes/communities/list';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

import { CommunityDiscussionType } from 'types/community.types';

import Filters from './containers/filters';
import { DEFAULT_FORM_STATE, FormResponseType } from './discussionsTab.utils';
import useColumns from './useColumns.hook';
import useRequest from './useRequest.hook';

const DiscussionsTab = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();

  const { isLoading, isFetching, data } = useRequest({ form });
  const columns = useColumns();

  const onRow = (discussion: CommunityDiscussionType) => ({
    onClick: () => navigate(getCommunityManagementDiscussionsByIdRoute(id!, discussion.id)),
  });

  return (
    <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
      <Filters />

      <FiltersV2.Table<CommunityDiscussionType, FormResponseType>
        isInitialLoading={isLoading}
        rowKey="id"
        dataSource={data?.items}
        total={data?.totalCount}
        loading={isFetching}
        onRow={onRow}
        columns={columns}
      />
    </FiltersV2>
  );
};

export default DiscussionsTab;
