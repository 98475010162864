import { Link, useParams } from 'react-router-dom';
import { COMMUNITY_MANAGEMENT_ROUTE } from 'routes/communities/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import { CommunityTypeWithModerators } from 'types/community.types';

import AddButtonDropdown from './containers/add-button-dropdown';
import CommunityInfo from './containers/community-info';

type HeaderProps = {
  community: CommunityTypeWithModerators;
};

const Header = ({ community }: HeaderProps) => {
  const { id: communityId } = useParams<{ id: string }>();

  return (
    <ContentHeader>
      <ContentHeader.Title goBackLink={COMMUNITY_MANAGEMENT_ROUTE}>
        <CommunityInfo community={community} />
      </ContentHeader.Title>
      <ContentHeader.Actions>
        <Link to={`${COMMUNITY_MANAGEMENT_ROUTE}/edit/${communityId}`}>
          <Button type="secondary" leftIcon="edit">
            {t('common_edit')}
          </Button>
        </Link>
        <AddButtonDropdown />
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
