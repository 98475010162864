import { t } from 'tools/i18n';

import { Form, Tabs } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import Actions from '../actions';
import NewsForm from '../form';
import s from './NewsDetails.module.scss';
import Comments from './containers/comments';
import Header from './containers/header';
import useFormData from './useFormData';
import useGetData from './useGetData';

const NewsDetails = () => {
  const [form] = Form.useForm();

  const { news, newsTitle, isLoading } = useGetData();
  const { isLoading: isUpdateLoading, handleFinish } = useFormData({ news, form });

  const TABS = [
    {
      key: '1',
      label: t('news_edit'),
      children: (
        <WithLoader isLoading={isLoading}>
          <NewsForm />
          <Actions isLoading={isUpdateLoading} />
        </WithLoader>
      ),
    },
    {
      key: '2',
      label: t('common_comments'),
      children: <Comments />,
    },
    {
      key: '3',
      label: t('common_removed'),
      children: <Comments isRemoved />,
    },
  ];

  return (
    <Form form={form} onFinish={handleFinish} className={s.form}>
      <WithOverflow>
        <Header title={newsTitle} isLoading={isUpdateLoading} />
        <Tabs type="card" defaultActiveKey="NEWS" items={TABS} className={s.tabs} />
      </WithOverflow>
    </Form>
  );
};

export default NewsDetails;
