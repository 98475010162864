import { ReactNode } from 'react';

import { t } from 'tools/i18n';

import { TabsProps } from 'gazprom-ui-lib';

import { TabContents } from './tabContents';

enum COMMUNITY_MANAGEMENT_SUBSCRIBERS_TABS_ENUM {
  SUBSCRIBERS = 'SUBSCRIBERS',
  INVITES = 'INVITES',
  BLOCKED = 'BLOCKED',
}

export type CommunityManagementSubscribersEnumType =
  keyof typeof COMMUNITY_MANAGEMENT_SUBSCRIBERS_TABS_ENUM;

type CommunityManagementSubscribersMapTabValue = {
  key: COMMUNITY_MANAGEMENT_SUBSCRIBERS_TABS_ENUM;
  label: string;
  children: ReactNode;
  getEmptyTitle: (hasFiltersApplied: boolean) => string;
};

export const COMMUNITY_MANAGEMENT_SUBSCRIBERS_TABS_MAP: Record<
  COMMUNITY_MANAGEMENT_SUBSCRIBERS_TABS_ENUM,
  CommunityManagementSubscribersMapTabValue
> = {
  SUBSCRIBERS: {
    key: COMMUNITY_MANAGEMENT_SUBSCRIBERS_TABS_ENUM.SUBSCRIBERS,
    label: t('common_participants'),
    children: <TabContents tabType="SUBSCRIBERS" />,
    getEmptyTitle: (hasFiltersApplied) =>
      hasFiltersApplied
        ? t('community_subscribers_empty_title_filters')
        : t('community_subscribers_empty_title'),
  },
  INVITES: {
    key: COMMUNITY_MANAGEMENT_SUBSCRIBERS_TABS_ENUM.INVITES,
    label: t('community_invites'),
    children: <TabContents tabType="INVITES" />,
    getEmptyTitle: (hasFiltersApplied) =>
      hasFiltersApplied
        ? t('community_invites_empty_title_filters')
        : t('community_invites_empty_title'),
  },
  BLOCKED: {
    key: COMMUNITY_MANAGEMENT_SUBSCRIBERS_TABS_ENUM.BLOCKED,
    label: t('community_blocked'),
    children: <TabContents tabType="BLOCKED" />,
    getEmptyTitle: (hasFiltersApplied) =>
      hasFiltersApplied
        ? t('community_blocked_empty_title_filters')
        : t('community_blocked_empty_title'),
  },
};

export const tabsItems: TabsProps['items'] = Object.values(
  COMMUNITY_MANAGEMENT_SUBSCRIBERS_TABS_MAP,
).map((tab) => tab);
