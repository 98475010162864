import { t } from 'tools/i18n';

import { useUnblockSubscriberMutation } from 'services/communities/communitiesApiService';

import { Button, ModalDefault } from 'gazprom-ui-lib';

type UnblockModalProps = {
  open: boolean;
  close: () => void;
  communityId: string;
  subscriberId: string;
  onSuccessBlockUnblockMutation: () => void;
};

const UnblockModal = ({
  open,
  close,
  subscriberId,
  communityId,
  onSuccessBlockUnblockMutation,
}: UnblockModalProps) => {
  const [unblockSubscriber, { isLoading }] = useUnblockSubscriberMutation();

  const handleUnblock = async () => {
    try {
      await unblockSubscriber({ communityId, subscriberId }).unwrap();
      onSuccessBlockUnblockMutation();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ModalDefault width={320} open={open} onCancel={close} closable>
      <ModalDefault.Icon name="lockOpenRight" type="primary" />
      <ModalDefault.Title>{t('community_unblock_reason_modal_title')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('community_unblock_reason_modal_description')}
      </ModalDefault.Description>

      <ModalDefault.Actions>
        <Button loading={isLoading} fullWidth leftIcon="ok" onClick={handleUnblock}>
          {t('common_accept')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default UnblockModal;
