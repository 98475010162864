import dayjs from 'dayjs';

type hasTimePassedProps = {
  sinceDate: string;
  targetDate?: dayjs.Dayjs;
  quantity: number;
  unit?: dayjs.QUnitType;
};

export const hasTimePassed = ({
  quantity,
  sinceDate,
  targetDate = dayjs(),
  unit = 'hour',
}: hasTimePassedProps): boolean => {
  const since = dayjs(sinceDate);
  return targetDate.diff(since, unit) >= quantity;
};
