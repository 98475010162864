import { Link, useParams } from 'react-router-dom';
import { COMMUNITIES_ROUTE, COMMUNITY_MANAGEMENT_ROUTE } from 'routes/communities/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import { CommunityManagementSubscribersEnumType } from '../tabs/tabs.utils';

type HeaderProps = {
  currentTab: CommunityManagementSubscribersEnumType;
};

const Header = ({ currentTab }: HeaderProps) => {
  const { id: communityId } = useParams<{ id: string }>();

  const renderHeaderActions = () => {
    if (currentTab === 'SUBSCRIBERS') {
      return (
        <Link to={`${COMMUNITY_MANAGEMENT_ROUTE}/view/${communityId}/subscribers/add`}>
          <Button leftIcon="groupsFilled">{t('community_add_multiple')}</Button>
        </Link>
      );
    } else if (currentTab === 'INVITES') {
      return (
        <Link to={`${COMMUNITY_MANAGEMENT_ROUTE}/view/${communityId}/subscribers/invite`}>
          <Button leftIcon="plus">{t('community_invite_multiple')}</Button>
        </Link>
      );
    } else {
      return null;
    }
  };

  return (
    <ContentHeader>
      <ContentHeader.Title goBackLink={`${COMMUNITIES_ROUTE}/management/view/${communityId}`}>
        {t('community_subscribers')}
      </ContentHeader.Title>
      <ContentHeader.Actions>{renderHeaderActions()}</ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
