import { useCallback, useMemo } from 'react';

import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { getWorkerEmployeesSelector } from 'services/1c/cApiService';
import { useAppSelector } from 'store';

import { Avatar, DropdownProps, Flex, Icon, Typography } from 'gazprom-ui-lib';

import { EmployeeType } from 'types/employee.types';

import s from './Header.module.scss';
import Employee from './containers/employee';

const useMenuItems = () => {
  const { data: employees } = useAppSelector(getWorkerEmployeesSelector);
  const [selectedEmployee, setSelectedEmployee] = useFullSelectedEmployee();

  const renderCompany = useCallback(
    (employee: EmployeeType) => {
      const handleSelect = () => setSelectedEmployee(employee.id);
      const isSelected = selectedEmployee?.id === employee.id;

      return {
        key: employee.id,
        label: (
          <Employee
            handleSelect={handleSelect}
            employee={employee}
            isSelected={isSelected}
            showDivision={false}
          />
        ),
        disabled: isSelected,
      } as DropdownProps['items'][0];
    },
    [selectedEmployee?.id, setSelectedEmployee],
  );

  const items = useMemo(() => {
    const mappedData = employees?.map(renderCompany) || [];
    const { firstName, lastName, patronymic } = selectedEmployee?.person || {
      firstName: '',
      lastName: '',
      patronymic: '',
    };
    const fullName = `${lastName} ${firstName} ${patronymic}`;

    mappedData.unshift({
      key: 'person',
      label: (
        <Flex gap="8" align="center">
          <Avatar
            firstName={lastName}
            lastName={firstName}
            alt="аватар пользователя"
            size="40"
            color="#21587A"
          />
          <Flex vertical className={s.textContainer}>
            <Typography.Text size="14" weight="500">
              {fullName}
            </Typography.Text>
          </Flex>
        </Flex>
      ),
      disabled: true,
      className: s.person,
    });

    mappedData.push({
      key: 'logout',
      label: (
        <Flex align="center" justify="center" className={s.logoutButton} gap="8">
          <Icon name="logout" />
          <Typography.Text size="14" weight="500" type="primary">
            {t('common_logout')}
          </Typography.Text>
        </Flex>
      ),
    });

    return mappedData;
  }, [employees, renderCompany, selectedEmployee?.person]);

  return items;
};

export default useMenuItems;
