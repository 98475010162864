import { useNavigate, useParams } from 'react-router-dom';
import { getCommunityManagementViewByIdRoute } from 'routes/communities/list';
import { prepareDateAndTime } from 'utils/date.utils';

import { useCreateCommunityNewsMutation } from 'services/communities/communitiesApiService';
import { CreateCommunityNewsPropsType } from 'services/communities/communityApiService.types';

import { COMMUNITY_NEWS_FORM_ENUM, CommunityNewsFormResponse } from '../form/form.utils';

const useHandleSubmit = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [create, { isLoading }] = useCreateCommunityNewsMutation();

  const handleNavigate = () => navigate(getCommunityManagementViewByIdRoute(id!));

  const handleFinish = (values: CommunityNewsFormResponse) => {
    const requestBody: CreateCommunityNewsPropsType['body'] = {
      ...values,
      cover: {
        link: values.cover,
      },
      publishFrom: prepareDateAndTime(
        values[COMMUNITY_NEWS_FORM_ENUM.PUBLISH_FROM_DATE],
        values[COMMUNITY_NEWS_FORM_ENUM.PUBLISH_FROM_TIME],
      ),
      media: [],
      type: 'NEWS',
    };

    if (id) {
      create({
        body: requestBody,
        communityId: id,
      });
    }

    handleNavigate();
  };
  return {
    isLoading,
    handleFinish,
  };
};

export default useHandleSubmit;
