// import { ReactComponent as GspAnniversary } from 'assets/icons/gsp-anniversary.svg';
import Loader from 'components/loader';

import s from './LoginLoader.module.scss';

const LoginLoader = () => {
  return (
    <div className={s.wrapper}>
      <Loader />
    </div>
  );
};

export default LoginLoader;
