import { FC, useEffect, useRef } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Form } from 'gazprom-ui-lib';

import Editor from 'containers/editor';
import { EVENT_FORM_ENUM } from 'containers/event-form/eventForm.utils';

import { COMMUNITY_NEWS_FORM_ENUM } from '../../form.utils';

const Text: FC = () => {
  const form = Form.useFormInstance();

  const editorRef = useRef<Editor>(null);
  const setRef = useRef<boolean>(false);

  const editorValue = Form.useWatch(COMMUNITY_NEWS_FORM_ENUM.TEXT);

  useEffect(() => {
    if (editorValue && editorRef && !setRef.current) {
      editorRef.current?.setEditorData(editorValue);
      setRef.current = true;
    }
  }, [editorRef, editorValue]);

  const handleSetEditorValue = (v: string) => {
    setRef.current = true;
    form.setFieldValue(EVENT_FORM_ENUM.DESCRIPTION, v);
  };

  return (
    <Form.Item
      label={t('news_content_for_news')}
      name={COMMUNITY_NEWS_FORM_ENUM.TEXT}
      rules={[formRules.required]}>
      <Editor onChange={handleSetEditorValue} ref={editorRef} />
    </Form.Item>
  );
};

export default Text;
