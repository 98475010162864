import { SORT_ENUM } from 'types/sort.types';

export type FormResponseType = {
  search?: string;
  page: number;
  size: number;
  order: SORT_ENUM;
  property: string;
  filters: {
    employerIds?: string[];
  };
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  search: '',
  page: 1,
  size: 10,
  order: SORT_ENUM.DESC,
  property: 'updatedAt',
  filters: {},
};
