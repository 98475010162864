import { SORT_ENUM } from 'types/sort.types';

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  property: 'updatedAt',
  order: SORT_ENUM.DESC,
  search: '',
};

export type FormFiltersType = {
  deliveryType?: number[];
};

export type FormResponseType = {
  page: number;
  size: number;
  property?: string;
  order?: SORT_ENUM;
  search: string;
  filters?: FormFiltersType;
};
