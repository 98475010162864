export const OPTIONS = [
  {
    value: 'STRING',
    label: 'Строка',
  },
  {
    value: 'FILE',
    label: 'Файл',
  },
  {
    value: 'TEXT_AREA',
    label: 'Текcт',
  },
  {
    value: 'YEARS_LIST',
    label: 'Список годов',
  },
  {
    value: 'DATE',
    label: 'Дата',
  },
  {
    value: 'BOOLEAN',
    label: 'Да/Нет',
  },
  {
    value: 'VACATION',
    label: 'Вид отпуска',
  },
  {
    value: 'DAY_OFF_TYPE',
    label: 'Вид выходного дня',
  },
  {
    value: 'HOLIDAYS',
    label: 'Категория выходных дней',
  },
  {
    value: 'WORKDAY_TYPE',
    label: 'Признак нормированности рабочего дня',
  },
  {
    value: 'WORKBOOK_TYPE',
    label: 'Вид копии трудовой',
  },
  {
    value: 'SCHEDULE',
    label: 'График работы',
  },
  {
    value: 'INTEGER',
    label: 'Целочисленное',
  },
  {
    value: 'MULTIFILE',
    label: 'Мультифайл',
  },
  {
    value: 'HINT',
    label: 'Подсказка',
  },
];
