import dayjs from 'dayjs';
import { t } from 'tools/i18n';

export type OnDateChangeValueType = [string | undefined, string | undefined];
export type DatePickerButtonType = { id: number; text: string; onClickValue: null | dayjs.Dayjs[] };

export const DEFAULT_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';

const today = dayjs().endOf('day');
const dayValue = [dayjs().startOf('day'), today];
const weekValue = [dayjs().subtract(6, 'days').startOf('day'), today];
const monthValue = [dayjs().subtract(1, 'month').startOf('day'), today];
const yearValue = [dayjs().subtract(1, 'year').startOf('day'), today];

export const datePickerButtons: DatePickerButtonType[] = [
  {
    id: 1,
    text: t('common_all_date'),
    onClickValue: null,
  },
  {
    id: 2,
    text: t('common_today'),
    onClickValue: dayValue,
  },
  {
    id: 3,
    text: t('common_week'),
    onClickValue: weekValue,
  },
  {
    id: 4,
    text: t('common_month'),
    onClickValue: monthValue,
  },
  {
    id: 5,
    text: t('common_year'),
    onClickValue: yearValue,
  },
];
