import { FC, ReactElement, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router-dom';
import { DOCUMENTS_BUSINESS_TRIPS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';
import { getOrderFile } from 'utils/files.utils';

import { useGetBusinessTripByIdQuery } from 'services/1c/work-tour/workTourApiService';

import { Button, Flex, Typography } from 'gazprom-ui-lib';

import DocumentEmployerCancelModal from 'containers/document-employer-cancel-modal';
import DocumentView from 'containers/document-view';
import { TabsTypes } from 'containers/document-view/containers/tabs/tabs.utils';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import { GLOBAL_ROLES } from 'types/roles.types';

import { transformBusinessTripPeriod } from '../documentsBusinessTrips.utils';
import s from './DocumentsBusinessTripsDetails.module.scss';
import ApprovalHistory from './containers/approval-history';
import Files from './containers/files';
import Header from './containers/header';
import Leaders from './containers/leaders';

const DocumentsBusinessTripsDetails: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { user } = useAuth();

  const [modalType, setModalType] = useState<'' | 'employerCancel'>('');

  const {
    data: businessTrip,
    isFetching: businessTripIsFetching,
    isError: businessTripIsError,
  } = useGetBusinessTripByIdQuery(id ?? skipToken);

  const userRoles = user?.profile?.roles as string[] | undefined;

  const userHasRightsToCancel = userRoles?.includes(GLOBAL_ROLES.KEDO_MODERATOR);
  const isTaskEmployerCanceled = businessTrip?.currentKedoStatus?.status === 'CANCEL_EMPLOYER';

  const showCancelButton = userHasRightsToCancel && !isTaskEmployerCanceled;

  const handleGoBack = () => {
    navigate(DOCUMENTS_BUSINESS_TRIPS_ROUTE);
  };

  const handleEmployerCancel = () => {
    setModalType('employerCancel');
  };

  const handleCloseEmployerCancel = () => {
    setModalType('');
  };

  const orderNumber = getOrderFile(businessTrip?.files)?.numberDoc ?? '';

  const tabs: { key: TabsTypes; label: string; children: ReactElement }[] = [
    {
      key: 'attached',
      label: t('common_attached_files'),
      children: <Files files={businessTrip?.files} id={id} hideDownload={isTaskEmployerCanceled} />,
    },
    {
      key: 'additional',
      label: t('common_process_participants'),
      children: (
        <Leaders
          firstLeader={businessTrip?.firstLeader}
          approvedLeader={businessTrip?.approvedLeader}
        />
      ),
    },
    {
      key: 'approval-history',
      label: t('documents_statements_approval_history'),
      children: (
        <ApprovalHistory
          workTourId={businessTrip?.id}
          approvalMap={businessTrip?.approvalMap}
          orderDate={businessTrip?.createdAt}
          numberDoc={orderNumber}
        />
      ),
    },
  ];

  const { employee, dateFrom, dateTo, sourceFinancing, targetComment, destination, target } =
    businessTrip ?? {};

  return (
    <WithOverflow>
      <DocumentEmployerCancelModal
        open={modalType === 'employerCancel'}
        onCancel={handleCloseEmployerCancel}
        type="workTour"
        id={id}
      />
      <Header businessTrip={businessTrip} />
      <WithLoader isLoading={businessTripIsFetching}>
        <DocumentView isError={businessTripIsError}>
          <DocumentView.Content>
            <Flex vertical gap="24">
              <DocumentView.Employee employee={employee} />
              <Flex vertical gap="4">
                <Typography.Title level={5}>{t('documents_business_trip_period')}</Typography.Title>
                <Typography.Text size="14">
                  {transformBusinessTripPeriod(dateFrom, dateTo)}
                </Typography.Text>
              </Flex>
              <Flex vertical gap="4">
                <Typography.Title level={5}>{t('documents_destination')}</Typography.Title>
                <Typography.Text size="14">{destination}</Typography.Text>
              </Flex>
              <Flex vertical gap="4">
                <Typography.Title level={5}>{t('documents_target')}</Typography.Title>
                <Typography.Text size="14">{target?.name}</Typography.Text>
              </Flex>
              {targetComment && (
                <Flex vertical gap="4">
                  <Typography.Title level={5}>{t('common_clarification')}</Typography.Title>
                  <Typography.Text size="14">{targetComment}</Typography.Text>
                </Flex>
              )}
              <Flex vertical gap="4">
                <Typography.Title level={5}>{t('documents_source_of_finance')}</Typography.Title>
                <Typography.Text size="14">{sourceFinancing}</Typography.Text>
              </Flex>
            </Flex>
          </DocumentView.Content>
          <DocumentView.Tabs items={tabs} className={s.tabs} />
          <Flex gap="8" className={s.actions}>
            {showCancelButton && (
              <Button leftIcon="unpublish" fullWidth danger onClick={handleEmployerCancel}>
                {t('common_cancel_employer')}
              </Button>
            )}
            <Button fullWidth type="secondary" onClick={handleGoBack}>
              {t('common_close')}
            </Button>
          </Flex>
        </DocumentView>
      </WithLoader>
    </WithOverflow>
  );
};

export default DocumentsBusinessTripsDetails;
