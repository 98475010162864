import { FC } from 'react';

import cn from 'classnames';

import { Collapse, CollapseProps } from 'gazprom-ui-lib';

import s from './FilterCollapse.module.scss';

interface FilterCollapseProps {
  defaultActiveKey?: string | number[];
  items: CollapseProps['items'];
  className?: string;
}

const FilterCollapse: FC<FilterCollapseProps> = (props) => {
  const { className, items, defaultActiveKey } = props;

  return (
    <Collapse
      accordion
      className={cn(s.wrapper, className)}
      items={items}
      noLeftBorder
      defaultActiveKey={defaultActiveKey}
    />
  );
};

export default FilterCollapse;
