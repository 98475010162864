import { DataNode } from 'gazprom-ui-lib';

import { OnLoadArguments } from 'types/employeeGroups.types';

export const filterTree = (nodes: DataNode[], filter: string): DataNode[] => {
  return nodes
    .map((node) => {
      const { title, children } = node as OnLoadArguments & { title: string };
      const titleMatches = title && title.toLowerCase().includes(filter.toLowerCase());

      const filteredChildren = children ? filterTree(children, filter) : [];

      if (titleMatches || filteredChildren.length > 0) {
        return { ...node, children: filteredChildren };
      }

      return null;
    })
    .filter((node) => node !== null) as DataNode[];
};
