import { t } from 'tools/i18n';
import { hasTimePassed } from 'utils/hasTimePassed.utils';
import { truncateText } from 'utils/truncateText.utils';

import { Button, ColumnsType, Flex, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TablePerson from 'components/table/table-person';

import { CommunityInviteStatus, CommunityInviteType } from 'types/communityInvite.types';
import { CommunityEmployeeType, CommunitySubscriberType } from 'types/communitySubscriber.types';

import { RESEND_INVITE_COOLDOWN } from '../../communityManagementSubscribers.utils';
import ResendInviteButton from '../resend-invite-button';
import StatusTag from '../status-tag';
import { CommunityManagementSubscribersEnumType } from '../tabs/tabs.utils';
import { HandleOpenBlockUnblockModalProps } from './table';
import { SEND_STATUS_MAP } from './useGetTabTableColumns.utils';

type UseTabColumnsProps = {
  tabType: CommunityManagementSubscribersEnumType;
  handleOpenBlockUnblockModal: (props: HandleOpenBlockUnblockModalProps) => void;
};

const MAX_TEXT_LENGTH = 100;

const sharedColumns = [
  {
    title: t('common_employee'),
    dataIndex: 'employee',
    render: ({
      person: { firstName, lastName },
      employer: { name: employerName },
    }: CommunityEmployeeType) => (
      <TablePerson firstName={firstName} lastName={lastName} department={employerName} />
    ),
  },
  {
    title: t('common_staffName'),
    dataIndex: 'employee',
    render: ({ position: { name: positionName } }: CommunityEmployeeType) => (
      <Typography.Text weight="500" type="secondary">
        {truncateText(positionName, MAX_TEXT_LENGTH)}
      </Typography.Text>
    ),
  },
  {
    title: t('common_department'),
    dataIndex: 'employee',
    render: ({ division: { name: departmentName } }: CommunityEmployeeType) => (
      <Typography.Text weight="500" type="secondary">
        {truncateText(departmentName, MAX_TEXT_LENGTH)}
      </Typography.Text>
    ),
  },
];

export const useGetTabTableColumns = ({
  tabType,
  handleOpenBlockUnblockModal,
}: UseTabColumnsProps): ColumnsType<CommunitySubscriberType | CommunityInviteType> => {
  switch (tabType) {
    case 'SUBSCRIBERS':
      return [
        ...sharedColumns,
        {
          title: '',
          render: (subscriber: CommunitySubscriberType) => (
            <Flex justify="center">
              <Button
                size="small"
                type="link"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenBlockUnblockModal({ modalType: 'block', subscriber });
                }}
                leftIcon="passwordFilled">
                {t('common_block')}
              </Button>
            </Flex>
          ),
        },
      ];
    case 'INVITES':
      return [
        ...sharedColumns,
        {
          title: t('common_status'),
          dataIndex: 'status',
          key: 'status',
          sorter: true,
          render: (status: CommunityInviteStatus) => {
            const inviteProps = SEND_STATUS_MAP[status];
            return <StatusTag {...inviteProps} />;
          },
        },
        {
          title: t('community_invitation_send_date'),
          align: 'center',
          dataIndex: 'sendDate',
          render: (sendDate: string) => <TableDate date={sendDate} />,
        },
        {
          title: t('community_retry_invitation'),
          align: 'center',
          render: (record: CommunityInviteType) => {
            const { id: inviteId, communityId, sendDate } = record;
            const has24hPassed = hasTimePassed({
              quantity: RESEND_INVITE_COOLDOWN,
              sinceDate: sendDate,
            });
            return (
              <Flex justify="center" fullWidth>
                <ResendInviteButton
                  communityId={communityId}
                  inviteId={inviteId}
                  has24hPassed={has24hPassed}
                  location="table"
                />
              </Flex>
            );
          },
        },
      ];
    case 'BLOCKED':
      return [
        ...sharedColumns,
        {
          title: t('community_block_reason'),
          dataIndex: 'reasonBlock',
          render: (reasonBlock: string) => (
            <Typography.Text type="secondary">
              {truncateText(reasonBlock, MAX_TEXT_LENGTH)}
            </Typography.Text>
          ),
        },
        {
          title: '',
          render: (subscriber: CommunitySubscriberType) => (
            <Flex justify="center">
              <Button
                size="small"
                type="link"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenBlockUnblockModal({ modalType: 'unblock', subscriber });
                }}
                leftIcon="lockReset">
                {t('community_unblock')}
              </Button>
            </Flex>
          ),
        },
      ];
  }
};
