import { useState } from 'react';

import { t } from 'tools/i18n';

import { useGetEmployees1cQuery, useUpdateExecutorMutation } from 'services/1c/cApiService';
import { GetEmployees1cResponseType } from 'services/1c/cApiService.types';

import { Button, Flex, ModalDefault, Select, Typography } from 'gazprom-ui-lib';

import s from './ChangeParticipantModal.module.scss';
import { BaseOptionType } from './changeParticipantModal.utils';

interface Props {
  isOpen: boolean;
  employerId?: string;
  executorId?: string;
  close: () => void;
}

const DEFAULT_PAGE_PARAMS = {
  page: 1,
  size: 500,
};

const ChangeParticipantModal = (props: Props) => {
  const { isOpen, employerId, executorId, close } = props;

  const [newExecutorId, setNewExecutorId] = useState<string | null>(null);

  const { data: employeesOptions } = useGetEmployees1cQuery(
    { ...DEFAULT_PAGE_PARAMS, employerIds: [employerId!] },
    { skip: !employerId, selectFromResult: simpleSelectFromResult },
  );
  const [updateExecutor, { isLoading }] = useUpdateExecutorMutation();

  const handleUpdate = () => {
    if (executorId && newExecutorId) {
      updateExecutor({
        executorId,
        body: { employeeId: newExecutorId },
      }).finally(close);
    }
  };

  return (
    <ModalDefault open={isOpen} onCancel={close} width={520} closable>
      <ModalDefault.Icon type="primary" name="refresh" />
      <ModalDefault.Title>{t('documents_change_participant')}</ModalDefault.Title>
      <ModalDefault.Description>{t('documents_choose_another_person')}</ModalDefault.Description>
      <Select
        onChange={setNewExecutorId}
        className={s.search}
        options={employeesOptions}
        placeholder={t('common_select_from_list')}
        showSearch
        filterOption
        optionFilterProp="name"
        optionRender={(option) => {
          const { name, position } = option.data as BaseOptionType;
          return (
            <Flex vertical key={option.key}>
              <Typography.Text size="14">{name}</Typography.Text>
              <Typography.Text size="12" type="secondary">
                {position}
              </Typography.Text>
            </Flex>
          );
        }}
      />
      <ModalDefault.Actions>
        <Flex gap="8">
          <Button fullWidth type="primary" onClick={handleUpdate} loading={isLoading}>
            {t('common_accept')}
          </Button>
          <Button fullWidth type="secondary" onClick={close}>
            {t('common_cancel')}
          </Button>
        </Flex>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

const simpleSelectFromResult = ({ data, ...other }: { data?: GetEmployees1cResponseType }) => ({
  data:
    data?.employees?.map(({ id, person, position }) => {
      const { firstName, lastName } = person;
      const { name } = position;
      const fullName = `${firstName} ${lastName}`;
      return {
        value: id,
        label: (
          <Flex align="baseline" gap="8">
            <Typography.Text size="14">{fullName}</Typography.Text>
            <Typography.Text size="12" type="secondary">
              {name}
            </Typography.Text>
          </Flex>
        ),
        name: fullName,
        position: name,
      };
    }) ?? [],
  ...other,
});

export default ChangeParticipantModal;
