import { t } from 'tools/i18n';

// import { getFio } from 'utils/getFio.utils';
import { ColumnsType, Flex, Icon, Tag, Typography } from 'gazprom-ui-lib';

import Cover from 'components/cover';
import TableDate from 'components/table/table-date';

import { CommunityTypeWithoutModerators } from 'types/community.types';

import s from './Table.module.scss';

const useColumns = (): ColumnsType<CommunityTypeWithoutModerators> => [
  {
    title: t('community_name'),
    dataIndex: 'name',
    render: (
      name: CommunityTypeWithoutModerators['name'],
      { avatar, categories }: CommunityTypeWithoutModerators,
    ) => {
      const communityCategories = categories.map(({ id, name }) => (
        <Tag className={s.communityCategoryTag} type="primary" key={id}>
          {name}
        </Tag>
      ));
      return (
        <Flex align="center" gap="12">
          <Cover className={s.communityCover} src={avatar?.link} alt="Аватар сообщества" />
          <Flex vertical gap="8">
            <Typography.Text size="12" weight="500">
              {name}
            </Typography.Text>
            <Flex gap="4">{communityCategories}</Flex>
          </Flex>
        </Flex>
      );
    },
  },
  // TODO.Ilya от этого поля отказались? В списке не возвращается
  // {
  //   title: t('community_moderator'),
  //   dataIndex: 'moderators',
  //   key: 'moderators',
  //   sorter: true,
  //   render: (moderators: CommunityTypeWithoutModerators['moderators']) => {
  //     const communityModerators = moderators.map(
  //       ({ id, person: { firstName, lastName, patronymic } }) => {
  //         const fioInitials = getFio({
  //           firstName,
  //           lastName,
  //           patronymic,
  //         });

  //         return (
  //           <Typography.Text size="14" weight="500" key={id}>
  //             {fioInitials}
  //           </Typography.Text>
  //         );
  //       },
  //     );
  //     return (
  //       <Flex vertical gap="4">
  //         {communityModerators}
  //       </Flex>
  //     );
  //   },
  // },
  {
    title: t('common_publication'),
    dataIndex: 'isPublish',
    align: 'center',
    render: (isPublish: CommunityTypeWithoutModerators['isPublish']) => {
      return (
        <Icon
          name="okFilled"
          color={`var(${isPublish ? '--color-success' : '--color-placeholder-25'})`}
        />
      );
    },
  },
  {
    title: t('common_type'),
    dataIndex: 'type',
    align: 'center',
    key: 'type',
    sorter: true,
    render: (type: CommunityTypeWithoutModerators['type']) => (
      <Icon
        name={type === 'CLOSE' ? 'passwordFilled' : 'lockOpenRight'}
        color="var(--color-primary)"
      />
    ),
  },
  {
    title: t('community_moderation'),
    dataIndex: 'articleModerationCount',
    align: 'center',
    key: 'articleModerationCount',
    sorter: true,
    render: (articleModerationCount: CommunityTypeWithoutModerators['articleModerationCount']) =>
      articleModerationCount > 0 && <Icon name="error" color="var(--color-error)" />,
  },
  {
    title: t('common_participants'),
    dataIndex: 'countMembers',
    align: 'center',
    render: (countMembers: CommunityTypeWithoutModerators['countMembers']) => (
      <Flex gap="8" align="center">
        <Icon name="groupsFilled" color="var(--color-primary)" />
        <Typography.Text className={s.countMembers} weight="500" size="12">
          {countMembers}
        </Typography.Text>
      </Flex>
    ),
  },
  {
    title: t('common_created'),
    align: 'center',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: true,
    render: (createdAt: CommunityTypeWithoutModerators['createdAt']) => (
      <TableDate date={createdAt} />
    ),
  },
  {
    title: t('common_updated'),
    align: 'center',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    defaultSortOrder: 'descend',
    sorter: true,
    render: (updatedAt: CommunityTypeWithoutModerators['updatedAt']) => (
      <TableDate date={updatedAt} />
    ),
  },
];

export default useColumns;
