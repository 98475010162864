import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { useCreateCommunityCategoryMutation } from 'services/communities/communitiesApiService';

import { Button, Flex, Form, Input, Modal, Typography } from 'gazprom-ui-lib';

type CommunityCategoryModalProps = {
  open: boolean;
  onClose: () => void;
  onFinish: (id: string) => void;
};

enum COMMUNITY_CATEGORY_MODAL_ENUM {
  NAME = 'name',
}

const CommunityCategoryModal = ({ open, onClose, onFinish }: CommunityCategoryModalProps) => {
  const [form] = Form.useForm();

  const [createCommunityCategory, { isLoading: isCreating }] = useCreateCommunityCategoryMutation();

  const handleSubmit = async (values: { name: string }) => {
    const payload = { payload: values };
    try {
      const { id } = await createCommunityCategory({ ...payload }).unwrap();
      onFinish;
      handleOnFinish(id);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseModal = () => {
    onClose();
    form.resetFields();
  };

  const handleOnFinish = (id: string) => {
    onFinish(id);
    handleCloseModal();
  };

  const disableSubmit = isCreating;

  return (
    <Modal open={open} onCancel={handleCloseModal} closable>
      <Flex vertical gap="16">
        <Typography.Title level={4}>{t('common_add_category')}</Typography.Title>

        <Flex vertical gap="8">
          <Typography.Text size="16" weight="500">
            {t('common_name')}
          </Typography.Text>

          <Form form={form} onFinish={handleSubmit}>
            <Form.Item name={COMMUNITY_CATEGORY_MODAL_ENUM.NAME} rules={[formRules.required]}>
              <Input placeholder={t('common_enter_text')} />
            </Form.Item>

            <Flex gap="8" align="center">
              <Button
                disabled={disableSubmit}
                htmlType="submit"
                loading={disableSubmit}
                leftIcon="plus"
                fullWidth>
                {t('common_add')}
              </Button>
              <Button htmlType="button" type="secondary" onClick={handleCloseModal} fullWidth>
                {t('common_close')}
              </Button>
            </Flex>
          </Form>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default CommunityCategoryModal;
