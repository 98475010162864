import { t } from 'tools/i18n';

import { Button, Flex, ModalDefault, TextArea, Typography } from 'gazprom-ui-lib';

import s from './CreateCommentModal.module.scss';
import useRequest from './useRequest';

interface Props {
  open: boolean;
  handleClose: () => void;
  parentId?: string;
}

const CreateCommentModal = (props: Props) => {
  const { open, handleClose, parentId } = props;

  const { isSendResponseLoading, newComment, setNewComment, handleSendResponse } = useRequest({
    handleClose,
    parentId,
  });

  return (
    <ModalDefault open={open} onCancel={handleClose} width={520} closable>
      <Flex vertical gap="16" className={s.container} align="flex-start">
        <Typography.Title level={4}>{t('common_add_comment')}</Typography.Title>
        <TextArea
          placeholder={t('common_enter_text')}
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />
      </Flex>

      <ModalDefault.Actions>
        <Flex gap="16">
          <Button fullWidth type="secondary" onClick={() => {}} loading={isSendResponseLoading}>
            {t('common_close')}
          </Button>
          <Button
            fullWidth
            type="primary"
            rightIcon="arrowRight"
            onClick={handleSendResponse}
            loading={isSendResponseLoading}>
            {t('common_send')}
          </Button>
        </Flex>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default CreateCommentModal;
