import { Link } from 'react-router-dom';
import { COMMUNITY_MANAGEMENT_CREATE_ROUTE } from 'routes/communities/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import { useGetIsCommunityModerator } from '../../communityManagement.utils';

const Header = () => {
  const isCommunityModerator = useGetIsCommunityModerator();
  return (
    <ContentHeader>
      <ContentHeader.Title>{t('communities')}</ContentHeader.Title>
      <ContentHeader.Actions>
        {isCommunityModerator && (
          <Link to={COMMUNITY_MANAGEMENT_CREATE_ROUTE}>
            <Button leftIcon="plus">{t('communities_add')}</Button>
          </Link>
        )}
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
