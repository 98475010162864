import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { useBlockSubscriberMutation } from 'services/communities/communitiesApiService';

import { Button, Flex, Form, ModalDefault, TextArea } from 'gazprom-ui-lib';

import s from './BlockModal.module.scss';
import { BLOCK_SUBSCRIBER_FORM_ENUM, BlockSubscriberFormResponse } from './blockModal.utils';

type BlockModalProps = {
  open: boolean;
  close: () => void;
  communityId: string;
  subscriberId: string;
  onSuccessBlockUnblockMutation: () => void;
};

const BlockModal = ({
  open,
  close,
  subscriberId,
  communityId,
  onSuccessBlockUnblockMutation,
}: BlockModalProps) => {
  const [form] = Form.useForm<BlockSubscriberFormResponse>();
  const disableSubmit = !Form.useWatch(BLOCK_SUBSCRIBER_FORM_ENUM.REASON_BLOCK, form)?.trim();

  const [blockSubscriber, { isLoading }] = useBlockSubscriberMutation();

  const handleClose = () => {
    close();
    form.resetFields();
  };

  const handleSubmit = async (values: BlockSubscriberFormResponse) => {
    try {
      await blockSubscriber({ communityId, subscriberId, payload: values }).unwrap();
      onSuccessBlockUnblockMutation();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ModalDefault open={open} onCancel={handleClose} closable>
      <ModalDefault.Icon name="passwordFilled" type="danger" />
      <ModalDefault.Title>{t('community_block_reason_modal_title')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('community_block_reason_modal_description')}
      </ModalDefault.Description>
      <Form className={s.fullWidth} form={form} onFinish={handleSubmit}>
        <Form.Item name={BLOCK_SUBSCRIBER_FORM_ENUM.REASON_BLOCK} rules={[formRules.required]}>
          <TextArea placeholder={t('common_enter_text')} />
        </Form.Item>
        <ModalDefault.Actions>
          <Flex gap="8">
            <Button
              loading={isLoading}
              disabled={disableSubmit}
              htmlType="submit"
              fullWidth
              leftIcon="ok">
              {t('common_accept')}
            </Button>
            <Button fullWidth ghost onClick={handleClose}>
              {t('common_close')}
            </Button>
          </Flex>
        </ModalDefault.Actions>
      </Form>
    </ModalDefault>
  );
};

export default BlockModal;
