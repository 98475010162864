import { CommunityInviteStatus } from 'types/communityInvite.types';
import { PaginationPropsTypePartial } from 'types/pagination.types';
import { SORT_ENUM } from 'types/sort.types';
import { SortType } from 'types/sort.types';

export type FormResponseType = {
  search?: string;
  page: number;
  size: number;
  order: SORT_ENUM;
  property: string;
  filters: FormFiltersType;
};

export type FormFiltersType = {
  employerId?: string;
  divisionId?: string;
  statuses?: CommunityInviteStatus[];
  isBlocked?: boolean;
  isModerator?: string;
};

export const DEFAULT_FORM_STATE: Omit<FormResponseType, 'order' | 'property'> = {
  search: '',
  page: 1,
  size: 10,
  filters: {},
};

export type SharedQueryParamsType = PaginationPropsTypePartial &
  SortType & {
    search?: string;
    filters?: FormFiltersType;
  };

export const RESEND_INVITE_COOLDOWN = 24;
