import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { getCommunityManagementNewsCreateRoute } from 'routes/communities/list';
import { t } from 'tools/i18n';

import { Button, Dropdown, DropdownProps, HandleItemClickType } from 'gazprom-ui-lib';

import DiscussionCreateEditModal from 'containers/discussion-create-edit-modal';

enum BUTTON_KEYS_ENUM {
  NEWS = 'NEWS',
  EVENT = 'EVENT',
  SURVEY = 'EVENTS',
  DISCUSSION = 'DISCUSSION',
}

const handlers = {
  [BUTTON_KEYS_ENUM.NEWS]: getCommunityManagementNewsCreateRoute,
};

const dropdownItems: DropdownProps['items'] = [
  { key: BUTTON_KEYS_ENUM.NEWS, label: t('news_news') },
  { key: BUTTON_KEYS_ENUM.EVENT, label: t('common_event_singular') },
  { key: BUTTON_KEYS_ENUM.SURVEY, label: t('common_survey_singular') },
  { key: BUTTON_KEYS_ENUM.DISCUSSION, label: t('common_discussion_singular') },
];

const AddButtonDropdown = () => {
  const [isDiscussionModalVisible, setIsDiscussionModalVisible] = useState(false);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const handleClick: HandleItemClickType = (value) => {
    if (id) {
      const navigationHandler = handlers[value.key as keyof typeof handlers];
      if (value.key === BUTTON_KEYS_ENUM.DISCUSSION) {
        setIsDiscussionModalVisible(true);
      } else {
        const route = navigationHandler(id);

        navigate(route);
      }
    }
  };

  return (
    // TODO.Ilya logic when implementing logic
    <>
      <DiscussionCreateEditModal
        communityId={id!}
        open={isDiscussionModalVisible}
        onClose={() => setIsDiscussionModalVisible(false)}
      />
      <Dropdown handleItemClick={handleClick} items={dropdownItems}>
        <Button leftIcon="plus">{t('community_add_publication')}</Button>
      </Dropdown>
    </>
  );
};

export default AddButtonDropdown;
