import { t } from 'tools/i18n';

import CommunityManagementFormMedia from 'containers/community-management-form/community-management-form-media';

import s from './Form.module.scss';
import Categories from './containers/categories';
import Publication from './containers/publication';
import Text from './containers/text';
import Title from './containers/title';
import { COMMUNITY_NEWS_FORM_ENUM } from './form.utils';

const NewsForm = () => {
  return (
    <div className={s.container}>
      <Title />
      <Text />
      <CommunityManagementFormMedia
        fieldName={COMMUNITY_NEWS_FORM_ENUM.COVER}
        label={t('common_cover')}
      />
      <Categories />
      <Publication />
    </div>
  );
};

export default NewsForm;
