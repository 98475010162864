import { Dayjs } from 'dayjs';

export enum COMMUNITY_NEWS_FORM_ENUM {
  TITLE = 'title',
  TEXT = 'text',
  COVER = 'cover',
  CATEGORIES = 'categories',
  PUBLISH_FROM_DATE = 'publishFrom',
  PUBLISH_FROM_TIME = 'time',
  PUBLISH = 'isPublish',
}

export type CommunityNewsFormResponse = {
  [COMMUNITY_NEWS_FORM_ENUM.TITLE]: string;
  [COMMUNITY_NEWS_FORM_ENUM.TEXT]: string;
  [COMMUNITY_NEWS_FORM_ENUM.COVER]: string;
  [COMMUNITY_NEWS_FORM_ENUM.CATEGORIES]: string[];
  [COMMUNITY_NEWS_FORM_ENUM.PUBLISH_FROM_DATE]: Dayjs;
  [COMMUNITY_NEWS_FORM_ENUM.PUBLISH_FROM_TIME]: Dayjs;
  [COMMUNITY_NEWS_FORM_ENUM.PUBLISH]: boolean;
};
