import { t } from 'tools/i18n';

import { Flex, Typography } from 'gazprom-ui-lib';

import MultiInput from 'components/form/multi-input';

import { COMMUNITY_MANAGEMENT_FORM_ENUM } from '../communityManagementForm.utils';

const CommunityManagementFormLinks = () => {
  return (
    <MultiInput
      label={
        <Flex gap="4" align="center">
          {t('communities_links')}
          <Typography.Text size="12" weight="400" type="secondary">
            {t('common_optional')}
          </Typography.Text>
        </Flex>
      }
      placeholder={t('common_enter_social_media_link')}
      buttonText={t('communities_add_link_field')}
      name={COMMUNITY_MANAGEMENT_FORM_ENUM.LINKS}
    />
  );
};

export default CommunityManagementFormLinks;
