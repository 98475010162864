import { FC, useState } from 'react';

import { t } from 'tools/i18n';

import { Button, DatePicker, Flex, Form, FormItemProps, Typography } from 'gazprom-ui-lib';

import { RangePickerProps as ARangePickerProps } from 'gazprom-ui-lib/dist/components/datePicker/rangePicker/rangePicker';

import s from './RangePicker.module.scss';
import { DEFAULT_FORMAT, DatePickerButtonType, datePickerButtons } from './rangePicker.utils';

interface RangePickerProps extends FormItemProps {
  formName?: string;
}

const RangePicker: FC<RangePickerProps> = (props) => {
  const { formName = 'range', normalize } = props;

  const form = Form.useFormInstance();

  const [pickerValue, setPickerValue] = useState<ARangePickerProps['value']>(null);

  const handleChange = (value: ARangePickerProps['value'] | null) => {
    if (value) {
      const transformedFrom = value?.[0]?.startOf('day')?.format(DEFAULT_FORMAT);
      const transformedTo = value?.[1]?.endOf('day')?.format(DEFAULT_FORMAT);

      form.setFieldValue('page', 1);
      setPickerValue(value);
      return form?.setFieldValue(formName, [transformedFrom, transformedTo]);
    }

    setPickerValue(null);
    form?.setFieldValue(formName, undefined);
  };

  const renderButtons = (buttonProps: DatePickerButtonType) => {
    const { id, text, onClickValue } = buttonProps;

    const handleButtonClick = () => handleChange(onClickValue as ARangePickerProps['value']);

    return (
      <Button size="small" type="link" key={id} onClick={handleButtonClick}>
        {text}
      </Button>
    );
  };

  return (
    <Flex gap="8" align="center">
      <Typography.Text size="14" type="secondary">
        {t('common_by_period')}
      </Typography.Text>
      <Form.Item name={formName} normalize={normalize} noStyle valuePropName="value" hidden />
      <DatePicker.RangePicker className={s.input} value={pickerValue} onChange={handleChange} />
      <Flex>{datePickerButtons.map(renderButtons)}</Flex>
    </Flex>
  );
};

export default RangePicker;
