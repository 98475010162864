import { FC, memo } from 'react';

import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import { useVerdictAssignmentMutation } from 'services/1c/cApiService';

import { Button, Form, Icon, Modal, TextArea, Typography, handleRequest } from 'gazprom-ui-lib';

import s from './CancelOrgRejectModal.module.scss';

interface Props {
  open: boolean;
  onCancel: () => void;
  taskId?: string;
  employeeId?: string;
}

const CancelOrgRejectModal: FC<Props> = (props) => {
  const { open, onCancel, taskId, employeeId } = props;

  const [verdict, { isLoading }] = useVerdictAssignmentMutation();
  const params = useParams<{ id: string }>();

  const [form] = Form.useForm();

  const onSuccess = () => {
    onCancel();
    form.resetFields();
  };

  const handleReject = useHandleRequestWithModal({
    onSuccessModalConfig: {
      title: {
        children: t('documents_refuse_cancel_success_title'),
      },
      description: { children: t('documents_refuse_cancel_success_description') },
    },
  });

  const comment = Form.useWatch('comment', form);

  const handleVerdict = async (status: string) => {
    if (params.id && employeeId) {
      verdict({
        id: taskId!,
        myEmployeeId: employeeId,
        comment,
        status,
      }).then(
        handleRequest({
          onSuccess: (r) => {
            onSuccess();
            if (status === 'REJECT') {
              handleReject(r);
            }
          },
        }),
      );
    }
  };

  return (
    <Modal open={open} onCancel={onCancel} closable>
      <Form form={form} className={s.modalWrapper} onReset={onCancel}>
        <div className={cn(s.iconWrapper, s.danger)}>
          <Icon name="delete" />
        </div>
        <Typography.Title level={3}>{t('documents_refuse_cancel_title')}</Typography.Title>
        <Typography.Title level={5} className={s.description} strong={false} type="secondary">
          {t('documents_refuse_cancel_description')}
        </Typography.Title>
        <Form.Item
          name="comment"
          rules={[formRules.required]}
          normalize={(e: string) => e.trimStart()}>
          <TextArea placeholder={t('common_enter_text')} />
        </Form.Item>
        <div className={s.actions}>
          <Button
            leftIcon="return"
            fullWidth
            loading={isLoading}
            onClick={() => handleVerdict('REJECTED')}
            disabled={!comment}>
            {t('common_confirm')}
          </Button>

          <Button loading={isLoading} type="secondary" fullWidth onClick={onCancel}>
            {t('common_close')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default memo(CancelOrgRejectModal);
