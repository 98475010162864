import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Flex, Form, TextArea, Typography } from 'gazprom-ui-lib';

import { COMMUNITY_MANAGEMENT_FORM_ENUM } from '../communityManagementForm.utils';

const CommunityManagementFormDescription = () => {
  const form = Form.useFormInstance();
  const description = Form.useWatch(COMMUNITY_MANAGEMENT_FORM_ENUM.DESCRIPTION, form);

  return (
    <Flex vertical gap="8">
      <Flex gap="8" align="center" justify="space-between">
        <Typography.Text weight="500" size="16">
          {t('common_description')}
        </Typography.Text>
        <Typography.Text size="12" weight="400" type="secondary">
          {t('common_symbol_count', {
            current: description?.length ?? 0,
            total: 380,
          })}
        </Typography.Text>
      </Flex>
      <Form.Item
        normalize={normalizeTextField}
        name={COMMUNITY_MANAGEMENT_FORM_ENUM.DESCRIPTION}
        rules={[formRules.required, { max: 380 }]}>
        <TextArea placeholder={t('common_enter_text')} maxLength={380} />
      </Form.Item>
    </Flex>
  );
};

export default CommunityManagementFormDescription;
