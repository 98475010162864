import { SORT_ENUM } from 'types/sort.types';

export type FormResponseType = {
  page: number;
  size: number;
  property?: string;
  order: SORT_ENUM;
  search?: string;
  filters?: {
    status: string[];
  };
  range?: Date[];
};

export const DEFAULT_SORT = {
  property: 'createdAt',
  order: SORT_ENUM.DESC,
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  ...DEFAULT_SORT,
};
