import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ExpiredModal = (props: Props) => {
  const { isOpen, onClose } = props;

  return (
    <ModalDefault open={isOpen} onCancel={onClose} width={320} closable>
      <ModalDefault.Icon type="warning" name="error" />
      <ModalDefault.Title>{t('documents_sign_sign_failed_title')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('documents_sign_sign_failed_description')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button fullWidth type="primary" onClick={onClose}>
          {t('common_ok')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default ExpiredModal;
