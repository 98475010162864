import { useEffect } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import {
  useCreateCommunityDiscussionMutation,
  useUpdateCommunityDiscussionMutation,
} from 'services/communities/communitiesApiService';

import { Button, Checkbox, Flex, Form, Modal, TextArea, Typography } from 'gazprom-ui-lib';

import { CommunityDiscussionType } from 'types/community.types';

type DiscussionCreateEditModalProps = {
  open: boolean;
  onClose: () => void;
  communityId: string;
  edit?: boolean;
  discussion?: CommunityDiscussionType;
};

enum DISCUSSION_MODAL_ENUM {
  TITLE = 'title',
  IS_PUBLISH = 'isPublish',
}

const DiscussionCreateEditModal = ({
  open,
  onClose,
  communityId,
  edit,
  discussion,
}: DiscussionCreateEditModalProps) => {
  const [form] = Form.useForm();

  const [createDiscussion, { isLoading: isCreating }] = useCreateCommunityDiscussionMutation();
  const [updateDiscussion, { isLoading: isUpdating }] = useUpdateCommunityDiscussionMutation();

  const handleSubmit = async (values: {
    [DISCUSSION_MODAL_ENUM.TITLE]: string;
    [DISCUSSION_MODAL_ENUM.IS_PUBLISH]: boolean;
  }) => {
    const data = {
      title: values[DISCUSSION_MODAL_ENUM.TITLE],
      isPublish: values[DISCUSSION_MODAL_ENUM.IS_PUBLISH] ?? false,
      communityId,
    };
    if (edit && discussion) {
      updateDiscussion({
        ...data,
        canComment: discussion.canComment,
        discussionId: discussion.id,
      }).then(handleCloseModal);
    } else {
      createDiscussion({
        ...data,
        canComment: true,
      }).then(handleCloseModal);
    }
  };

  const handleCloseModal = () => {
    onClose();
    form.resetFields();
  };

  useEffect(() => {
    if (discussion && open) {
      form.setFieldValue(DISCUSSION_MODAL_ENUM.TITLE, discussion.title);
      form.setFieldValue(DISCUSSION_MODAL_ENUM.IS_PUBLISH, discussion.isPublish);
    }
  }, [open, discussion, form]);

  const disableSubmit = isCreating || isUpdating;

  return (
    <Modal open={open} onCancel={handleCloseModal} closable>
      <Flex vertical gap="16">
        <Typography.Title level={4}>
          {edit ? t('community_discussion_edit') : t('community_discussion_add')}
        </Typography.Title>

        <Flex vertical gap="8">
          <Typography.Text size="16" weight="500">
            {t('community_discussion_theme')}
          </Typography.Text>

          <Form form={form} onFinish={handleSubmit}>
            <Form.Item name={DISCUSSION_MODAL_ENUM.TITLE} rules={[formRules.required]}>
              <TextArea placeholder={t('common_enter_text')} />
            </Form.Item>

            <Form.Item
              name={DISCUSSION_MODAL_ENUM.IS_PUBLISH}
              hidden={!edit}
              valuePropName="checked">
              <Checkbox>{t('common_publish')}</Checkbox>
            </Form.Item>

            <Flex gap="8" align="center">
              <Button htmlType="button" type="secondary" onClick={handleCloseModal} fullWidth>
                {t('common_close')}
              </Button>
              <Button
                disabled={disableSubmit}
                htmlType="submit"
                loading={disableSubmit}
                leftIcon="ok"
                fullWidth>
                {t('common_save')}
              </Button>
            </Flex>
          </Form>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default DiscussionCreateEditModal;
