import { useAuth } from 'react-oidc-context';

import {
  useGetCommunitiesModeratorQuery,
  useGetCommunitiesQuery,
} from 'services/communities/communitiesApiService';

import { BooleanFilter } from 'types/boolean.types';
import { GLOBAL_ROLES } from 'types/roles.types';
import { SORT_ENUM } from 'types/sort.types';

export type FormResponseType = {
  search?: string;
  page: number;
  size: number;
  order: SORT_ENUM;
  property: string;
  filters?: FormFiltersType;
};

export type FormFiltersType = {
  moderatorIds?: string;
  categoryIds?: string;
  isPublish?: BooleanFilter;
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  search: '',
  page: 1,
  size: 10,
  order: SORT_ENUM.DESC,
  property: 'updatedAt',
  filters: {},
};

export const useGetCommunitiesQueryHook = () => {
  const { user } = useAuth();
  const userRoles = (user?.profile?.roles as string[]) ?? [];
  const isCommunityAdmin = userRoles.includes(GLOBAL_ROLES.COMMUNITY_ADMIN);

  if (isCommunityAdmin) {
    return useGetCommunitiesQuery;
  } else {
    return useGetCommunitiesModeratorQuery;
  }
};

export const useGetIsCommunityModerator = () => {
  const { user } = useAuth();
  const userRoles = (user?.profile?.roles as string[]) ?? [];
  const isCommunityAdmin = userRoles.includes(GLOBAL_ROLES.COMMUNITY_ADMIN);

  return isCommunityAdmin;
};
