import Presents from 'pages/presents';
import PresentDetails from 'pages/presents/presents-details';

import { GLOBAL_ROLES } from 'types/roles.types';

import { RouteConfig } from '../routes';
import { PRESENTS_DETAILS_ROUTE, PRESENTS_ROUTE } from './list';

export const presentsRoutes: RouteConfig[] = [
  {
    path: PRESENTS_ROUTE,
    element: <Presents />,
    roles: [GLOBAL_ROLES.MODERATOR, GLOBAL_ROLES.ADMIN_MOBILE, GLOBAL_ROLES.ADMIN],
  },
  {
    path: PRESENTS_DETAILS_ROUTE,
    element: <PresentDetails />,
    roles: [GLOBAL_ROLES.MODERATOR, GLOBAL_ROLES.ADMIN_MOBILE, GLOBAL_ROLES.ADMIN],
  },
];
