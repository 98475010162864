import { t } from 'tools/i18n';

import { TableProps, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TablePerson from 'components/table/table-person';

import { EmployeeType } from 'types/employee.types';
import { GiftOrderType } from 'types/gift.types';

const useColumns = (): TableProps<GiftOrderType>['columns'] => {
  return [
    {
      title: t('present_order_number'),
      dataIndex: 'externalId',
      sorter: true,
      render: (id: string) => (
        <Typography.Text size="14" weight="500">
          {id}
        </Typography.Text>
      ),
    },
    {
      title: t('presents_receiver'),
      dataIndex: 'employee',
      sorter: true,
      render: (employee: EmployeeType) => {
        return <TablePerson {...employee.person} department={employee.employer.name} position="" />;
      },
    },
    {
      title: t('presents_delivery_type'),
      dataIndex: 'deliveryId',
      sorter: true,
      render: (deliveryId: number) => (
        <Typography.Text size="14" weight="500">
          {t(deliveryId === 16 ? 'presents_by_pickup' : 'presents_by_post')}
        </Typography.Text>
      ),
    },
    {
      title: t('present_order_status'),
      dataIndex: 'status',
      sorter: true,
      render: (status: string) => (
        <Typography.Text size="14" weight="500">
          {status}
        </Typography.Text>
      ),
    },
    {
      title: t('common_created'),
      dataIndex: 'createdAt',
      align: 'center',
      sorter: true,
      render: (date: string) => <TableDate date={date} />,
      width: 150,
    },
    {
      title: t('common_updated'),
      dataIndex: 'updatedAt',
      align: 'center',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (date: string) => <TableDate date={date} />,
      width: 150,
    },
  ];
};

export default useColumns;
