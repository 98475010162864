import { useDebounce } from 'utils/useDebounce.hook';

import { useGetCommunityCategoriesQuery } from 'services/communities/communitiesApiService';
import { GetCommunityCategoriesPropsType } from 'services/communities/communityApiService.types';

import { FormResponseType } from './communityCategories.utils';

type UseRequestProps = {
  requestBody: FormResponseType;
};

export const useRequest = ({ requestBody }: UseRequestProps) => {
  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  const { search, property, order, ...clearedRequestBody } = debouncedRequestBody ?? {};

  const actualRequestBody: GetCommunityCategoriesPropsType = {
    ...clearedRequestBody,
    ...(search && { name: search }),
    ...(property && order && { sort: [{ order, property }] }),
  };

  const {
    data: { items: dataSource, totalCount = 0 } = {},
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetCommunityCategoriesQuery(actualRequestBody, { skip: !actualRequestBody?.page });

  const isInitialLoading = isLoading || isUninitialized;
  const loading = isFetching || isDebouncing;
  const hasFiltersApplied = !!actualRequestBody?.name?.trim();

  return { isInitialLoading, loading, dataSource, totalCount, hasFiltersApplied };
};
