import { t } from 'tools/i18n';

import { ColumnsType, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TablePerson from 'components/table/table-person';

import { CommunityNewsComment } from 'types/community.types';

const useColumns = (): ColumnsType<CommunityNewsComment> => {
  const renderEmployee = ({ person, employer }: CommunityNewsComment['creator']) => {
    const { firstName, lastName } = person;
    const { name } = employer;

    return <TablePerson firstName={firstName} lastName={lastName} department={name} />;
  };

  const renderPosition = ({ position }: CommunityNewsComment['creator']) => (
    <Typography.Text size="12" weight="500" type="secondary">
      {position.name}
    </Typography.Text>
  );

  const renderComment = (comment: CommunityNewsComment['comment']) => (
    <Typography.Text size="12" weight="400">
      {comment}
    </Typography.Text>
  );

  const renderDate = (date: CommunityNewsComment['createdAt']) => <TableDate date={date} />;

  return [
    {
      title: t('common_employee'),
      dataIndex: 'creator',
      render: renderEmployee,
    },
    {
      title: t('common_staffName'),
      dataIndex: 'creator',
      render: renderPosition,
    },
    {
      title: t('common_comment'),
      dataIndex: 'comment',
      render: renderComment,
    },
    {
      title: t('thank_you_data'),
      dataIndex: 'createdAt',
      render: renderDate,
      align: 'center',
    },
  ];
};

export default useColumns;
