import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Flex, Form, Input, Typography } from 'gazprom-ui-lib';

import { COMMUNITY_MANAGEMENT_FORM_ENUM } from '../communityManagementForm.utils';

const CommunityManagementFormName = () => {
  const form = Form.useFormInstance();
  const name = Form.useWatch(COMMUNITY_MANAGEMENT_FORM_ENUM.NAME, form);

  return (
    <Flex vertical gap="8">
      <Flex gap="8" align="center" justify="space-between">
        <Typography.Text weight="500" size="16">
          {t('community_name')}
        </Typography.Text>
        <Typography.Text size="12" weight="400" type="secondary">
          {t('common_symbol_count', {
            current: name?.length ?? 0,
            total: 80,
          })}
        </Typography.Text>
      </Flex>
      <Form.Item
        normalize={normalizeTextField}
        name={COMMUNITY_MANAGEMENT_FORM_ENUM.NAME}
        rules={[formRules.required, { max: 80 }]}>
        <Input placeholder={t('common_enter_text')} maxLength={80} />
      </Form.Item>
    </Flex>
  );
};

export default CommunityManagementFormName;
