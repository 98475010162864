import { useRef, useState } from 'react';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

import { CommunityDiscussionComment } from 'types/community.types';

import { DEFAULT_FORM_STATE, FormResponseType } from './comments.utils';
import CreateCommentModal from './containers/create-comment-modal';
import DetailsModal from './containers/details-modal';
import Filters from './containers/filters';
import useColumns from './useColumns';
import useRequest from './useRequest.hook';

interface Props {
  isRemoved?: boolean;
}

const Comments = (props: Props) => {
  const parentId = useRef('');
  const [isCreateCommentModalVisible, setIsCreateCommentModalVisible] = useState(false);
  const { isRemoved = false } = props;
  const [form] = Form.useForm();

  const { isLoading, isFetching, data } = useRequest({ form, isRemoved });

  const [currentComment, setCurrentComment] = useState<CommunityDiscussionComment | null>(null);

  const columns = useColumns({
    isRemoved,
    setCurrentComment,
  });
  const handleOnRow = (record: CommunityDiscussionComment) => ({
    onClick: () => !isRemoved && setCurrentComment(record),
  });

  const handleCloseDetails = () => setCurrentComment(null);

  return (
    <>
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />

        <FiltersV2.Table<CommunityDiscussionComment, FormResponseType>
          isInitialLoading={isLoading}
          rowKey="id"
          dataSource={data?.items}
          total={data?.totalCount}
          loading={isFetching}
          onRow={handleOnRow}
          columns={columns}
        />
      </FiltersV2>
      <DetailsModal
        detailsComment={currentComment}
        closeDetails={handleCloseDetails}
        handleOpenCreateModal={(pId) => {
          if (pId) {
            parentId.current = pId;
          } else {
            parentId.current = '';
          }
          setIsCreateCommentModalVisible(true);
        }}
      />
      <CreateCommentModal
        parentId={parentId.current}
        open={isCreateCommentModalVisible}
        handleClose={() => setIsCreateCommentModalVisible(false)}
      />
    </>
  );
};

export default Comments;
