import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCollapse from 'containers/filters/filter-collapse';

import useFilterItems from './useFilterItems.hook';

const Filters = () => {
  const filterItems = useFilterItems();

  return (
    <FiltersV2.TableFilters>
      <FiltersV2.Search />
      <FiltersContainer showClearAll overlay={<FilterCollapse items={filterItems} />} />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
