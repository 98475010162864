import { ReactNode } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { Button, Flex, Form, Icon, Input } from 'gazprom-ui-lib';

import s from './MultiInput.module.scss';

type MultiInputProps = {
  name: string;
  classNameItem?: string;
  classNameWrapper?: string;
  buttonText?: string;
  label: ReactNode;
  placeholder?: string;
};

const MultiInput = ({
  name,
  buttonText = t('common_add'),
  classNameItem,
  classNameWrapper,
  placeholder = t('common_enter_text'),
  label,
}: MultiInputProps) => {
  return (
    <Form.List name={name} initialValue={['']}>
      {(fields, { add, remove }) => (
        <Flex className={cn(s.wrapper, classNameWrapper)} vertical gap="8">
          {fields.map((field, index) => (
            <Form.Item
              className={cn(s.item, classNameItem)}
              label={index === 0 && label}
              required={false}
              key={field.key}>
              <Form.Item {...field}>
                <Input
                  style={{ width: '100%' }}
                  placeholder={placeholder}
                  addonAfter={
                    fields.length > 1 ? (
                      <Button
                        className={s.deleteBtn}
                        type="link"
                        onClick={() => remove(field.name)}>
                        <Icon name="bin" />
                      </Button>
                    ) : null
                  }
                />
              </Form.Item>
            </Form.Item>
          ))}

          <Button type="link" leftIcon="plus" className={s.buttonCustom} onClick={() => add()}>
            {buttonText}
          </Button>
        </Flex>
      )}
    </Form.List>
  );
};

export default MultiInput;
