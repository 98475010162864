import { useState } from 'react';

import { t } from 'tools/i18n';
import { getFio } from 'utils/getFio.utils';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetEmployees1cQuery } from 'services/1c/cApiService';

import MultiTagsSelect from 'components/form/multi-tags-select';

import { useGetIsCommunityModerator } from 'pages/communities/community-management/communityManagement.utils';

import { COMMUNITY_MANAGEMENT_FORM_ENUM } from '../communityManagementForm.utils';

const DEFAULT_PAGINATION = { page: 1, size: 100 };

const CommunityManagementFormModerators = () => {
  const isCommunityModerator = useGetIsCommunityModerator();
  const [search, setSearch] = useState('');
  const { debouncedValue: debouncedSearch } = useDebounce(search);

  const { data: { employees = [] } = {} } = useGetEmployees1cQuery({
    ...DEFAULT_PAGINATION,
    fio: debouncedSearch,
  });

  const preparedEmployees: { value: string; label: string }[] = employees.map(
    ({ id: employeeId, person: { firstName, lastName, patronymic, id: personId }, position }) => {
      const label = `${getFio({ firstName, lastName, patronymic, type: 'full', safe: false })} / ${
        position.name
      }`;
      const stringifiedValue = JSON.stringify({ employeeId, personId });
      return { value: stringifiedValue, label };
    },
  );

  return (
    <MultiTagsSelect
      disabled={!isCommunityModerator}
      onSearch={setSearch}
      searchValue={search}
      name={COMMUNITY_MANAGEMENT_FORM_ENUM.MODERATORS}
      title={t('community_moderators')}
      placeholder={t('suggestions_commission_head_choose_placeholder')}
      options={preparedEmployees}
      isRequired={true}
    />
  );
};

export default CommunityManagementFormModerators;
