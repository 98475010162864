import { t } from 'tools/i18n';

import { CommunityInviteStatus } from 'types/communityInvite.types';

type CommunityInviteFiltersOptionsType = {
  value: CommunityInviteStatus;
  label: string;
}[];

export const COMMUNITY_INVITES_FILTERS_OPTIONS: CommunityInviteFiltersOptionsType = [
  { value: 'ACCEPTED', label: t('community_invite_accepted') },
  { value: 'SEND', label: t('community_invite_sent') },
  { value: 'REJECTED', label: t('community_invite_rejected') },
];
