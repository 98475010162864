import { Table as AntTable } from 'gazprom-ui-lib';

import { EmployeeType } from 'types/employee.types';

import useColumns from './useColumns.hook';

interface Props {
  dataSource?: EmployeeType[];
}

const Table = (props: Props) => {
  const { dataSource } = props;

  const columns = useColumns();

  return <AntTable dataSource={dataSource} columns={columns} rowKey="id" pagination={false} />;
};

export default Table;
