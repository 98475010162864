import { t } from 'tools/i18n';

import { useGetCommunityCategoriesQuery } from 'services/communities/communitiesApiService';

import MultiTagsSelect from 'components/form/multi-tags-select';
import CommunityCategoryModal from 'containers/community-category-modal';

import { COMMUNITY_MANAGEMENT_FORM_ENUM } from '../communityManagementForm.utils';

const DEFAULT_PAGINATION = { page: 1, size: 500 };

const CommunityManagementFormCategories = () => {
  const { data: { items: communityCategories = [] } = {} } = useGetCommunityCategoriesQuery({
    ...DEFAULT_PAGINATION,
  });

  const preparedCategories: { value: string; label: string }[] = communityCategories.map(
    ({ id, name }) => ({ value: id, label: name }),
  );

  return (
    <MultiTagsSelect
      buttonText={t('common_add_new_category')}
      ModalComponent={CommunityCategoryModal}
      name={COMMUNITY_MANAGEMENT_FORM_ENUM.CATEGORIES}
      title={t('communities_category')}
      placeholder={t('common_choose_category_from_list_or_add')}
      options={preparedCategories}
      isRequired={true}
    />
  );
};

export default CommunityManagementFormCategories;
