import { EmployeeType, PersonType } from './employee.types';

// Community Category
export type CommunityCategoryType = {
  id: string; // UUID of the category
  name: string; // Name of the category
  countCommunity: number; // Number of communities in the category
  visible: boolean; // Visibility of the category
  creator: string; // UUID of the creator
  editor: string; // UUID of the editor
  createdAt: string; // Date and time of creation in ISO 8601 format
  updatedAt: string; // Date and time of update in ISO 8601 format
};

export type CommunityCategoryCreateUpdateDto = {
  name: string;
  visible?: boolean;
};

// Community
export type CommunityTypeWithModerators = {
  id: string;
  name: string;
  description: string;
  avatar: CommunityMediaType | null;
  cover: CommunityMediaType | null;
  groupId: string;
  moderators: EmployeeSubscriberResType[];
  type: COMMUNITY_ACCESS_TYPE;
  links: string[];
  countMembers: number;
  isPublish: boolean;
  creator: string;
  editor: string;
  createdAt: string;
  updatedAt: string;
  status: CommunityUserStatusType;
  reasonBlock: string;
  categories: CategoryCommunityNameType[];
  articleModerationCount: number;
};

export type CommunityTypeWithoutModerators = Omit<CommunityTypeWithModerators, 'moderators'>;

export enum COMMUNITY_ACCESS_TYPE {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}

export type CommunityAccessType = keyof typeof COMMUNITY_ACCESS_TYPE;

enum COMMUNITY_USER_STATUS_TYPE {
  BLOCKED = 'BLOCKED',
  SUBSCRIBER = 'SUBSCRIBER',
  UNDEFINED = 'UNDEFINED',
  MODERATOR = 'MODERATOR',
}
type CommunityUserStatusType = keyof typeof COMMUNITY_USER_STATUS_TYPE;

// TODO.Ilya update media type
type CommunityMediaType = {
  num?: number;
  link: string;
  name?: string;
  type?: 'image' | 'video' | 'youtube';
  size?: number;
  version?: number;
  signKeys?: string[];
};

type CommunityPersonFioType = {
  id: string;
  lastName: string;
  firstName: string;
  patronymic: string;
};

type SubscriberEmployerNameResType = {
  id: string;
  name: string;
};

type SubscriberDivisionNameResType = {
  id: string;
  name: string;
};

type SubscriberPositionNameResType = {
  id: string;
  name: string;
};

type EmployeeSubscriberResType = {
  description: string;
  id: string;
  person: CommunityPersonFioType;
  employer: SubscriberEmployerNameResType;
  division: SubscriberDivisionNameResType;
  position: SubscriberPositionNameResType;
};

type CategoryCommunityNameType = {
  id: string;
  name: string;
};

type CommunityModeratorType = {
  employeeId: string;
  personId: string;
};

export type CommunityCreateOrUpdateAdminReqDto = {
  name: string;
  description: string;
  avatar: CommunityMediaType | null;
  cover: CommunityMediaType | null;
  groupId?: string;
  moderators: CommunityModeratorType[];
  type: CommunityAccessType;
  links: string[];
  isPublish: boolean;
  categories: string[];
};

// TODO.Ilya change when BE ready
export enum COMMUNITY_TARGET_AUDIENCE {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  BOTH = 'BOTH',
}

export type CommunityNewsType = {
  id: string;
  communityId: string;
  code: string;
  title: string;
  text: string;
  cover: {
    link: string;
    name: string;
    type: string;
    size: number;
    signKeys: string[];
  };
  media: [];
  type: string;
  sourceType: string;
  status: string;
  publishFrom: string;
  publishTill: string;
  isPublish: boolean;
  commentCount: number;
  viewCount: number;
  likeCount: number;
  creator: string;
  editor: string;
  createdAt: string;
  updatedAt: string;
  categories: {
    id: string;
    name: string;
  }[];
};

export type CommunityNewsComment = {
  id: string;
  comment: string;
  parentId: string;
  visible: boolean;
  createdAt: string;
  updatedAt: string;
  creator: EmployeeType;
  editor: PersonType;
};

export type CommunityDiscussionType = {
  canComment: boolean;
  code: string;
  commentCount: number;
  communityId: string;
  createdAt: string;
  creator: string;
  editor: string;
  id: string;
  isPublish: boolean;
  title: string;
  updatedAt: string;
};

export type CommunityDiscussionComment = CommunityNewsComment;
