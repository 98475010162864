export const WORDS_WITH_EV_ENDING = [''];

export const pluralEnding = (amount: number, initialWord: string): string => {
  if (amount % 10 === 1 && amount % 100 !== 11) {
    return '';
  }

  if ([2, 3, 4].includes(amount % 10) && ![12, 13, 14].includes(amount % 100)) {
    return 'а';
  }

  if (WORDS_WITH_EV_ENDING.includes(initialWord)) {
    return 'ев';
  }

  return 'ов';
};

type GetPluralEndingProps = {
  wordBase?: string;
  count: number;
};

export const getPluralEnding = ({ wordBase, count }: GetPluralEndingProps): string => {
  const lastDigit = count % 10;
  const lastTwoDigits = count % 100;
  let ending = '';

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    ending = 'ов'; // Special case for 11-14
  } else if (lastDigit === 1) {
    ending = ''; // Singular (no ending for singular)
  } else if (lastDigit >= 2 && lastDigit <= 4) {
    ending = 'а'; // Genitive singular (for numbers ending in 2-4 except 12-14)
  } else {
    ending = 'ов'; // Genitive plural (for 0, 5-9, and all other cases)
  }

  if (wordBase) {
    return `${wordBase}${ending}`;
  }

  return ending;
};
