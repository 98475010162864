import { FC } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { Flex, Typography } from 'gazprom-ui-lib';

import { EMPLOYMENT_TYPE_ENUM, EmployeeType } from 'types/employee.types';

import EmploymentTag from '../employment-tag';
import {
  getEmploymentRateTitle,
  getEmploymentTypeTitle,
} from '../employment-tag/employmentTag.utils';
import s from './Employee.module.scss';

interface EmployeeSelectProps {
  employee?: EmployeeType;
  showDivision?: boolean;
  showEmploymentInfo?: boolean;
  isSelected?: boolean;
  showStaffName?: boolean;
  handleSelect: () => void;
}

const Employee: FC<EmployeeSelectProps> = (props) => {
  const {
    employee,
    showDivision = true,
    showStaffName = true,
    showEmploymentInfo = true,
    isSelected = false,
    handleSelect,
  } = props;

  return (
    <button onClick={handleSelect} className={s.button}>
      <Flex gap="12" align="center" className={s.wrapper}>
        <Flex vertical justify="center" gap="4">
          {showDivision && <Typography.Text size="12">{employee?.division?.name}</Typography.Text>}
          {showEmploymentInfo && (
            <Flex gap="4" wrap="wrap">
              <EmploymentTag type={isSelected ? 'outline' : 'neutral'}>
                {getEmploymentTypeTitle(employee?.employmentType as EMPLOYMENT_TYPE_ENUM)}
              </EmploymentTag>
              {!!employee?.partTime && (
                <EmploymentTag type={isSelected ? 'primary' : 'neutral'} icon="work">
                  {getEmploymentRateTitle(employee?.partTime)}
                </EmploymentTag>
              )}
            </Flex>
          )}
          <Typography.Title level={5} type="primary" className={cn({ [s.name]: isSelected })}>
            {employee?.employer?.name}
          </Typography.Title>
          <Typography.Text size="12" type="secondary">
            {showStaffName ? employee?.staff?.name : t('common_organization')}
          </Typography.Text>
        </Flex>
      </Flex>
    </button>
  );
};

export default Employee;
