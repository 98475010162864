import { ChangeEvent, useEffect, useState } from 'react';

import { formRules } from 'utils/formRules';

import { useLazyGetEmployeeGroupTreeQuery } from 'services/employee-groups-new/employeeGroupsNewApiService';
import { GetEmployerRootResponse } from 'services/employee-groups-new/employeeGroupsNewApiService.types';

import { DataNode, Form, Tree } from 'gazprom-ui-lib';

import { OnLoadArguments } from 'types/employeeGroups.types';

import { MASS_SUBSCRIBE_INVITE_FORM_ENUM } from '../massSubscribeInviteForm.utils';
import s from './Tree.module.scss';
import Filter from './containers/filter';
import { filterTree } from './massSubscribeIntiveFormTree.utils';

type UpdateNodeProps = {
  nodes: DataNode[];
  key: string;
  children: DataNode[];
};

type MassSubscribeInviteFormTreeProps = {
  rootEmployers: GetEmployerRootResponse;
  isFetchingRootEmployers: boolean;
};

const MassSubscribeInviteFormTree = ({
  rootEmployers,
  isFetchingRootEmployers,
}: MassSubscribeInviteFormTreeProps) => {
  const [getTree] = useLazyGetEmployeeGroupTreeQuery();
  const [treeData, setTreeData] = useState<DataNode[]>(rootEmployers);
  const [treeSearch, setTreeSearch] = useState('');
  const form = Form.useFormInstance();

  useEffect(() => {
    setTreeData(rootEmployers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingRootEmployers]);

  const updateNode = ({ nodes, key, children }: UpdateNodeProps): DataNode[] =>
    nodes.map((node) => {
      if (node.key === key) {
        return { ...node, children };
      }
      if (node.children) {
        return { ...node, children: updateNode({ nodes: node.children, key, children }) };
      }
      return node;
    });

  const handleLoadData = async (treeNode: DataNode) => {
    if (treeNode.children) return;

    const { id, key, type } = treeNode as OnLoadArguments & {
      type: string;
      id: string;
      key: string;
    };

    const childNodes = await getTree({
      parentId: id,
      type,
    }).unwrap();

    setTreeData((prevData) => updateNode({ nodes: prevData, key, children: childNodes }));
  };

  const handleCheck = (checked: string[]) => {
    form.setFieldValue(MASS_SUBSCRIBE_INVITE_FORM_ENUM.TREE_IDS, checked);
  };

  const handleTreeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setTreeSearch(e.target.value);
  };

  const filteredTreeData = treeSearch ? filterTree(treeData, treeSearch) : treeData;

  return (
    <>
      <Filter treeSearch={treeSearch} handleTreeSearch={handleTreeSearch} />
      <Form.Item rules={[formRules.required]} name={MASS_SUBSCRIBE_INVITE_FORM_ENUM.TREE_IDS}>
        <Tree
          className={s.tree}
          checkable
          treeData={filteredTreeData}
          onCheck={(checked) => {
            handleCheck(checked as string[]);
          }}
          loadData={handleLoadData}
          key="treeStructure"
          selectable={false}
        />
      </Form.Item>
    </>
  );
};

export default MassSubscribeInviteFormTree;
