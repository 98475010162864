import { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetCommunityNewsListQuery } from 'services/communities/communitiesApiService';
import { GetCommunityNewsListPropsType } from 'services/communities/communityApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from 'pages/events/events.utils';

interface Props {
  form: FormInstance<FormResponseType>;
}

const useRequest = (props: Props) => {
  const { form } = props;

  const { id } = useParams<{ id: string }>();

  const requestBody: FormResponseType | undefined = Form.useWatch([], form);

  const [getNews, { isFetching, data, isLoading }] = useLazyGetCommunityNewsListQuery();

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    if (debouncedRequestBody && debouncedRequestBody.size) {
      const preparedBody: GetCommunityNewsListPropsType = {
        communityId: id!,
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
      };

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        preparedBody.sort = [
          {
            property: debouncedRequestBody.property,
            order: debouncedRequestBody.order,
          },
        ];
      }

      getNews(preparedBody);
    }
  }, [debouncedRequestBody, getNews, id]);

  return {
    isLoading: isLoading,
    isFetching: isFetching || isDebouncing,
    data,
  };
};

export default useRequest;
