import { t } from 'tools/i18n';

import { EMPLOYMENT_TYPE_ENUM } from 'types/employee.types';

export const getEmploymentRateTitle = (workRate?: number) => {
  if (workRate === 1) {
    return `${workRate} ${t('common_work_rate')}`;
  }

  return `${workRate} ${t('common_work_part_rate')}`;
};

export const getEmploymentTypeTitle = (employmentType?: EMPLOYMENT_TYPE_ENUM) => {
  if (employmentType === EMPLOYMENT_TYPE_ENUM.MAIN_WORK) {
    return t('common_work_main');
  }

  return t('common_work_extra');
};
