import { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetCommunityCommentsByDiscussionIdQuery } from 'services/communities/communitiesApiService';
import { GetCommunityCommentsByDiscussionIdPropsType } from 'services/communities/communityApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './comments.utils';

interface Props {
  isRemoved?: boolean;
  form: FormInstance<FormResponseType>;
}

const useRequest = (props: Props) => {
  const { form, isRemoved } = props;

  const { id, discussionId } = useParams<{ id: string; discussionId: string }>();

  const requestBody: FormResponseType | undefined = Form.useWatch([], form);

  const [getComments, { isFetching, data, isLoading }] =
    useLazyGetCommunityCommentsByDiscussionIdQuery();

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    if (debouncedRequestBody && debouncedRequestBody.size) {
      const preparedBody: GetCommunityCommentsByDiscussionIdPropsType = {
        communityId: id!,
        discussionId: discussionId!,
        visible: !isRemoved,
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
      };

      if (debouncedRequestBody.search) {
        preparedBody.queryString = debouncedRequestBody.search;
      }

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        preparedBody.sort = [
          {
            property: debouncedRequestBody.property,
            order: debouncedRequestBody.order,
          },
        ];
      }

      getComments(preparedBody);
    }
  }, [debouncedRequestBody, getComments, id, discussionId, isRemoved]);

  return {
    isLoading: isLoading,
    isFetching: isFetching || isDebouncing,
    data,
  };
};

export default useRequest;
