import { useState } from 'react';

import { useParams } from 'react-router-dom';

import { useCreateCommunityDiscussionCommentMutation } from 'services/communities/communitiesApiService';

interface Props {
  handleClose: () => void;
  parentId?: string;
}

const useRequest = (props: Props) => {
  const { handleClose, parentId } = props;
  const { id, discussionId } = useParams<{ id: string; discussionId: string }>();

  const [newComment, setNewComment] = useState<string>('');

  const [sendResponse, { isLoading: isSendResponseLoading }] =
    useCreateCommunityDiscussionCommentMutation();

  const isParamsExist = id && discussionId;

  const handleSendResponse = () => {
    if (isParamsExist) {
      sendResponse({
        communityId: id,
        discussionId: discussionId,
        comment: newComment,
        parentId,
        visible: true,
      }).then(() => {
        setNewComment('');
        handleClose();
      });
    }
  };

  return {
    newComment,
    isSendResponseLoading,
    setNewComment,
    handleSendResponse,
  };
};

export default useRequest;
