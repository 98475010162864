import { Empty } from 'components/empty';
import FiltersV2 from 'containers/filters-v2';

import { CommunityCategoryType } from 'types/community.types';

import { FormResponseType } from '../../communityCategories.utils';
import s from './Table.module.scss';
import useColumns from './useColumns.hook';

type TableProps = {
  dataSource?: CommunityCategoryType[];
  loading?: boolean;
  totalCount?: number;
  isInitialLoading: boolean;
  hasFiltersApplied: boolean;
  onOpenAddEdit: (record: CommunityCategoryType) => void;
  onOpenDelete: (record: CommunityCategoryType) => void;
};

const Table = ({ onOpenAddEdit, onOpenDelete, totalCount, ...rest }: TableProps) => {
  const columns = useColumns({ onOpenDelete });

  const onRow = (record: CommunityCategoryType) => ({
    onClick: () => {
      onOpenAddEdit(record);
    },
  });

  const locale = {
    emptyText: () => (
      <Empty
        title={`${
          rest.hasFiltersApplied ? 'common_no_records_found' : 'communities_categories_empty_title'
        }`}
        description={`${
          rest.hasFiltersApplied ? 'common_empty_list_with_filters_description' : ''
        }`}
      />
    ),
  };

  return (
    <FiltersV2.Table<CommunityCategoryType, FormResponseType>
      rowKey="id"
      className={s.table}
      columns={columns}
      onRow={onRow}
      locale={locale}
      total={totalCount}
      {...rest}
    />
  );
};

export default Table;
