import { useMemo } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { Avatar, Icon, TableProps, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';

import { NotificationTypeWithPerson } from 'types/notification.types';
import { CPersonType } from 'types/person.types';

import s from './NotificationNewsletterEditStats.module.scss';

const useColumns = (): TableProps<NotificationTypeWithPerson>['columns'] => {
  return useMemo(() => {
    return [
      {
        title: t('common_receiver'),
        dataIndex: 'person',
        sorter: true,
        width: 300,
        render: (person: CPersonType) => {
          return (
            <div className={s.receiver}>
              <Avatar
                firstName={person.firstName ?? ''}
                lastName={person.lastName ?? ''}
                alt="аватар пользователя"
                size="40"
              />
              <Typography.Text weight="500" size="14">{`${person.lastName ?? ''} ${
                person.firstName ?? ''
              }`}</Typography.Text>
            </div>
          );
        },
      },
      {
        title: t('common_read'),
        dataIndex: 'viewed',
        align: 'center',
        sorter: true,
        render: (viewed: boolean) => (
          <div className={cn(s.viewedWrapper, viewed ? s.active : s.inactive)}>
            <Icon name="okFilled" />
          </div>
        ),
      },
      {
        title: t('common_feedback'),
        dataIndex: 'feedback',
        align: 'center',
        sorter: true,
        render: (feedback: NotificationTypeWithPerson['feedback']) =>
          !!feedback && <Icon name="comment" size={18} color="var(--color-primary-6)" />,
      },
      {
        title: t('notification_request_callback'),
        dataIndex: 'callBack',
        align: 'center',
        sorter: true,
        render: (callBack: NotificationTypeWithPerson['callBack']) =>
          !!callBack && <Icon name="time" size={18} color="var(--color-error)" />,
      },
      {
        title: t('common_notification_title'),
        dataIndex: 'title',
        align: 'center',
        render: (text: string) => (
          <Typography.Text size="12" title={text}>
            {text}
          </Typography.Text>
        ),
      },
      {
        title: t('events_notification_text'),
        dataIndex: 'body',
        align: 'center',
        render: (text: string) => (
          <Typography.Text size="12" className={s.message} title={text}>
            {text}
          </Typography.Text>
        ),
      },
      {
        title: t('notification_sent'),
        align: 'center',
        sorter: true,
        dataIndex: 'createdAt',
        render: (createdAt: number) => <TableDate date={createdAt} />,
      },
    ];
  }, []);
};

export default useColumns;
