import { useNavigate } from 'react-router-dom';

import { GetOrdersResponseType } from 'services/gift/giftApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { Empty } from 'components/empty';
import FiltersV2 from 'containers/filters-v2';

import { FormResponseType } from '../../presents.utils';
import s from './PresentsTable.module.scss';
import useColumns from './useColumns.hook';

interface Props {
  dataSource?: GetOrdersResponseType;
  loading: boolean;
  isInitialLoading: boolean;
  form: FormInstance<FormResponseType>;
}

const PresentsTable = (props: Props) => {
  const navigate = useNavigate();

  const { dataSource, loading, isInitialLoading, form } = props;

  const handleRowClick = (record: { id: string }) => navigate(record.id);

  const onRow = (record: { id: string }) => ({
    onClick: () => handleRowClick(record),
  });

  const columns = useColumns();

  const requestBody: FormResponseType | undefined = Form.useWatch([], form);
  const { filters, search } = requestBody ?? {};

  const hasFiltersApplied = !!filters?.deliveryType?.length || !!search?.length;

  const emptyText = (
    <Empty
      className={s.emptyContent}
      title={hasFiltersApplied ? 'presents_not_found' : 'presents_no_data'}
      description={hasFiltersApplied ? 'common_empty_list_with_filters_description' : ''}
    />
  );

  return (
    <FiltersV2.Table
      columns={columns}
      isInitialLoading={isInitialLoading}
      dataSource={dataSource?.content}
      total={dataSource?.totalCount}
      className={s.table}
      onRow={onRow}
      loading={loading}
      rowKey="key"
      showSorterTooltip={false}
      locale={{ emptyText }}
    />
  );
};

export default PresentsTable;
