import { Icon, Tag } from 'gazprom-ui-lib';

import { SendStatusMapValue } from '../table/useGetTabTableColumns.utils';
import s from './StatusTag.module.scss';

const StatusTag = ({ iconName, text, type }: SendStatusMapValue) => {
  return (
    <Tag className={s.tag} type={type} icon={<Icon name={iconName} />}>
      {text}
    </Tag>
  );
};

export default StatusTag;
