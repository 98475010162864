import { useParams } from 'react-router-dom';
import { COMMUNITY_MANAGEMENT_ROUTE } from 'routes/communities/list';
import { t } from 'tools/i18n';

import { Button, FormInstance } from 'gazprom-ui-lib';

import { CommunityManagementFormResponse } from 'containers/community-management-form/communityManagementForm.utils';
import ContentHeader from 'containers/content-header';

type HeaderProps = {
  isUpdate: boolean;
  submitForm: FormInstance<CommunityManagementFormResponse>['submit'];
  isCreatingOrLoading: boolean;
};

const Header = ({ isUpdate, submitForm, isCreatingOrLoading }: HeaderProps) => {
  const { id: communityId } = useParams<{ id: string }>();

  const goBackLink = communityId
    ? `${COMMUNITY_MANAGEMENT_ROUTE}/view/${communityId}`
    : COMMUNITY_MANAGEMENT_ROUTE;

  return (
    <ContentHeader>
      <ContentHeader.Title goBackLink={goBackLink}>
        {t(isUpdate ? 'communities_edit' : 'communities_add')}
      </ContentHeader.Title>
      <ContentHeader.Actions>
        <Button loading={isCreatingOrLoading} leftIcon="ok" onClick={submitForm}>
          {t('common_save')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
