import { useCallback, useMemo } from 'react';

import { t } from 'tools/i18n';
import downloadHandler from 'utils/downloadHandler';

import { Button, TableProps, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TableStatus from 'components/table/table-status';
import { STATUSES } from 'components/table/table-status/tableStatus.utils';

import { ReviewTaskAssignmentType } from 'types/review-task.types';

interface UseColumnsHookProps {
  recruitId?: string;
  hideDownload?: boolean;
}

const useColumnsHook = (
  props: UseColumnsHookProps,
): TableProps<ReviewTaskAssignmentType>['columns'] => {
  const { hideDownload } = props;

  const dateRenderer = (
    date: ReviewTaskAssignmentType['createdAt'] | ReviewTaskAssignmentType['finishDate'],
  ) => <TableDate date={date} />;

  const handleDownload = useCallback((row: ReviewTaskAssignmentType) => {
    const file = row.files[0];
    const url = `${process.env.https://admin-gateway-test.dev.tockl.ru/api/v1/}recruit/download/lna?taskAssignmentId=${row.assignmentId}`;

    downloadHandler({
      url,
      fileName: file.fileName,
      headersFileNameKey: 'filename',
    });
  }, []);

  return useMemo(
    () => [
      {
        title: t('common_document_name'),
        dataIndex: 'title',
        render: (docName: string) => (
          <Typography.Text weight="500" size="14">
            {docName}
          </Typography.Text>
        ),
      },
      {
        title: t('documents_recruit_submitted_for_review'),
        dataIndex: 'createdAt',
        render: dateRenderer,
      },
      {
        title: t('common_status'),
        dataIndex: 'status',
        render: (status: { state: string; title: string }) => (
          <TableStatus status={status.state.toUpperCase() as STATUSES}>{status.title}</TableStatus>
        ),
      },
      {
        title: t('documents_recruit_review_date'),
        dataIndex: 'finishDate',
        render: dateRenderer,
      },
      {
        title: '',
        render: (_, row) =>
          row.files[0] && !hideDownload ? (
            <Button leftIcon="download" onlyIcon type="link" onClick={() => handleDownload(row)} />
          ) : null,
      },
    ],
    [handleDownload, hideDownload],
  );
};

export default useColumnsHook;
