import { t } from 'tools/i18n';

import { Button, ColumnsType, Flex, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';

import { CommunityCategoryType } from 'types/community.types';

type UseColumnsProps = {
  onOpenDelete: (record: CommunityCategoryType) => void;
};

const useColumns = ({ onOpenDelete }: UseColumnsProps): ColumnsType<CommunityCategoryType> => [
  {
    title: t('common_category_name'),
    dataIndex: 'name',
    render: (name: CommunityCategoryType['name']) => (
      <Typography.Text size="14" weight="500">
        {name}
      </Typography.Text>
    ),
  },
  {
    title: t('communities_count'),
    align: 'center',
    dataIndex: 'countCommunity',
    key: 'countCommunity',
    sorter: true,
    render: (countCommunity: CommunityCategoryType['countCommunity']) => (
      <Typography.Text size="14" weight="500">
        {countCommunity}
      </Typography.Text>
    ),
  },
  {
    title: t('common_created'),
    align: 'center',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: true,
    render: (createdAt: CommunityCategoryType['createdAt']) => <TableDate date={createdAt} />,
  },
  {
    title: t('common_updated'),
    align: 'center',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    defaultSortOrder: 'descend',
    sorter: true,
    render: (updatedAt: CommunityCategoryType['updatedAt']) => <TableDate date={updatedAt} />,
  },
  {
    title: '',
    align: 'center',
    width: 240,
    render: (record) => {
      return (
        <Flex justify="center" align="center">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onOpenDelete(record);
            }}
            type="link"
            danger
            leftIcon="bin"
            size="small">
            {t('common_delete')}
          </Button>
        </Flex>
      );
    },
  },
];

export default useColumns;
