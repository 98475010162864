import { ChangeEvent } from 'react';

import { t } from 'tools/i18n';

import { Icon, Input } from 'gazprom-ui-lib';

import s from './Filter.module.scss';

type FilterProps = {
  treeSearch: string;
  handleTreeSearch: (e: ChangeEvent<HTMLInputElement>) => void;
};

const Filter = ({ treeSearch, handleTreeSearch }: FilterProps) => {
  return (
    <Input
      className={s.filter}
      prefix={<Icon name="search" />}
      placeholder={t('common_search')}
      size="small"
      value={treeSearch}
      onChange={handleTreeSearch}
    />
  );
};

export default Filter;
