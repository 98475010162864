import { prepareQueryParams } from 'utils/prepareQueryParams';

import { https://admin-gateway-test.dev.tockl.ru/api/v1/ } from 'constants/apiUrls';

export const createDownloadUrl = (id: string, fileKey: string, full: boolean = false) => {
  return `${https://admin-gateway-test.dev.tockl.ru/api/v1/}renew-term/form/${id}/download-file${prepareQueryParams({
    fileKey,
    full,
  })}`;
};

export const createDownloadArchiveUrl = (id: string, full: boolean = false) => {
  return `${https://admin-gateway-test.dev.tockl.ru/api/v1/}renew-term/form/${id}/archive${prepareQueryParams({
    full,
  })}`;
};

export const ARCHIVE_NAME = 'anketa.zip';
