import { ReactNode } from 'react';

import { t } from 'tools/i18n';

import { TabsProps } from 'gazprom-ui-lib';

import DiscussionsTab from './containers/discussions/discussions-tab';
import NewsTab from './containers/news/news-tab';

enum COMMUNITY_MANAGEMENT_VIEW_TABS_ENUM {
  NEWS = 'NEWS',
  MEET = 'MEET',
  ARTICLE_DISCUSSIONS = 'ARTICLE_DISCUSSIONS',
  ANKETA = 'ANKETA',
  ARTICLE_SUGGESTED = 'ARTICLE_SUGGESTED',
}

type CommunityManagementViewMapTabValue = {
  key: COMMUNITY_MANAGEMENT_VIEW_TABS_ENUM;
  label: string;
  children: ReactNode;
};

export const COMMUNITY_MANAGEMENT_VIEW_TABS_MAP: Record<
  COMMUNITY_MANAGEMENT_VIEW_TABS_ENUM,
  CommunityManagementViewMapTabValue
> = {
  NEWS: {
    key: COMMUNITY_MANAGEMENT_VIEW_TABS_ENUM.NEWS,
    label: t('common_news'),
    children: <NewsTab />,
  },
  MEET: {
    key: COMMUNITY_MANAGEMENT_VIEW_TABS_ENUM.MEET,
    label: t('common_events'),
    children: <div>Мероприятия</div>,
  },
  ARTICLE_DISCUSSIONS: {
    key: COMMUNITY_MANAGEMENT_VIEW_TABS_ENUM.ARTICLE_DISCUSSIONS,
    label: t('common_discussions'),
    children: <DiscussionsTab />,
  },
  ANKETA: {
    key: COMMUNITY_MANAGEMENT_VIEW_TABS_ENUM.ANKETA,
    label: t('common_surveys_short'),
    children: <div>Опросы</div>,
  },
  ARTICLE_SUGGESTED: {
    key: COMMUNITY_MANAGEMENT_VIEW_TABS_ENUM.ARTICLE_SUGGESTED,
    label: t('common_suggested'),
    children: <div>Предложенные</div>,
  },
};

export const tabsItems: TabsProps['items'] = Object.values(COMMUNITY_MANAGEMENT_VIEW_TABS_MAP).map(
  (tab) => tab,
);
