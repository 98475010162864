import { Link } from 'react-router-dom';
import { COMMUNITY_MANAGEMENT_ROUTE } from 'routes/communities/list';
import { t } from 'tools/i18n';
import { getPluralEnding } from 'utils/declension.utils';
import { useVisibility } from 'utils/useVisibility.hook';

import { Button, Flex, Icon, Typography } from 'gazprom-ui-lib';

import Cover from 'components/cover';

import { CommunityTypeWithModerators } from 'types/community.types';

import s from './CommunityInfo.module.scss';
import ModeratorsModal from './containers/moderators-modal';

type CommunityInfoProps = {
  community: CommunityTypeWithModerators;
};

const CommunityInfo = ({ community }: CommunityInfoProps) => {
  const { name, categories, avatar, countMembers, moderators, id: communityId } = community;

  const communityCategories = categories.map(({ name }) => name).join(' • ');
  const [moderatorsModalIsVisible, { close, open }] = useVisibility();

  return (
    <Flex align="start" vertical gap="8">
      <Flex align="center" gap="8">
        <Cover className={s.communityCover} src={avatar?.link} alt="Аватар сообщества" />
        <Flex vertical gap="4">
          <Typography.Text size="18" weight="500">
            {name}
          </Typography.Text>
          <Flex gap="16" align="center" wrap="wrap">
            <Typography.Text size="14" weight="400" type="secondary">
              {communityCategories}
            </Typography.Text>

            <Link
              className={s.subscribersLink}
              to={`${COMMUNITY_MANAGEMENT_ROUTE}/view/${communityId}/subscribers`}>
              <Flex gap="8" align="center">
                <Icon name="groupsFilled" color="var(--color-primary)" />
                <Typography.Text className={s.countMembers} weight="400" size="14">
                  {countMembers} {getPluralEnding({ count: countMembers, wordBase: 'участник' })}
                </Typography.Text>
              </Flex>
            </Link>
          </Flex>
        </Flex>
      </Flex>

      <Button onClick={open} leftIcon="accountCircle" className={s.moderatorsButton} type="link">
        {t('community_moderators')}
      </Button>
      <ModeratorsModal open={moderatorsModalIsVisible} close={close} moderators={moderators} />
    </Flex>
  );
};

export default CommunityInfo;
