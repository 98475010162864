import { useState } from 'react';

import { useParams } from 'react-router-dom';
import { getCommunityManagementViewByIdRoute } from 'routes/communities/list';
import { t } from 'tools/i18n';

import { useUpdateCommunityDiscussionMutation } from 'services/communities/communitiesApiService';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import DiscussionCreateEditModal from 'containers/discussion-create-edit-modal';

import { CommunityDiscussionType } from 'types/community.types';

import CreateCommentModal from '../comments/containers/create-comment-modal';

interface Props {
  title: string;
  discussion?: CommunityDiscussionType;
}

const Header = (props: Props) => {
  const { title, discussion } = props;
  const { id } = useParams<{ id: string }>();
  const [isCreateCommentModalVisible, setIsCreateCommentModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const [update] = useUpdateCommunityDiscussionMutation();

  return (
    <>
      <DiscussionCreateEditModal
        communityId={id!}
        edit
        discussion={discussion}
        open={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
      />
      <ContentHeader>
        {/* @TODO custom handleEdit........ */}
        <ContentHeader.Title
          editConfig={{
            value: '',
            isLoading: false,
            handleSave: () => new Promise(() => {}),
            title: '',
            label: '',
            handleEdit: () => setIsEditModalVisible(true),
          }}
          goBackLink={getCommunityManagementViewByIdRoute(id!)}>
          {title}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          <Button
            type="secondary"
            danger={discussion?.canComment}
            leftIcon={discussion?.canComment ? 'delete' : 'ok'}
            onClick={() => {
              if (discussion)
                update({
                  communityId: id!,
                  discussionId: discussion.id,
                  title: discussion.title,
                  isPublish: discussion.isPublish,
                  canComment: !discussion.canComment,
                });
            }}>
            {discussion?.canComment
              ? t('community_discussion_close')
              : t('community_discussion_open')}
          </Button>
          <Button
            type="primary"
            leftIcon="comment"
            onClick={() => {
              setIsCreateCommentModalVisible(true);
            }}>
            {t('community_discussion_comment')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>
      <CreateCommentModal
        open={isCreateCommentModalVisible}
        handleClose={() => setIsCreateCommentModalVisible(false)}
      />
    </>
  );
};

export default Header;
