import { ChangeEvent, useState } from 'react';

import { t } from 'tools/i18n';

import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import { FilterCheckboxGroupOptions } from 'containers/filters/filter-checkbox-group/filterCheckboxGroup';
import FilterHeader from 'containers/filters/filter-header';

import { CommunityManagementSubscribersEnumType } from '../tabs/tabs.utils';

type UseFilterItemsProps = {
  tabType: CommunityManagementSubscribersEnumType;
};

export const useFilterItems = ({ tabType }: UseFilterItemsProps) => {
  // TODO.Ilya add endpoints for filter data when BE ready - https://jira.tockl.ru/browse/GSP-4746
  const [employersSearch, setEmployersSearch] = useState('');
  const [divisionsSearch, setDivisionsSearch] = useState('');

  const handleEmployersSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setEmployersSearch(e.target.value);
  };

  const handleDivisionsSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setDivisionsSearch(e.target.value);
  };

  const preparedEmployers: FilterCheckboxGroupOptions[] = [{ label: 'testEmp', value: '123' }];
  const preparedDivisions: FilterCheckboxGroupOptions[] = [{ label: 'testDiv', value: '456' }];

  const filterItems = [
    {
      key: 'employerIds',
      label: <FilterHeader valueKey="employerIds">{t('common_organization')}</FilterHeader>,
      children: (
        <FilterCheckboxGroup
          valueKey="employerIds"
          options={preparedEmployers}
          showSearch
          searchProps={{
            placeholder: t('common_search'),
            value: employersSearch,
            onChange: handleEmployersSearch,
          }}
        />
      ),
    },
    {
      key: 'divisionIds',
      label: <FilterHeader valueKey="divisionIds">{t('common_department')}</FilterHeader>,
      children: (
        <FilterCheckboxGroup
          valueKey="divisionIds"
          options={preparedDivisions}
          showSearch
          searchProps={{
            placeholder: t('common_search'),
            value: divisionsSearch,
            onChange: handleDivisionsSearch,
          }}
        />
      ),
    },
  ];

  return tabType === 'SUBSCRIBERS' ? filterItems : [filterItems[0]];
};
