import cn from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { COMMUNITY_MANAGEMENT_ROUTE } from 'routes/communities/list';
import { t } from 'tools/i18n';

import { Button, Flex, Form, FormInstance } from 'gazprom-ui-lib';

import s from './CommunityManagementForm.module.scss';
import CommunityManagementFormCategories from './community-management-form-categories';
import CommunityManagementFormDescription from './community-management-form-description';
import CommunityManagementFormIsPublish from './community-management-form-is-publish';
import CommunityManagementFormLinks from './community-management-form-links';
import CommunityManagementFormMedia from './community-management-form-media';
import CommunityManagementFormModerators from './community-management-form-moderators';
import CommunityManagementFormName from './community-management-form-name';
import CommunityManagementFormType from './community-management-form-type';
import {
  COMMUNITY_MANAGEMENT_FORM_ENUM,
  COMMUNITY_MANAGEMENT_FORM_NAME,
  CommunityManagementFormResponse,
} from './communityManagementForm.utils';

type CommunityManagementFormProps = {
  handleSubmit: (form: CommunityManagementFormResponse) => void;
  className?: string;
  form: FormInstance<CommunityManagementFormResponse>;
  isCreatingOrUpdating: boolean;
};

const CommunityManagementForm = ({
  handleSubmit,
  className,
  form,
  isCreatingOrUpdating,
}: CommunityManagementFormProps) => {
  const { id: communityId } = useParams<{ id: string }>() as { id: string };

  return (
    <Form
      form={form}
      className={cn(s.wrapper, className)}
      onFinish={handleSubmit}
      id={COMMUNITY_MANAGEMENT_FORM_NAME}>
      <CommunityManagementFormName />
      <CommunityManagementFormDescription />
      <CommunityManagementFormMedia
        fieldName={COMMUNITY_MANAGEMENT_FORM_ENUM.AVATAR}
        label={t('communities_avatar')}
      />
      <CommunityManagementFormMedia
        fieldName={COMMUNITY_MANAGEMENT_FORM_ENUM.COVER}
        label={t('communities_cover')}
      />
      <CommunityManagementFormType />
      <CommunityManagementFormCategories />
      <CommunityManagementFormLinks />
      <CommunityManagementFormModerators />
      <CommunityManagementFormIsPublish />

      <Flex gap="8">
        <Link className={s.fullWidth} to={`${COMMUNITY_MANAGEMENT_ROUTE}/view/${communityId}`}>
          <Button type="secondary" fullWidth form={COMMUNITY_MANAGEMENT_FORM_NAME}>
            {t('common_close')}
          </Button>
        </Link>
        <Button
          leftIcon="ok"
          fullWidth
          type="primary"
          htmlType="submit"
          form={COMMUNITY_MANAGEMENT_FORM_NAME}
          loading={isCreatingOrUpdating}>
          {t('common_save')}
        </Button>
      </Flex>
    </Form>
  );
};

export default CommunityManagementForm;
