import { t } from 'tools/i18n';

import { Button, Flex, ModalDefault, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';

import { CommunityNewsComment } from 'types/community.types';

import s from './DetailsModal.module.scss';
import useRequest from './useRequest';

interface Props {
  handleOpenCreateModal: (parentId?: string) => void;
  detailsComment: CommunityNewsComment | null;
  closeDetails: () => void;
}

const DetailsModal = (props: Props) => {
  const { detailsComment, handleOpenCreateModal, closeDetails } = props;

  const { comment, createdDate, creator, isRemoveCommentLoading, handleRemoveComment } = useRequest(
    { detailsComment, closeDetails },
  );

  return (
    <ModalDefault open={!!detailsComment} onCancel={closeDetails} width={520} closable>
      <Flex vertical gap="24" className={s.container}>
        <Flex vertical gap="4" align="flex-start">
          <Typography.Title level={4}>{t('common_comment')}</Typography.Title>
          <Typography.Text size="14" type="secondary">
            {createdDate}
          </Typography.Text>
        </Flex>

        <Bio
          firstName={creator?.person.firstName}
          lastName={creator?.person.lastName}
          staffName={creator?.position?.name}
          employerName={creator?.employer.name}
        />

        <Flex vertical gap="4" align="flex-start">
          <Typography.Text size="12" type="secondary">
            {t('commont_comment_text')}
          </Typography.Text>
          <Typography.Text size="14" weight="500">
            {comment}
          </Typography.Text>
        </Flex>
      </Flex>

      <ModalDefault.Actions>
        <Flex gap="16">
          <Button
            fullWidth
            type="secondary"
            danger
            onClick={handleRemoveComment}
            loading={isRemoveCommentLoading}>
            {t('common_remove')}
          </Button>
          <Button
            fullWidth
            type="primary"
            leftIcon="return"
            onClick={() => {
              closeDetails();
              handleOpenCreateModal(detailsComment?.id);
            }}>
            {t('common_reply')}
          </Button>
        </Flex>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default DetailsModal;
