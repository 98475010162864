import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import { useVerdictAssignmentMutation } from 'services/1c/cApiService';

import { Button, handleRequest } from 'gazprom-ui-lib';

const CancelOrgApproveButton = () => {
  const { id, executorId } = useParams<{ id: string; executorId: string }>();
  const [verdict, { isLoading }] = useVerdictAssignmentMutation();

  const onSuccess = useHandleRequestWithModal({
    onSuccessModalConfig: {
      title: {
        children: t('documents_refuse_approve_success_title'),
      },
      description: { children: t('documents_refuse_approve_success_description') },
    },
  });

  const handleVerdict = async () => {
    if (id && executorId) {
      verdict({
        id: id!,
        myEmployeeId: executorId,
        status: 'APPROVED',
      }).then(handleRequest({ onSuccess }));
    }
  };

  return (
    <>
      <Button onClick={handleVerdict} leftIcon="signature" loading={isLoading}>
        {t('top_management_form_approve')}
      </Button>
    </>
  );
};

export default CancelOrgApproveButton;
