import { FileType } from './file.types';

export enum EMPLOYMENT_TYPE_ENUM {
  MAIN_WORK = 'mainWork',
  EXTRA_WORK = 'extraWorkInternal',
}

export type StaffType = {
  id: string;
  name: string;
};

export type PositionType = {
  id: string;
  name: string;
};

export type DivisionType = {
  id: string;
  name: string;
  structureType: string;
  type: string;
};

export type EmployerType = {
  id: string;
  name: string;
};

export type OrganizationType = {
  id: string;
  name: string;
};

export type PersonType = {
  id: string;
  lastName: string;
  firstName: string;
  patronymic: string;
};

export type PowerOfAttorneyType = {
  id: string;
  expireDate: string;
  inn: string;
  kpp: string;
  ogrn: string;
  orgName: string;
  files: FileType[];
  employeeId: string;
};

export type EmployeeType = {
  id: string;
  employmentType: string;
  person: PersonType;
  employer: EmployerType;
  division: DivisionType;
  position: PositionType;
  staff: StaffType;
  partTime: number;
  powerOfAttorney: PowerOfAttorneyType | null;
};

export type EmployeeShortType = {
  divisionName: string;
  employerName: string;
  firstName: string;
  id: string;
  lastName: string;
  patronymic: string;
  positionName: string;
};
