import { useEffect, useRef } from 'react';

import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import { useUpdateCommunityDiscussionCommentMutation } from 'services/communities/communitiesApiService';

import { CommunityDiscussionComment } from 'types/community.types';

interface Props {
  detailsComment: CommunityDiscussionComment | null;
  closeDetails: () => void;
}

const useRequest = (props: Props) => {
  const { detailsComment, closeDetails } = props;

  const { id, discussionId } = useParams<{ id: string; discussionId: string }>();

  const refCommentId = useRef<string | null>(null);

  const [removeComment, { isLoading: isRemoveCommentLoading }] =
    useUpdateCommunityDiscussionCommentMutation();

  const { id: commentId, createdAt, creator, comment } = detailsComment || {};
  const createdDate = dayjs(createdAt).format('DD.MM.YYYY в HH:mm');
  const isParamsExist = id && discussionId;

  useEffect(() => {
    if (commentId) {
      refCommentId.current = commentId;
    }
  }, [commentId]);

  const handleRemoveComment = () => {
    if (isParamsExist && comment && commentId) {
      removeComment({
        communityId: id,
        discussionId: discussionId,
        commentId: commentId,
        comment,
        visible: false,
      });
    }

    closeDetails();
  };

  return {
    createdDate,
    creator,
    comment,
    isRemoveCommentLoading,
    handleRemoveComment,
  };
};

export default useRequest;
