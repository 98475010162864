import { useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { GetWorkerEmployeesResponseType } from 'services/1c/cApiService.types';

import { Flex, ModalDefault } from 'gazprom-ui-lib';

import Employee from 'containers/header/containers/employee';

import { EmployeeType } from 'types/employee.types';

import s from './ProfileModal.module.scss';

interface Props {
  employeesData: GetWorkerEmployeesResponseType | undefined;
}

const ProfileModal = (props: Props) => {
  const { employeesData } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [selectedEmployee, setSelectedEmployee] = useFullSelectedEmployee();

  const handleSelectEmployee = (id: string) => () => {
    setSelectedEmployee(id);
    handleCloseModal();
  };
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const renderEmployee = (employee: EmployeeType) => (
    <div className={s.wrapper} key={employee.id}>
      <Employee
        handleSelect={handleSelectEmployee(employee.id)}
        employee={employee}
        isSelected={true}
        showDivision={false}
      />
    </div>
  );

  useEffect(() => {
    if (!employeesData) return;

    const currentEmployeeDoesNotExist =
      selectedEmployee && !employeesData.some((e) => e.id === selectedEmployee.id);

    if (currentEmployeeDoesNotExist && employeesData.length === 1) {
      setSelectedEmployee(employeesData[0]?.id);
      return;
    }

    if (!selectedEmployee || currentEmployeeDoesNotExist) {
      handleOpenModal();
    }
  }, [employeesData, selectedEmployee, setSelectedEmployee]);

  return (
    <ModalDefault open={isModalOpen} width={420} className={s.modal}>
      <ModalDefault.Title>{t('common_select_profile')}</ModalDefault.Title>

      <Flex vertical gap="12" className={s.container}>
        {employeesData?.map(renderEmployee)}
      </Flex>
    </ModalDefault>
  );
};

export default ProfileModal;
