import { FC } from 'react';

import { Table } from 'gazprom-ui-lib';

import { ReviewTaskAssignmentType } from 'types/review-task.types';

import useColumns from './useColumns.hook';

interface LnaTableProps {
  data: ReviewTaskAssignmentType[];
  recruitId?: string;
  hideDownload?: boolean;
}

const LnaTable: FC<LnaTableProps> = (props) => {
  const { data, recruitId, hideDownload } = props;

  const columns = useColumns({
    recruitId,
    hideDownload,
  });

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey="id"
      pagination={{
        current: 1,
        total: 1,
      }}
    />
  );
};

export default LnaTable;
