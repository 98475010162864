import { t } from 'tools/i18n';
import { getFiltersCount } from 'utils/filter.utils';

import { Flex, Form } from 'gazprom-ui-lib';

import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import { CommunityManagementSubscribersEnumType } from '../tabs/tabs.utils';
import InviteSubscribeUser from './containers/invite-subscribe-user';
import { COMMUNITY_INVITES_FILTERS_OPTIONS } from './filters.utils';
import { useFilterItems } from './useFilterItems.hook';

type FiltersProps = {
  tabType: CommunityManagementSubscribersEnumType;
};

const Filters = ({ tabType }: FiltersProps) => {
  const filters = Form.useWatch('filters');
  const filterItems = useFilterItems({ tabType });

  const renderSubscribedOrBlockedFilters = tabType === 'SUBSCRIBERS' || tabType === 'BLOCKED';
  const renderInvitesFilters = tabType === 'INVITES';
  const renderSubscibedOrInvitesActions = tabType === 'SUBSCRIBERS' || tabType === 'INVITES';
  return (
    <FiltersV2.TableFilters>
      <Flex align="items">
        <FiltersV2.Search />
      </Flex>
      {renderSubscibedOrInvitesActions && <InviteSubscribeUser tabType={tabType} />}
      <FiltersContainer
        showClearAll
        overlay={
          <>
            {renderSubscribedOrBlockedFilters && <FilterCollapse items={filterItems} />}

            {renderInvitesFilters && (
              <FilterCustomWrapper>
                <FilterHeader valueKey="statuses">{t('common_status')}</FilterHeader>
                <FilterCheckboxGroup
                  usePadding={false}
                  valueKey="statuses"
                  options={COMMUNITY_INVITES_FILTERS_OPTIONS}
                />
              </FilterCustomWrapper>
            )}
          </>
        }
        filtersCount={getFiltersCount(filters)}
      />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
