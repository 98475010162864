import { useNavigate } from 'react-router-dom';
import { COMMUNITY_MANAGEMENT_ROUTE } from 'routes/communities/list';

import { Empty } from 'components/empty';
import FiltersV2 from 'containers/filters-v2';

import { CommunityTypeWithoutModerators } from 'types/community.types';

import { FormResponseType } from '../../communityManagement.utils';
import s from './Table.module.scss';
import useColumns from './useColumns.hook';

type TableProps = {
  dataSource?: CommunityTypeWithoutModerators[];
  loading?: boolean;
  total?: number;
  isInitialLoading: boolean;
  hasFiltersApplied: boolean;
};

const Table = (props: TableProps) => {
  const navigate = useNavigate();
  const columns = useColumns();

  const locale = {
    emptyText: () => (
      <Empty
        title={`${props.hasFiltersApplied ? 'common_no_records_found' : 'communities_empty_title'}`}
        description={`${
          props.hasFiltersApplied ? 'common_empty_list_with_filters_description' : ''
        }`}
      />
    ),
  };

  const onRow = (community: CommunityTypeWithoutModerators) => ({
    onClick: () => navigate(`${COMMUNITY_MANAGEMENT_ROUTE}/view/${community.id}`),
  });

  return (
    <FiltersV2.Table<CommunityTypeWithoutModerators, FormResponseType>
      rowKey="id"
      onRow={onRow}
      className={s.table}
      columns={columns}
      locale={locale}
      {...props}
    />
  );
};

export default Table;
