import { useState } from 'react';

import { t } from 'tools/i18n';

import { Button, Form } from 'gazprom-ui-lib';

import ModalUpload from '../modal-upload';
import s from './UploadButton.module.scss';

interface Props {
  employeeId: string;
}

const UploadButton = (props: Props) => {
  const { employeeId } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = Form.useForm();

  const handleOpenModal = () => setIsOpen((prev) => !prev);

  return (
    <>
      <Button
        type="link"
        leftIcon="upload"
        fullWidth
        onClick={handleOpenModal}
        className={s.button}>
        {t('common_upload_files')}
      </Button>
      <ModalUpload open={isOpen} employeeId={employeeId} form={form} onCancel={handleOpenModal} />
    </>
  );
};

export default UploadButton;
