import { GetEmployersResponse } from 'services/employer/employerApiService.types';

export const selectFromResult = ({ data }: { data?: GetEmployersResponse }) => ({
  employersOptions: data?.content.map(({ id, name }) => ({ value: id, label: name })) ?? [],
});

export type RequestBody = {
  employerName?: string | null;
  page?: number;
  size?: number;
};

export const INITIAL_REQUEST_BODY: RequestBody = {
  employerName: null,
  page: 1,
  size: 20,
};
