import cn from 'classnames';
import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { Flex, Icon, TableProps, Typography } from 'gazprom-ui-lib';

import { EmployeeType } from 'types/employee.types';

import RemoveButton from '../remove-button';
import UploadButton from '../upload-button';
import s from './Table.module.scss';

const useColumns = (): TableProps<EmployeeType>['columns'] => {
  const renderText = (text: string) => (
    <Typography.Text size="14" weight="500">
      {text}
    </Typography.Text>
  );

  const renderAttorney = (value: EmployeeType['powerOfAttorney'], record: EmployeeType) => {
    const employeeId = record.id;

    if (!value) {
      return <UploadButton employeeId={record.id} />;
    }

    const formattedDate = dayjs(value.expireDate).format('DD.MM.YYYY');
    const isExpired = dayjs(dayjs()).isAfter(value.expireDate, 'day');

    const text = isExpired
      ? t('common_invalide')
      : t('common_valide_until', { date: formattedDate });
    const typeOfText = isExpired ? 'danger' : 'success';

    return (
      <Flex gap="16" justify="space-between">
        <Flex gap="8" align="center">
          <Icon name={isExpired ? 'delete' : 'ok'} className={cn(s.icon, { [s.red]: isExpired })} />
          <Typography.Text size="12" weight="500" type={typeOfText}>
            {text}
          </Typography.Text>
        </Flex>
        <Flex gap="16">
          <RemoveButton employeeId={employeeId} />
        </Flex>
      </Flex>
    );
  };

  return [
    {
      title: t('common_staffName'),
      dataIndex: 'employer',
      render: (value) => renderText(value.name),
    },
    {
      title: t('common_organization'),
      dataIndex: 'position',
      render: (value) => renderText(value.name),
    },
    {
      title: t('common_machine_readable_attorney'),
      dataIndex: 'powerOfAttorney',
      render: renderAttorney,
      width: 300,
    },
  ];
};

export default useColumns;
