import { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import {
  useResponseToCommentByCommunityNewsIdMutation,
  useUpdateCommentByCommunityNewsIdMutation,
} from 'services/communities/communitiesApiService';

import { CommunityNewsComment } from 'types/community.types';

interface Props {
  detailsComment: CommunityNewsComment | null;
  closeDetails: () => void;
}

const useRequest = (props: Props) => {
  const { detailsComment, closeDetails } = props;

  const { id, newsId } = useParams<{ id: string; newsId: string }>();

  const refCommentId = useRef<string | null>(null);
  const [isCommentOpen, setIsCommentOpen] = useState<boolean>(false);
  const [newComment, setNewComment] = useState<string>('');

  const [removeComment, { isLoading: isRemoveCommentLoading }] =
    useUpdateCommentByCommunityNewsIdMutation();
  const [sendResponse, { isLoading: isSendResponseLoading }] =
    useResponseToCommentByCommunityNewsIdMutation();

  const { id: commentId, createdAt, creator, comment } = detailsComment || {};
  const createdDate = dayjs(createdAt).format('DD.MM.YYYY в HH:mm');
  const isParamsExist = id && newsId;

  useEffect(() => {
    if (commentId) {
      refCommentId.current = commentId;
    }
  }, [commentId]);

  const handleRemoveComment = () => {
    if (isParamsExist && comment && commentId) {
      removeComment({
        communityId: id,
        newsId: newsId,
        commentId: commentId,
        body: {
          comment,
          visible: false,
        },
      });
    }

    closeDetails();
  };

  const handleSendResponse = () => {
    if (isParamsExist && refCommentId.current) {
      sendResponse({
        communityId: id,
        newsId: newsId,
        body: {
          comment: newComment,
          visible: true,
          parentId: refCommentId.current,
        },
      });
    }

    handleCloseComment();
  };

  const handleCloseComment = () => {
    setNewComment('');
    setIsCommentOpen(false);
  };

  const handleOpenComment = () => {
    closeDetails();
    setIsCommentOpen(true);
  };

  return {
    createdDate,
    creator,
    comment,
    newComment,
    isRemoveCommentLoading,
    isSendResponseLoading,
    isCommentOpen,
    handleOpenComment,
    setNewComment,
    handleRemoveComment,
    handleCloseComment,
    handleSendResponse,
  };
};

export default useRequest;
