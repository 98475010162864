import { useVisibility } from 'utils/useVisibility.hook';

import { Form } from 'gazprom-ui-lib';

import MassSubscribeInviteForm from 'containers/mass-subscribe-invite-form';
import {
  MASS_SUBSCRIBE_INVITE_FORM_ENUM,
  MassSubscribeInviteFormResponse,
} from 'containers/mass-subscribe-invite-form/massSubscribeInviteForm.utils';

import { CommunityManagementSubscribersEnumType } from '../containers/tabs/tabs.utils';
import Header from './containers/header';

type CommunityManagementSubscribersAddInviteProps = {
  pageType: CommunityManagementSubscribersEnumType;
};

const CommunityManagementSubscribersAddInvite = ({
  pageType,
}: CommunityManagementSubscribersAddInviteProps) => {
  const [form] = Form.useForm<MassSubscribeInviteFormResponse>();
  const [confirmModalIsOpened, { close, open }] = useVisibility();
  const isSubscribersPage = pageType === 'SUBSCRIBERS';
  const disableSubmit =
    (Form.useWatch(MASS_SUBSCRIBE_INVITE_FORM_ENUM.TREE_IDS, form) ?? []).length < 1;

  return (
    <>
      <Header
        disableSubmit={disableSubmit}
        openConfirmModal={open}
        isSubscribersPage={isSubscribersPage}
      />
      <MassSubscribeInviteForm
        closeConfirmModal={close}
        openConfirmModal={open}
        confirmModalIsOpened={confirmModalIsOpened}
        form={form}
        isSubscribersPage={isSubscribersPage}
        disableSubmit={disableSubmit}
      />
    </>
  );
};

export default CommunityManagementSubscribersAddInvite;
