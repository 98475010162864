import { FC } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import downloadHandler from 'utils/downloadHandler';

import { GetKedoReviewTaskAssignmentsPropsType } from 'services/kedo-review-tasks/kedoReviewTasksApiService.types';
import { createKedoReviewTaskUrl } from 'services/kedo-review-tasks/utils';

import { Button, Flex, Icon, Input, InputProps } from 'gazprom-ui-lib';

import InternalFilters from 'containers/filters';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import { https://admin-gateway-test.dev.tockl.ru/api/v1/ } from 'constants/apiUrls';
import { REVIEW_TASK_TYPES_ENUM } from 'types/review-task.types';

import s from './Filters.module.scss';
import { getAssigneeStatusOptions } from './filters.utils';
import useFilterItemsHook from './useFilterItems.hook';

interface FiltersProps {
  filters: Pick<GetKedoReviewTaskAssignmentsPropsType, 'status'> | null;
  setFilters: (values: Pick<GetKedoReviewTaskAssignmentsPropsType, 'status'> | null) => void;
  taskStatus?: REVIEW_TASK_TYPES_ENUM;
  search?: string;
  handleSearchChange?: InputProps['onChange'];
}

const Filters: FC<FiltersProps> = (props) => {
  const { filters, setFilters, taskStatus, search, handleSearchChange } = props;

  const { id: taskId } = useParams<{ id: string }>();

  const assigneeStatusOptions = getAssigneeStatusOptions();

  const handleListDownload = () => {
    if (taskId) {
      downloadHandler({
        url: `${https://admin-gateway-test.dev.tockl.ru/api/v1/}${createKedoReviewTaskUrl(`/${taskId}/generate`)}`,
        fileName: t('kedo_review_tasks_assign_list_download_template'),
        method: 'GET',
      });
    }
  };

  const items = useFilterItemsHook();

  return (
    <Flex align="center" justify="space-between" className={s.container}>
      <Flex align="center" gap="12">
        <Input
          allowClear
          value={search}
          onChange={handleSearchChange}
          className={s.input}
          placeholder={t('common_search')}
          prefix={<Icon name="search" />}
        />

        {taskStatus !== REVIEW_TASK_TYPES_ENUM.REVIEW_CREATED && (
          <Button
            className={s.downloadButton}
            type="link"
            leftIcon="download"
            onClick={handleListDownload}>
            {t('kedo_review_tasks_assignment_download_list')}
          </Button>
        )}
      </Flex>

      <InternalFilters
        initialValues={filters}
        overlay={
          <>
            <FilterCollapse items={items} />
            <FilterCustomWrapper>
              <FilterHeader valueKey="status">{t('common_status')}</FilterHeader>
              <FilterCheckboxGroup
                usePadding={false}
                valueKey="status"
                options={assigneeStatusOptions}
              />
            </FilterCustomWrapper>
          </>
        }
        handleApply={setFilters}
      />
    </Flex>
  );
};

export default Filters;
