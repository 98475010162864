import { t } from 'tools/i18n';

import { Icon, TableProps, Tag } from 'gazprom-ui-lib';

import Cover from 'components/cover';
import TableDate from 'components/table/table-date';
import TableText from 'components/table/table-text';

import { CommunityNewsType } from 'types/community.types';

import s from './NewsTab.module.scss';

const useColumns = (): TableProps<CommunityNewsType>['columns'] => {
  const eventCoverWithNameRenderer = (_: unknown, row: CommunityNewsType) => (
    <div className={s.eventCoverCell}>
      <Cover src={row.cover?.link} alt="event cover" />
      <div>
        <TableText size="12" weight="500" strong>
          {row.title}
        </TableText>
        <div>
          {row.categories.map((category) => (
            <Tag type="primary" key={category.id}>
              {category.name}
            </Tag>
          ))}
        </div>
      </div>
    </div>
  );

  const isPublishRenderer = (_: unknown, row: CommunityNewsType) => (
    <Icon
      name="okFilled"
      color={`var(${row?.isPublish ? '--color-success' : '--color-placeholder-25'})`}
    />
  );

  const updatedAtRenderer = (_: unknown, row: CommunityNewsType) => (
    <TableDate date={row.updatedAt} />
  );

  return [
    {
      title: t('news_news'),
      dataIndex: 'title',
      sorter: true,
      render: eventCoverWithNameRenderer,
    },
    {
      title: t('news_publication'),
      align: 'center' as const,
      width: 100,
      dataIndex: 'isPublish',
      render: isPublishRenderer,
    },
    {
      title: t('news_updated'),
      width: 110,
      sorter: true,
      align: 'center' as const,
      dataIndex: 'updatedAt',
      render: updatedAtRenderer,
      defaultSortOrder: 'descend',
    },
  ];
};

export default useColumns;
