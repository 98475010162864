import { useEffect, useState } from 'react';

import { useAuth } from 'react-oidc-context';

import { useGetWorkerEmployeesQuery } from 'services/1c/cApiService';

import useFullSelectedEmployee from './useFullSelectedEmployee.hook';

const useInitialAuthRequestsHook = () => {
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedEmployee] = useFullSelectedEmployee();

  const { data: employeesData, isLoading: getEmployeesIsLoading } = useGetWorkerEmployeesQuery(
    undefined,
    {
      skip: !auth?.isAuthenticated,
    },
  );

  useEffect(() => {
    if (isLoading && !auth.isLoading && auth.activeNavigator !== 'signinSilent') {
      setIsLoading(false);
    }
  }, [auth.activeNavigator, auth.isLoading, isLoading]);

  return {
    isAuthenticated: auth.isAuthenticated,
    employeesData,
    selectedEmployee,
    isLoading,
    getEmployeesIsLoading,
  };
};

export default useInitialAuthRequestsHook;
