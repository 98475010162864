import { prepareQueryParams } from 'utils/prepareQueryParams';

import cInitialApiService from 'services/1c/cApiService';

import {
  RemovePowerOfAttorneyFilesPropTypes,
  UploadPowerOfAttorneyFilesPropTypes,
} from './powerOfAttorneyApiService.types';
import { C_SERVICE_TAGS } from 'constants/serviceTags';

const positionApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    uploadPowerOfAttorneyFiles: builder.mutation<void, UploadPowerOfAttorneyFilesPropTypes>({
      query: ({ body, employeeId }) => ({
        url: `/power-of-attorney${prepareQueryParams({ employeeId })}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.GET_EMPLOYEES],
    }),
    removePowerOfAttorneyFiles: builder.mutation<void, RemovePowerOfAttorneyFilesPropTypes>({
      query: ({ employeeId }) => ({
        url: `/power-of-attorney${prepareQueryParams({ employeeId })}`,
        method: 'DELETE',
      }),
      invalidatesTags: [C_SERVICE_TAGS.GET_EMPLOYEES],
    }),
  }),
});

export const { useUploadPowerOfAttorneyFilesMutation, useRemovePowerOfAttorneyFilesMutation } =
  positionApiService;
