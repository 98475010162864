import { useMemo } from 'react';

import { getWorkerEmployeesSelector } from 'services/1c/cApiService';
import { useAppSelector } from 'store';

import { EmployeeType } from 'types/employee.types';

import useLocalStorage from './useLocalStorage.hook';

export const SELECTED_EMPLOYEE_KEY = 'selectedEmployee';

const useFullSelectedEmployee = (): [EmployeeType | null, (id: string) => void] => {
  const { data } = useAppSelector(getWorkerEmployeesSelector);

  const [selectedEmployeeId, setSelectedEmployee] = useLocalStorage(SELECTED_EMPLOYEE_KEY, '');

  const selectedEmployee = useMemo(() => {
    return data?.find((e) => e.id === selectedEmployeeId);
  }, [data, selectedEmployeeId]);

  return [selectedEmployee ?? null, setSelectedEmployee];
};

export default useFullSelectedEmployee;
