import { t } from 'tools/i18n';

import { Tabs } from 'gazprom-ui-lib';

import WithOverflow from 'containers/wrappers/with-overflow';

import s from './DiscussionsDetails.module.scss';
import Comments from './containers/comments';
import Header from './containers/header';
import useGetData from './useGetData';

const DiscussionsDetails = () => {
  const { discussion, discussionTitle } = useGetData();

  const TABS = [
    {
      key: '1',
      label: t('common_comments'),
      children: <Comments />,
    },
    {
      key: '2',
      label: t('common_removed'),
      children: <Comments isRemoved />,
    },
  ];

  return (
    <WithOverflow>
      <Header title={discussionTitle} discussion={discussion} />
      <Tabs type="card" defaultActiveKey="1" items={TABS} className={s.tabs} />
    </WithOverflow>
  );
};

export default DiscussionsDetails;
