import { useCallback, useEffect, useState } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

import Loader from 'components/loader';
import WithLoader from 'containers/wrappers/with-loader';

import s from './PreviewPdf.module.scss';
import { getFile } from './previewPdf.utils';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

interface Props {
  open: boolean;
  fileUrl: string;
  onCancel: () => void;
  handleSign: () => void;
}

type FileStateType = {
  fileBlob: string | null;
  fileType: string | null;
};

const PreviewPdf = (props: Props) => {
  const { fileUrl, open, onCancel, handleSign } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [{ fileBlob, fileType }, setPdfFile] = useState<FileStateType>({
    fileBlob: null,
    fileType: null,
  });

  const isPdfFile = fileType === 'application/pdf';
  const content = isPdfFile ? (
    <Document file={fileBlob} loading={<Loader />}>
      <Page pageNumber={1} width={700} />
    </Document>
  ) : (
    <ModalDefault.Description>
      {t('documents_preview_unsupported_file_type')}
    </ModalDefault.Description>
  );

  const loadPdf = useCallback(async () => {
    const { blob, type } = await getFile(fileUrl);
    setPdfFile({
      fileBlob: blob,
      fileType: type,
    });
    setIsLoading(false);
  }, [fileUrl]);

  useEffect(() => {
    setIsLoading(true);

    loadPdf();
  }, [loadPdf]);

  return (
    <ModalDefault open={open} onCancel={onCancel} width={755} closable className={s.modal}>
      <WithLoader isLoading={isLoading}>
        <ModalDefault.Title>{t('documents_sign_check_out_doc')}</ModalDefault.Title>
        <div className={s.content}>{content}</div>
        <ModalDefault.Actions>
          <Button fullWidth leftIcon="ok" loading={false} onClick={handleSign}>
            {t('common_acquainted')}
          </Button>
        </ModalDefault.Actions>
      </WithLoader>
    </ModalDefault>
  );
};

export default PreviewPdf;
