import { CommunityManagementFormResponse } from 'containers/community-management-form/communityManagementForm.utils';

import { CommunityCreateOrUpdateAdminReqDto } from 'types/community.types';

export const preparePayload = ({
  cover,
  avatar,
  moderators,
  links,
  isPublish,
  ...rest
}: CommunityManagementFormResponse): CommunityCreateOrUpdateAdminReqDto => {
  return {
    ...rest,
    isPublish: isPublish ? isPublish : false,
    links: links.filter((link) => link !== ''),
    cover: cover ? { link: cover } : null,
    avatar: avatar ? { link: avatar } : null,
    moderators: moderators.map((value) => {
      if (typeof value === 'string') {
        return JSON.parse(value);
      } else {
        // @ts-expect-error todo
        return JSON.parse(value.value);
      }
    }),
  };
};
