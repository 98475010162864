import { useParams } from 'react-router-dom';
import { getCommunityManagementViewByIdRoute } from 'routes/communities/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

interface Props {
  isLoading: boolean;
}

const Header = (props: Props) => {
  const { isLoading } = props;
  const { id } = useParams<{ id: string }>();

  return (
    <ContentHeader>
      <ContentHeader.Title goBackLink={getCommunityManagementViewByIdRoute(id!)}>
        {t('news_add')}
      </ContentHeader.Title>
      <ContentHeader.Actions>
        <Button type="primary" leftIcon="ok" htmlType="submit" loading={isLoading}>
          {t('common_save')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
