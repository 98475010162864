import { useState } from 'react';

import Header from './containers/header';
import CommunityManagementSubscribersTabs from './containers/tabs';
import { CommunityManagementSubscribersEnumType } from './containers/tabs/tabs.utils';

const CommunityManagementSubscribers = () => {
  const [currentTab, setCurrentTab] =
    useState<CommunityManagementSubscribersEnumType>('SUBSCRIBERS');
  return (
    <>
      <Header currentTab={currentTab} />
      <CommunityManagementSubscribersTabs setCurrentTab={setCurrentTab} />
    </>
  );
};

export default CommunityManagementSubscribers;
