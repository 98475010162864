import {
  useEmployerCancelKedoDocumentMutation,
  useEmployerCancelRecruitMutation,
  useEmployerCancelWorkTourMutation,
} from 'services/1c/employer-cancel-document/employerCancelDocumentApiService';
import { EmployerCancelDocumentPropsType } from 'services/1c/employer-cancel-document/employerCancelDocumentApiService.types';

import { handleRequest } from 'gazprom-ui-lib';

import { EmployerCancelType } from './documentEmployerCancelModal';

export interface Props {
  type: EmployerCancelType;
  id?: string;
  employerCancelComment: string;
  onSuccess: () => void;
}

const useActions = (props: Props) => {
  const { type, id, employerCancelComment, onSuccess } = props;

  const [cancelRecruit, { isLoading: isRecruitCanceling }] = useEmployerCancelRecruitMutation();
  const [cancelDocument, { isLoading: isDocumentCanceling }] =
    useEmployerCancelKedoDocumentMutation();
  const [cancelWorkTour, { isLoading: isWorkTourCanceling }] = useEmployerCancelWorkTourMutation();

  const ACTIONS_BY_TYPE = {
    workTour: (requestBody: EmployerCancelDocumentPropsType) => cancelWorkTour(requestBody),
    document: (requestBody: EmployerCancelDocumentPropsType) => cancelDocument(requestBody),
    recruit: (requestBody: EmployerCancelDocumentPropsType) => cancelRecruit(requestBody),
  };

  const onSubmit = () => {
    if (id) {
      ACTIONS_BY_TYPE[type as keyof typeof ACTIONS_BY_TYPE]({
        id,
        employerCancelComment,
      }).then(handleRequest({ onSuccess }));
    }
  };

  return {
    onSubmit,
    isLoading: isRecruitCanceling || isDocumentCanceling || isWorkTourCanceling,
  };
};

export default useActions;
