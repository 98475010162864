import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetOrdersQuery } from 'services/gift/giftApiService';
import { GetOrdersPropsType } from 'services/gift/giftApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './presents.utils';

interface Props {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: Props) => {
  const { form } = props;

  const requestBody: FormResponseType | undefined = Form.useWatch([], form);

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  const [getOrders, { data, isLoading, isFetching, isUninitialized }] = useLazyGetOrdersQuery();

  useEffect(() => {
    if (debouncedRequestBody) {
      const { page, size, search, filters, order, property } = debouncedRequestBody;

      const preparedBody: GetOrdersPropsType = {
        page,
        size,
      };

      if (filters?.deliveryType?.length) {
        preparedBody.deliveryId = filters.deliveryType;
      }

      if (search?.length) {
        preparedBody.search = search;
      }

      if (order && property) {
        const propertyToSort = property === 'employee' ? 'employeeId' : property;

        preparedBody.sort = [{ property: propertyToSort, order }];
      }

      getOrders(preparedBody);
    }
  }, [debouncedRequestBody, getOrders]);

  return {
    data,
    isFetching: isDebouncing || isFetching,
    isLoading: isUninitialized || isLoading,
  };
};

export default useGetData;
