import { StepsProps } from 'gazprom-ui-lib';

import { GiftOrderType } from 'types/gift.types';

export const convertPresentDetailsLogs = (logs?: GiftOrderType['logs']): StepsProps['items'] => {
  if (!logs) return [];

  return logs?.map(({ status, createdAt }) => ({
    title: status,
    state: 'primary',
    createdAt,
  }));
};
