import { userManager } from 'utils/keycloak';

export const getFile = async (fileUrl: string) => {
  const user = await userManager.getUser();
  const token = user?.access_token as string;

  if (!token) {
    throw new Error('User token is not available');
  }

  const response = await fetch(fileUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch file: ${response.statusText}`);
  }

  const blob = await response.blob();

  return { blob: URL.createObjectURL(blob), type: blob.type };
};
