import { Dispatch, SetStateAction } from 'react';

import { Tabs } from 'gazprom-ui-lib';

import { CommunityManagementSubscribersEnumType, tabsItems } from './tabs.utils';

type CommunityManagementSubscribersTabsProps = {
  setCurrentTab: Dispatch<SetStateAction<CommunityManagementSubscribersEnumType>>;
};

const CommunityManagementSubscribersTabs = ({
  setCurrentTab,
}: CommunityManagementSubscribersTabsProps) => {
  return (
    <Tabs
      type="card"
      onChange={(value) => setCurrentTab(value as CommunityManagementSubscribersEnumType)}
      defaultActiveKey="NEWS"
      items={tabsItems}
    />
  );
};

export default CommunityManagementSubscribersTabs;
