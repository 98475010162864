import { useEffect } from 'react';

import dayjs from 'dayjs';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetRecruitsQuery } from 'services/recruit/recruitApiService';
import { GetRecruitsPropsType } from 'services/recruit/recruitApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './documentsRecruits.utils';

interface UseGetDataProps {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: UseGetDataProps) => {
  const { form } = props;

  const formData = Form.useWatch([], form);

  const { debouncedValue: debouncedFormData, isDebouncing } = useDebounce(formData);

  const [getRecruits, { data, isLoading, isFetching, isUninitialized }] = useLazyGetRecruitsQuery();

  useEffect(() => {
    const requestBody: GetRecruitsPropsType = {
      page: debouncedFormData?.page,
      size: debouncedFormData?.size,
    };

    if (debouncedFormData?.search) {
      requestBody.text = debouncedFormData.search;
    }

    if (debouncedFormData?.filters?.status) {
      requestBody.status = debouncedFormData.filters?.status;
    }

    if (debouncedFormData?.range) {
      requestBody.startDate = dayjs.utc(debouncedFormData.range[0]).format();
      requestBody.finishDate = dayjs.utc(debouncedFormData.range[1]).format();
    }

    if (debouncedFormData?.order && debouncedFormData?.property) {
      requestBody.sort = [
        { order: debouncedFormData.order, property: `recruit.${debouncedFormData.property}` },
      ];
    }

    getRecruits(requestBody);
  }, [debouncedFormData, getRecruits]);

  return {
    data,
    isLoading: isUninitialized || isLoading,
    isFetching: isFetching || isDebouncing,
  };
};

export default useGetData;
